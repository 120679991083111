import moment from "moment";
import { useState, useRef, Fragment } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import useSWR from "swr";
import { URLS } from "../../../../../services/urls/urls";

const SupportTickets = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const {data:tickets, isLoading} = useSWR(URLS.TICKETS.MY)

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Fragment title={t("menu_tickets")}>
      <div
        className="q-page-container my-account trade-room-layout">
            <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
                <div>
                    <h3 className="font-w600 mb-0 me-auto mb-2 text-black">{t("txt_mt")}</h3>
                </div>
                <div className="ms-auto">
                    <Link
                      onClick={() => setShow(true)}
                      to="/support-tickets/create"
                      className="button btn btn-primary px-4 py-3"
                    >
                      {t("new_tickets")}
                    </Link>
                  </div>
            </div>
          <div className="">
            <div>
              <div className="table account-table">
                <div
                  id="monetaryStatement_wrapper"
                  className="dataTables_wrapper no-footer w-100"
                >

                  {!loading && (
                    <>
                      <table
                        id="monetaryStatement"
                        className="desktop-table dataTable no-footer w-100"
                        role="grid"
                      >
                        <thead>
                          <tr role="row">
                            <th className="sorting_disabled" rowSpan="1" colSpan="1">
                              {t("wd_reference")}
                            </th>
                            <th className="sorting_disabled" rowSpan="1" colSpan="1">
                              {t("ticket_subject")}
                            </th>
                            <th className="sorting_disabled" rowSpan="1" colSpan="1">
                              {t("ticket_status")}
                            </th>
                            <th className="sorting_disabled" rowSpan="1" colSpan="1">
                              {t("ticket_last_updated")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading && tickets?.results?.length === 0 && (
                            <tr className="">
                              {/* <td className="st-key">Operation Date</td> */}
                              <td className="st-val" colSpan={7}>
                                {t("table_empty")}
                              </td>
                            </tr>
                          )}
                          {tickets?.results?.map((ticket, index) => (
                            <tr
                              onClick={() =>
                                navigate(`/support-tickets/${ticket.id}/`)
                              }
                              key={ticket.id}
                              className={index % 2 === 0 ? "odd hover" : "even hover"}
                            >
                              <td colSpan="1" valign="top">
                                #{ticket.reference}
                              </td>
                              <td colSpan="1" valign="top">
                                {ticket.subject}
                              </td>
                              <td colSpan="1" valign="top">
                                <span
                                  className={
                                    ticket.status === "OPENED"
                                      ? "text-error"
                                      : ticket.status === "CLOSED"
                                      ? "text-success"
                                      : "text-warning"
                                  }
                                >
                                  {ticket.status}
                                </span>
                              </td>
                              <td colSpan="1" valign="top">
                                {moment(ticket.updated_at).format("lll")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          </div>
    </Fragment>
  );
};

export default SupportTickets;
