
export const evaluatePrice = (currency, marketPrices, wallet) => {

  let value = 0;

  switch (wallet.currency) {
    case "BTC":
      if (currency === "EUR") {
        value = wallet.balance * marketPrices?.BTC?.EUR;
        return Number(value).toFixed(2) + " " + currency;
      } else {
        value = wallet.balance * marketPrices?.BTC?.USD;
        return Number(value).toFixed(2) + " " + currency;
      }
    case "USDT":
      if (currency === "EUR") {
        value = wallet.balance * marketPrices?.USDT?.EUR;
        return Number(value).toFixed(2) + " " + currency;
      } else {
        value = wallet.balance * marketPrices?.USDT?.USD;
        return Number(value).toFixed(2) + " " + currency;
      }
    default:
      value = 0;
      return Number(value).toFixed(2) + " " + currency;
  }

  // console.log("marketPrices========>", marketPrices, value, wallet.balance * marketPrices?.BTC?.USD)
//   const data = {
//     frm: wallet?.currency,
//     to: currency,
//     amount: wallet?.amount,
//   };
//   let value = await API.post(URLS.WALLETS.CONVERT, data).then(resp => {
//     return 0;
//   }).catch(e => 0);

  // return value + " " + currency;
};

export const formatAmount = (num) => {
  // Round to one decimal and convert to string
  let rounded = Number(num).toFixed(1);
  
  // Add thousands separator with space
  return rounded.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}


export const formatCurrency = (currency) => {

  switch (currency) {
    case "EUR":
      return " € ";

    case "USD":
      return " $ ";
  
    default:
      return currency;
  }
}

       // Function to check if element is in viewport
export const isElementInViewport = (elem) => {
    var windowTop = window.$(window).scrollTop();
    var windowBottom = windowTop + window.$(window).height();
    var elemTop = window.$(elem).offset().top;
    var elemBottom = elemTop + window.$(elem).outerHeight();

    // Check if at least 50% of element is in viewport
    return ((elemTop + (elemBottom - elemTop) / 2) >= windowTop && (elemTop + (elemBottom - elemTop) / 2) <= windowBottom);
}

export const calculate_profit_loss = (investment_amount, opening_price, current_price) => {

  // # Calculate quantity of BTC purchased
  const quantity_btc = investment_amount / opening_price
  
  // # Calculate current value of investment
  const current_value = quantity_btc * current_price
  
  // # Calculate profit or loss
  const profit_loss = current_value - investment_amount
  
  return profit_loss
}