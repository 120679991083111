import { menus } from "./en/menus.translations";

 const en = {
  translation: {
    "shared.french": "French",
    "shared.english": "English",
    "shared.spanish": "Spanish",
    "shared.italian": "Italian",
    "shared.norvegian": "Norvegian",
    "shared.portugues": "Portugues",
    "shared.sweden": "Sweden",
    "shared.deutsch": "Deutsch",
    home: "Home",
    home_welcome: "Welcome",
    home_you_can:
      "You can now use real account to trade Forex, CFDs, Commodities, Stocks, Indices across multiple platforms (Web & Mobile). Thank you for choosing us",
    menu_contact_us: "Contact Us",
    menu_my_account: "My account",
    menu_logout: "Logout",
    menu_personal_details: "Personal Details",
    menu_change_password: "Change Password",
    menu_trading_history: "Trading History",
    menu_upload_document: "Upload Documents",
    menu_monetary_transaction: "Monetary Transactions",
    menu_wallet: "Wallets",
    menu_withdrawal: "Withdrawal",
    pd_first_name: "First Name",
    pd_last_name: "Last Name",
    pd_email: "Email",
    pd_phone_number: "Phone Number",
    pd_country: "Country",
    pd_edit: "Edit",
    pd_city: "City",
    pd_post_code: "Post code",
    form_save: "Save",
    edit_account: "Edit Account",
    field_mandatory: "All fields marked as *are mandatory",
    change_password: "Change Password",
    old_password: "Old Password",
    pw_weak: "Weak",
    pw_medium: "Medium",
    pw_strong: "Strong",
    pw_confirm_pass: "Confirm Password",
    pw_set_pass: "Confirm Password",
    form_submit: "Submit",
    pw_new_password: "New Password",
    table_display: "Display",
    display_record: "records",
    table_empty: "No data available",
    th_symbol: "Symbol",
    th_quantity: "Quantity",
    th_open_date: "Open Date",
    th_close_date: "Close Date",
    th_open_price: "Open Price",
    th_close_price: "Close Price",
    th_position_type: "Buy/Sell",
    th_comments: "Comments",
    th_profit_lost: "Profit / lost",
    upload_documents: "Upload Documents",
    ud_upload_type: "Upload Type",
    ud_upload_date: "Upload date",
    select_choose_option: "Choose an option",
    ft_bank_file: "File",
    ft_bank_wire: "Bank Wire",
    ft_credit_card_front: "Credit Card Front",
    ft_driving_license: "Driving License",
    ft_national_id_card: "National ID Card",
    ft_passport_id: "Passport ID",
    ft_utility_bill: "Utility Bill",
    ft_bank_statement: "Bank statement",
    ft_other: "Other",
    ft_dod: "DOD",
    ft_por: "POR",
    ft_id: "ID",
    ft_wire_details: "Wire Details",
    ft_wd_request_form: "WD Request Form",
    ft_credit_card_back: "Credit Card Back",
    ft_other_doc_type: "Other document type",
    file_uploaded: "Files uploaded",
    wallet_details: "Wallet Details",
    w_currency: "Currency",
    w_amount: "Amount",
    w_status: "Status",
    w_status_valid: "VALID",
    w_status_invalid: "INVALID",
    w_currency: "Deposit address",
    w_transactions_history: "Transactions history",
    w_currency: "Currency",
    wd_reference: "Reference",
    wd_transaction_type: "Transaction Type",
    wd_amount: "Amount",
    wd_wallet: "Wallet",
    wd_status: "Status",
    wd_operation_date: "Operation Date",
    wd_note: "Note",
    wallet_list: "Wallet list",
    new_wallet: "New wallet",
    create_wallet_description: "Inter your wallet informations to create.",
    choose_currency: "Choose Currency",
    wt_choose_wallet: "Choose a wallet",
    wt_amount: "Amount",
    wt_beneficiary: "Beneficiary",
    wt_bank_name: "Bank Name",
    wt_bank_address: "Bank Address",
    wt_bank_account_number: "Bank Account Number",
    wt_swift_code: "Swift Code",
    wt_wallet_address: "Wallet address",
    wt_relation_between: "Relation between you and the receiver",
    wt_confirm:
      "Recognizing that crypto wallet transactions are risky, I approve this transaction by validating the data provided.",
    wt_request_a_withdrawal: "Request a Withdrawal",
    l_loading: "Loading",
    login_to_start: "Login to Start Trading Right Now",
    login_to_start_description: `You can sign up right now to start practicing trading on a
    FREE demo account. Once you’ve built up enough confidence,
    you can start trading for real. Test the platform, find
    your approach, and get started.`,
    dont_have_account: "Don't have an account",
    register_here: "Register here",
    login_password: "Password",
    login_email: "Email",
    login_button: "Login",
    live_registration: "Live Registration",
    personal_informations: "Personal information",
    login_information: "Login information",
    login_i_have_read: "I have read, understood and accept the",
    term_and_conditions: "Terms and Conditions",
    agree_im_major_and_not_living_in_usa:
      "I confirm that I am of legal age and that I am not a citizen of the USA.",
    accept_general_risk: `I agree with  <a href="https://StockBridge Capitaltrade.com/legalcontent/General_risk_disclosure.pdf" target="_blank" class="text-primary">general Risk disclosure</a>`,
    register_now: "REGISTER NOW",
    login_now: "Login",
    already_have_ccount: "Already have an account",
    edit_profile_language: "Language",
    edit_profile_gender: "Gender",
    edit_profile_gender_male: "Male",
    edit_profile_gender_female: "Female",
    edit_profile_birth_day: "Birth date",
    edit_profile_address: "Address",
    update_pw_failed_message:
      "Password change failed, please verify your input and try again.",
    closed_order: "Closed positions",
    opened_order: "Opened positions",
    menu_video_academy: "Video academy",
    w_deposit_address: "Wallet deposit address",
    button_deposit_text: "Deposit",
    button_deposit_description: "Deposit informations.",
    qr_code: "QR code",
    wallet_details_overview: "Overview",
    wt_wallet_address_type: "Wallet type",
    wallet_details_details: "Details",
    no_email_validation: "No email confirmation key, please try agaim later.",
    email_address_validated:
      "Your email address was successfully verified, you can login now",
    email_address_validation_error:
      "Ooops an error occured while verifying your email address. Please, try refreshing the page or try later.",
    email_verification_pending: "Verifying your email address ...",
    w_deposit_iban: "IBAN",
    deposit_infos: "Deposit informations",
    // New translations
    new_wallet_generate_address: "Generate address.",
    new_wallet_success: "Wallet successfuly created",
    new_wallet_error: "Wallet creation error",
    menu_swap_transfert: "Swap transfert",
    transfert_money: "Transfer Money",
    transfert_money_between_wallets: "Transfer Money Between wallets",
    wallet_to_withdraw: "Wallet to debit",
    wallet_to_send_to: "Wallet to credit",
    transfert_amount: "Transfert Amount",
    swap_transfert_success: "Funds transfered successfuly",
    swap_transfert_error:
      "An error occured while transfering funds, please try later.",
    swap_transfert_cannot_process:
      "We canot process to the transfert, please fill all required informations and retry.",
    transfert_amount: "Amount",
    transfert_execute: "Execute",
    return_to_courses: "Return to courses",
    crypto_trading: "Crypto trading",
    crypto_trading_description:
      "In this course, you will learn all the basics to start trading in the world of cryptocurrencies.",
    introduction_and_history: "Introduction and history",
    blockchain_and_descentralisation: "Blockchain and decentralization",
    major_cryptocurencies: "Major cryptocurrencies",
    other_crypto_and_stable_coin: "Other cryptos and stablecoins",
    nft: "NFT",
    market_actors: "Market actors",
    key_factors: "Key drivers of the crypto market",
    trading_tools: "Crypto trading tools",
    psychologie_of_trading_and_risk_management:
      "Crypto trading psychology and risk management",
    trade_cryptos: "How to trade crypto",
    conclusion: "Conclusion",
    market_analysis: "Market Analysis",
    market_analysis_description:
      "This Market Analysis course is intended for those who want to develop their trading edge and sharpen their fundamental and technical analysis skills to make better trading decisions.",
    introduction: "Introduction",
    economic_analyse: "Economic Analysis",
    informations_and_trading_events: "Trading News And Events",
    trading_events: "Trading Events",
    key_economic_data: "Key Economic Data",
    technical_analyse: "Technical Analysis",
    support_and_resistance: "Support And Resistance",
    trends: "Trends",
    hauss_trends: "Upward Trends",
    bass_trends: "Downward Trends",
    course_resume: "Course Summary",
    trading_psychology: "Trading Psychology",
    trading_psychology_description:
      "In this groundbreaking course you will learn how to overcome the greatest enemy of trading: emotions! Get into the mindset of a successful trader, build confidence in your trading.",
    intro: "Intro",
    fear_of_lost: "Fear Of Loss",
    surmont_the_fear_of_lost: "Overcoming Fear Of Loss",
    gourmandise: "Greed",
    surmont_gourmandise: "Overcoming Greed",
    positions_management: "Position Management",
    solutions_: "Solutions",
    risks_management: "Risk Management",
    financial_management_solutions: "Money Management Solutions",
    course_resume: "Course Summary",
    forex_strategy: "Trading Strategies",
    forex_strategy_description:
      "This course will unveil a wide range of on-demand forex trading strategies for all types of traders",
    day_trading: "Day Trading",
    news_trading: "News Trading",
    carry_trading: "Carry Trading",
    binary_trends_trading: "Trend Trading",
    binary_swing_trading: "Swing Trading",
    chart_level_trading_of_binaries_option: "Chart Level Trading",
    chartists_classics_figures: "Classic Chart Pattern Trading",
    technical_indicators_trading: "Technical Indicator Trading",
    course_resume: "Summary",
    trading_sociology: "Social Trading",
    trading_sociology_description:
      "Social trading is a type of trading where investors can observe the trading strategies of other traders. Become a copier and replicate provider's trades, where a provider is an experienced trader who shares their investment strategies.",
    what_interest: "Why do social trading?",
    how_to_process: "Chose a provider",
    advantages_and_inconvenients: "Advantages and disadvantages",
    course_resume: "Summary",
    technical_indicators: "Technical Indicators",
    technical_indicators_description:
      "The most common methods to analyze the financial markets indicators, candlesticks charting and more.",
    graphiques_en_chandeliers: "Candlestick Charting",
    figures_des_chandeliers_part_1: "Candlestick Patterns Part 1",
    figures_des_chandeliers_part_2: "Candlestick Patterns Part 2",
    supports_et_resistances: "Support and Resistance",
    moyenne_mobile: "Moving average",
    bandes_de_bollinger: "Bollinger Bands",
    retracements_de_fibonacci: "Fibonacci Retracements",
    indice_de_force_relative_ou_rsi: "Relative Strength Index",
    diversification_and_timing: "diversification and timing",
    video_academy: "Video Academy",
    video_academy_description:
      "Here you can find courses containing video lessons.",
    video_academy_courses: "Courses",
    active_orders: "Active Orders",
    closed_orders: "Closed Orders",
    wt_balance: "Balance",
    w_volume: "Volume",
    form_choose_file: "Choose file",
    // New translations

    btn_continue: "Continue",
    deposit_amount_error:
      "The amount cannot be more than 5000, please contact an account manager for a deposit amount greather than 5000",
    selecte_a_payment_method: "Select a payment method",
    btn_previous: "Previous",
    send_the_exact_amount_of:
      "To make your deposit, please send the exact amount of",
    to_this_wallet: "To the following address",
    or_scan_the_qrcode: "Or scan the QR code",
    timeleft_before_deposit: "Time left before the deposit: ",
    btn_return_on_app: "Return on the app.",
    deposit_important: "Important",
    deposit_select_coin: "select coin",
    deposit_deposit_to: "Deposit to",
    deposit_send_only: `Send only {{currency}} to
    this deposit address. Sending any other currency
    to this address may result in the lost of your
    deposit.`,
    deposit_ensure_network_is: "Ensure the network is",
    deposit_deposit_address: "Deposit Address",
    deposit_send_within:
      "Send only {{currency}} on this wallet within {{timeout}}",
    deposit_show_qr: "Show QR Code",
    deposit_copy_qr: "Copy Address",
    deposit_tips: "Tips",
    deposit_tips_1:
      "Notice: Coins will be deposited immediately after 2 Network confirmation",
    deposit_track_progress:
      "After making a deposit, you can track its progress on the",
    deposit_history_page: "history page.",
    deposit_enter_card_details: "Please, enter your card details to pay with.",
    btn_copy_text: "Copy",
    payment_view_trans_id: "Transaction ID",
    you_also_have_create: "You also have to open ",
    a_ticket: "a ticket.",
    menu_tickets: "support Tickets",
    new_tickets: "New Ticket",
    ticket_subject: "Subject",
    ticket_status: "Status",
    ticket_last_updated: "Last Updated",
    ticket_details: "Ticket details",
    ticket_view: "Ticket view",
    posted_on: "on",
    posted_by: "Posted by",
    ticket_replies: "Replies",
    ticket_reply: "Reply",
    notification_title: "Notification",
    notification_empty: "You have no notifications",
    usr_request_call: "Call request",
    usr_request_call_severity: "Severity",
    usr_request_call_date: "Desired call date",
    usr_request_call_time: "Desired call time",
    usr_request_call_subject: "Call subject",
    usr_request_call_severity_normal: "Normal",
    usr_request_call_severity_urgent: "Urgent",
    usr_request_call_severity_very_u: "Very urgent",
    usr_request_call_action_btn: "Request a call",
    usr_request_call_request_sended:
      "Call request has been received, we will call you at mentionned time.",
    password_reset_desc: "Please, enter your email address to reset password",
    password_reset_action_btn: "Reset the password",
    password_recovery_action_btn: "Recover the password",
    password_reset_reset: "Enter and confirm you new password",
    deposit_card_holder: "Card holder name",
    deposit_hide_qr: "Hide QR Code",
    TR_PENDING: "Pending",
    TR_CANCELED: "Rejected",
    TR_VALIDATED: "Validated",
    TR_AWAITING_FOR_APPROVAL: "Awaiting for approval",
    TR_ON_HOLD: "On hold",
    menu_upload_document_to_verify_identity:
      "Upload your documents to verify your identity.",
    ft_tin: "TIN",
    menu_upload_document_to_verify_identity_success:
      "We have received your documents, we will check them and activate your account if they are verified.",
    upload_failed_file_too_large:
      "Sorry, the size of the file you uploaded is more than 2Mb, ans that is not allowed.",
    text_copied_success: "Text copied!",
    ft_national_id_card_front: "ID card front",
    ft_national_id_card_back: "ID card back",
    ft_upload_national_id_card_back: "Upload ID card back",
    ft_upload_national_id_card_front: "Upload ID card front",
    account_status: "Account verification",
    account_status_pending:
      "The verification of your information is in progress.",
    account_status_pending_text:
      "Our verification team has received your documents, and their verification is in progress. You will receive an email once your documents are approved then you can log in and start making your investments.",
    account_status_pending_signature:
      "Sincerely, the StockBridge Capital verification team",
    account_status_pending_nb:
      "NB: Due to a large amount of verification requests submitted, the validation of your documents can take from 30 mins to 24 hours. Thank you for being patient, and see you very soon for your first investment.",
    withdrawal_page_description: `By clicking "Request a Withdrawal" We are will be informed that
    you would like to withdraw funds. The money will be refunded to
    your funding credit or debit card.`,
    dashboard_login_wtrader: "Login to Web Trader",
    transaction_create_success: "Transaction successfully created!",
    transaction_create_error_message:
      " Transaction creation error!! Please try later",
    transaction_loading_error_message:
      "Loading transaction error !! Please try later",
    files_loading_error_message: "File loading failed!",
    files_upload_success_message: "File successfully uploaded",
    files_upload_error_message: "File upload failed. please, try again",
    wallets_creation_error_message: "Wallet successfuly created!",
    wallets_creation_success_message: "Wallet creation failed!",
    wallets_loading_error_message: "Loading error!",
    wallets_loading_success_message: "Wallet creation failed!",
    text_no_eth_wallet_for_swap:
      "We are sorry, but you have no Ethereum wallet to pay network fees for this swap transaction.",
    text_cannot_generate_eth_wallet:
      "You cannot generate an ETH wallet, please upgrade your account type and try again.",
    you_also_have_open: "You also have open",
    // Untranslated
    textBuy: "Buy",
    textSell: "Sell",
    textPrice: "Price",
    textTotal: "Total",
    textPositionOpenSuccess: "Position opened!",
    textPositionOpenError: "An error occured wile opening the posisiton!",
    to_bbm: "to StockBridge Capital",
    account_status_2: "Statut du compte",
    account_verified: "Verified",
    account_not_verified: "Not Verified",
    date_registered: "Date Registered",
    txt_wm: "Withdrawal Methods",
    txt_cw:"Continue withdrawal",
    txt_tr:"TRADE ROOM",
    txt_ob:"ORDER BOOK",
    txt_wl:"Watch List",
    txt_ar:"Alerts & Reports",
    txt_fh:"Financial History",
    txt_dashboard:"Dashboard",
    txt_tpv:"Total Portfolio Value",
    txt_trader_room:"Trader Room",
    txt_ar2:"Account Report",
    txt_ab:"Available Balance",
    txt_e_pnl:"Estimated PNL",
    txt_td:"Total Deposit",
    txt_taw:"Total Approved Withdrawals",
    txt_p_bal:"Plan Balance",
    txt_d:"Date",
    txt_ad:"My Account Details",
    txt_ud:"Uploaded Documents",
    txt_mt:"My Tickets",
    txt_up:"Update Password",
    txt_w:"Withdrawals",
    txt_s_n:"S/N",
    txt_pm:"Payment Method",
    txt_at:"All Transactions",
    txt_w_l:"Wishlist",
    txt_sa:"Search Assets",
    txt_ass:"Assets",
    txt_sell:"SELL",
    txt_buy:"BUY",
    txt_spread:"Spread",
    txt_actions:"Actions",
    menu_withdraw: "Withdraw",
    // New translation
    txt_md: "Make a Deposit",
    txt_aaa:"All assets available",
    txt_ffp:"Full-fledged Platform",
    txt_aw:"Easy Withdrawals",
    txt_tuya:"Top Up Your Account",
    txt_asmp:"And Start Making Real Profit",
    txt_export_report:"Export Report",
    last_6_months:"Last 6 Monnth",
    // txt_:"",
    last_7_days: "Last 7 days",
    current_month: "Current Month",
    last_year: "Last year",
    current_year: "Current Year",
        txt_close: "Close",
    txt_please_wait: "Please, wait...",
    txt_position_closed: "Position successfully closed",
    txt_position_closed_error: "And error occured while closing your position, please try contacting your account manager.",
    txt_ref_code: "Referral Link",
    txt_referral_copied: "Referral code copied!",
    txt_affiliates: "Affiliates",
    text_pips: "Pips",
    text_stop_loss: "Stop Loss",
    text_take_profit: "Take Profit",

    // New_to 
    txt_login_welcome: "Welcome to <strong>StockBridge Capital</strong>",
    txt_sign_in: "Sign in",
    txt_sign_up: "Sign up",
    txt_to_access: "to access your account",
    txt_to_open: "open your account",
    txt_forgot_password: "Forgot password?",
    txt_not_client: "Not a StockBridge Capital <br /> client yet?",
    txt_open_account: "Open an account",
    txt_main_page: "StockBridge Capital Main Page ",
    txt_contact_us_at: "Contact us at",
    txt_if_have_pb: "If you have problems",
    txt_footer: "Trading CFDs involves significant risk of loss. Read StockBridge Capital's",
    txt_risk: "‘Risk Disclosure Statement’.",
    txt_not_membber: 'Not a member?</span> <b className="ng-star-inserted">Sign up now!',
    txt_already_member: "<span>Already a member?</span> <b>Sign in now!</b>",
    txt_f: "",
  },
};

export default en;