


export default {
  translation: {
     "shared.french": "Fransk",
     "shared.english": "Engelsk",
     "shared.spanish": "Spanish",
     "shared.italian": "Italian",
     "shared.norvegian": "Norvegian",
     "shared.portugues": "Portugues",
     "shared.sweden": "Sweden",
     "shared.deutsch": "Deutsch",
     home: "Hjem",
     home_welcome: "Velkommen",
     home_you_can: "Du kan nå bruke ekte konto til å handle Forex, CFDer, råvarer, aksjer, indekser på tvers av flere plattformer (nett og mobil). Takk for at du valgte oss",
     menu_contact_us: "Kontakt oss",
     menu_my_account:"Min konto",
     menu_logout: "Logg ut",
     menu_personal_details: "Personlige detaljer",
     menu_change_password: "Endre passord",
     menu_trading_history: "Handelshistorikk",
     menu_upload_document: "Last opp dokumenter",
     menu_monetary_transaction: "Monetære transaksjoner",
     menu_wallet: "Lommebøker",
     menu_withdrawal: "Uttak",
     pd_first_name: "Fornavn",
     pd_last_name: "Etternavn",
     pd_email: "E-post",
     pd_phone_number: "Telefonnummer",
     pd_country: "Land",
     pd_edit: "Rediger",
     pd_city: "By",
     pd_post_code: "Postnummer",
     form_save: "Lagre",
     edit_account: "Rediger konto",
     field_mandatory: "Alle felt merket som *er obligatoriske",
     change_password: "Endre passord",
     old_password: "Gammelt passord",
     pw_weak: "Svak",
     pw_medium: "Medium",
     pw_strong: "Sterk",
     pw_confirm_pass: "Bekreft passord",
     pw_set_pass: "Bekreft passord",
     form_submit: "Send inn",
     pw_new_password: "Nytt passord",
     table_display: "Display",
     display_record: "records",
     table_empty: "Ingen data tilgjengelig i tabellen",
     th_symbol: "Symbol",
     th_quantity: "Quantity",
     th_open_date: "Åpningsdato",
     th_close_date: "Slutt dato",
     th_open_price: "Åpen pris",
     th_close_price: "Lukk pris",
     th_position_type: "Kjøp/selg",
     th_comments: "Kommets",
     th_profit_lost:"Fortjeneste / tapt",
     upload_documents: "Last opp dokumenter",
     ud_upload_type: "Opplastingstype",
     ud_upload_date: "Opplastningsdato",
     select_choose_option: "Velg et alternativ",
     ft_bank_file: "Fil",
     ft_bank_wire: "Bankoverførsel",
     ft_credit_card_front: "Kredittkort foran",
     ft_driving_license: "Førekort",
     ft_national_id_card: "Nasjonalt ID-kort",
     ft_passport_id: "Pass-ID",
     ft_utility_bill: "Utility Bill",
     ft_dod: "DOD",
     ft_por: "POR",
     ft_id: "ID",
     ft_wire_details: "Wire Details",
     ft_wd_request_form: "WD-forespørselsskjema",
     ft_credit_card_back: "Kredittkort tilbake",
     ft_other_doc_type: "Annen dokumenttype",
     file_uploaded: "Filer lastet opp",
     wallet_details: "Lommebokdetaljer",
     w_currency:"Valuta",
     w_amount:"Beløp",
     w_status:"Status",
     w_status_valid:"VALID",
     w_status_invalid:"INVALID",
     w_currency:"Innskuddsadresse",
     w_transactions_history:"Transaksjonshistorikk",
     w_currency:"Valuta",
     wd_reference:"Referanse",
     wd_transaction_type:"Transaksjonstype",
     wd_amount:"Beløp",
     wd_wallet:"Lommebok",
     wd_status:"Status",
     wd_operation_date:"Operasjonsdato",
     wd_note: "Merk",
     wallet_list: "Lommebokliste",
     new_wallet: "Ny lommebok",
     create_wallet_description:"Legg inn lommebokinformasjonen din for å lage.",
     select_currency: "Velg valuta",
     withdrawal_page_description: `Ved å klikke på "Be om uttak" vil vi bli informert om at
     du ønsker å ta ut penger. Pengene vil bli refundert til
     ditt finansieringskreditt- eller debetkort.`,
     wt_choose_wallet: "Velg en lommebok",
     wt_amount: "Beløp",
     wt_beneficiary: "Mottaker",
     wt_bank_name: "Banknavn",
     wt_bank_address: "Bankadresse",
     wt_bank_account_number: "Bankkontonummer",
     wt_swift_code: "Swift-kode",
     wt_wallet_address: "Lommebokadresse",
     wt_relation_between: "Forholdet mellom deg og mottakeren",
     wt_confirm: "Jeg erkjenner at kryptolommeboktransaksjoner er risikable, godkjenner jeg denne transaksjonen ved å validere dataene som er oppgitt.",
     wt_request_a_withdrawal: "Be om uttak",
     l_loading: "Laster",
     login_to_start: "Logg på for å begynne å handle akkurat nå",
     login_to_start_description: `Du kan registrere deg akkurat nå for å begynne å praktisere handel på en
     GRATIS demokonto. Når du har bygget opp nok selvtillit,
     du kan begynne å handle på ekte. Test plattformen, finn
     din tilnærming, og kom i gang.`,
     dont_have_account:"Har ikke en konto",
     register_here: "Registrer deg her",
     login_password :"Passord",
     login_email :"E-post",
     login_button :"LOGG PÅ",
     live_registration: "Live-registrering",
     personal_informations: "Personlig informasjon",
     login_information: "Påloggingsinformasjon",
     login_i_have_read:"Jeg har lest, forstått og godtar",
     term_and_conditions: "Vilkår",
     register_now: "REGISTER NÅ",
     login_now: "Logg på nå",
     already_have_ccount: "Har allerede en konto",
     edit_profile_language: "Språk",
     edit_profile_gender: "Kjønn",
     edit_profile_gender_male: "Mann",
     edit_profile_gender_female: "Kvinne",
     edit_profile_birth_day: "Fødselsdato",
     edit_profile_address: "Adresse",
     te_pw_failed_message: "Passordendring mislyktes. Vennligst bekreft inndataene dine og prøv igjen.",
     closed_order: "Stengte stillinger",
     opened_order: "Åpnede stillinger",
     menu_video_academy: "Video akademi",
     w_deposit_address: "Lommebokinnskuddsadresse",
     button_deposit_text: "Innskudd",
     button_deposit_description: "Innskuddsinformasjon.",
     qr_code: "QR-kode",
     wallet_details_overview: "Oversikt",
     wt_wallet_address_type: "Lommeboktype",
     wallet_details_details: "Detaljer",
     "no_email_validation": "Ingen e-postbekreftelsesnøkkel, prøv igjen senere.",
     email_address_validated: 'E-postadressen din ble bekreftet, du kan logge på nå',
     email_address_validation_error: 'Beklager, det oppstod en feil under bekreftelse av e-postadressen din. Vennligst prøv å oppdatere siden eller prøv senere.',
     email_verification_pending: "Bekrefter e-postadressen din ...",
     w_deposit_iban: "IBAN",
     deposit_infos: "Innskuddsinformasjon",
     new_wallet_generate_address: "Generer adresse.",
     new_wallet_success: "Lommebok ble opprettet",
     new_wallet_error: "Feil ved opprettelse av lommebok",
     menu_swap_transfert: "Swap transfert",
     transfert_money: "Overfør penger",
     transfert_money_between_wallets: "Overfør penger mellom lommebøker",
     wallet_to_withdraw: "Lommebok til belastning",
     wallet_to_send_to: "Lommebok til kreditering",
     transfert_amount: "Overføringsbeløp",
     swap_transfert_success: "Mengene ble overført",
     swap_transfert_error: "Det oppstod en feil under overføring av midler, vennligst prøv senere.",
     swap_transfert_cannot_process: "Vi kan ikke behandle til overføringen, vennligst fyll inn all nødvendig informasjon og prøv på nytt.",
     transfert_amount: "Beløp",
     transfert_execute: "Utfør",
     return_to_courses: "Gå tilbake til kurs",
     crypto_trading: "Kryptohandel",
     crypto_trading_description: "I dette kurset vil du lære alt det grunnleggende for å begynne å handle i kryptovalutaens verden.",
     introduction_and_history: "Introduksjon og historie",
     blockchain_and_descentralisation: "Blockchain og desentralisering",
     major_cryptocurrencies: "Større kryptovalutaer",
     other_crypto_and_stable_coin: "Andre kryptoer og stablecoins",
     nft: "NFT",
     market_actors: "Markedsaktører",
     key_factors: "Nøkkeldrivere for kryptomarkedet",
     trading_tools: "Kryptohandelsverktøy",
     psychologie_of_trading_and_risk_management: "Kryptohandelspsykologi og risikostyring",
     trade_cryptos: "Hvordan handle krypto",
     conclusion: "Konklusjon",
     market_analysis: "Markedsanalyse",
     market_analysis_description: "Dette markedsanalysekurset er ment for de som ønsker å utvikle sin handelsfordel og skjerpe sine grunnleggende og tekniske analyseferdigheter for å ta bedre handelsbeslutninger.",
     introduction: "Introduksjon",
     economic_analyse: "Økonomisk analyse",
     informations_and_trading_events: "Handelsnyheter og begivenheter",
     trading_events: "Handelshendelser",
     key_economic_data: "Økonomiske nøkkeldata",
     technical_analyse: "Teknisk analyse",
     support_and_resistance: "Støtte og motstand",
     trends: "Trender",
     hauss_trends: "Oppadgående trender",
     bass_trends: "Nedadgående trender",
     course_resume: "Kurssammendrag",
     trading_psychology: "Handelspsykologi",
     trading_psychology_description: "I dette banebrytende kurset vil du lære hvordan du kan overvinne handelens største fiende: følelser! Kom inn i tankesettet til en vellykket trader, bygg opp tillit til handelen din.",
     intro: "Intro",
     fear_of_lost: "Frykt for tap",
     surmont_the_fear_of_lost: "Overvinne frykt for tap",
     gourmandise: "Grådighet",
     surmont_gourmandise: "Overvinne grådighet",
     positions_management: "Posisjonsadministrasjon",
     solutions_: "Løsninger",
     risks_management: "Risk Management",
     financial_management_solutions: "Money Management Solutions",
     course_resume: "Kurssammendrag",
     forex_strategy: "Handelsstrategier",
     forex_strategy_description: "Dette kurset vil avsløre et bredt spekter av on-demand valutahandelsstrategier for alle typer handelsmenn",
     day_trading: "Dagshandel",
     news_trading: "Nyhetshandel",
     carry_trading: "Carry Trading",
     binary_trends_trading: "Trendhandel",
     binary_swing_trading: "Swing Trading",
     chart_level_trading_of_binaries_option: "Chart Level Trading",
     chartists_classics_figures: "Classic Chart Pattern Trading",
     technical_indicators_trading: "Teknisk indikatorhandel",
     course_resume: "Sammendrag",
     trading_sociology: "Sosial handel",
     trading_sociology_description: "Sosial handel er en type handel der investorer kan observere handelsstrategiene til andre handelsmenn. Bli en kopimaskin og repliker leverandørens handler, der en leverandør er en erfaren handelsmann som deler sine investeringsstrategier.",
     what_interest: "Hvorfor driver sosial handel?",
     how_to_process: "Velg en leverandør",
     advantages_and_inconvenients: "Fordeler og ulemper",
     course_resume: "Sammendrag",
     technical_indicators: "Tekniske indikatorer",
     technical_indicators_description: "De vanligste metodene for å analysere finansmarkedsindikatorer, kartlegging av lysestaker og mer.",
     graphiques_en_chandeliers: "Kartering av lysestaker",
     figures_des_chandeliers_part_1: "Lysestakemønstre del 1",
     figures_des_chandeliers_part_2: "Lysestakemønstre del 2",
     supports_et_resistances: "Støtte og motstand",
     moyenne_mobile: "Gjennomsnitt",
     bandes_de_bollinger: "Bollinger Bands",
     retracements_de_fibonacci: "Fibonacci retracements",
     indice_de_force_relative_ou_rsi: "Relativ styrkeindeks",
     diversification_and_timing: "diversifisering og timing",
     video_academy: "Video Academy",
     video_academy_description: "Her kan du finne kurs som inneholder videotimer.",
     video_academy_courses: "Kurs",
     active_orders: "Aktive bestillinger",
    closed_orders: "Stengte bestillinger",
    wt_balance: "Saldo",
    w_volume: "Volum",
    form_choose_file: "Velg fil",
    ft_bank_statement: "Bankutskrift",
     ft_other: "Annet",
     agree_im_major_and_not_living_in_usa: "Jeg bekrefter at jeg er myndig og at jeg ikke er statsborger i USA.",
     accept_general_risk: `og jeg er enig med <a href="https://StockBridge Capitaltrade.com/legalcontent/General_risk_disclosure.pdf" target="_blank"class="text-primary">generell risikoavsløring</a>` ,
     btn_continue: "Fortsett",
     deposit_amount_error: "Beløpet kan ikke være mer enn 5000, vennligst kontakt en kontoadministrator for et innskuddsbeløp større enn 5000",
     selecte_a_payment_method: "Velg en betalingsmetode",
     btn_previous: "Forrige",
     send_the_exact_amount_of: "For å gjøre innskuddet ditt, vennligst send det nøyaktige beløpet på",
     to_this_wallet: "Til følgende adresse",
     or_scan_the_qrcode: "Eller skann QR-koden",
     timeleft_before_deposit: "Tid igjen før innskuddet: ",
     btn_return_on_app: "Gå tilbake på appen.",
     deposit_important: "Viktig",
     deposit_select_coin: "velg mynt",
     deposit_deposit_to: "Innskudd til",
     deposit_send_only: `Send bare {{currency}} til
     denne innskuddsadressen. Sende en hvilken som helst annen valuta
     til denne adressen kan føre til tap av din
     innskudd.`,
     deposit_ensure_network_is: "Sørg for at nettverket er",
     deposit_deposit_address: "Innskuddsadresse",
     deposit_show_qr: "Vis QR-kode",
     deposit_copy_qr: "Kopier adresse",
     deposit_tips: "Tips",
     deposit_tips_1: "Merk: Mynter vil bli satt inn umiddelbart etter 2 nettverksbekreftelse",
     deposit_track_progress: "Etter å ha gjort et innskudd kan du spore fremdriften på",
     deposit_history_page: "historikkside.",
     deposit_enter_card_details: "Vennligst, skriv inn kortdetaljene dine for å betale med.",
     btn_copy_text: "Kopier",
     payment_view_trans_id: "Transaksjons-ID",
     you_also_have_create: "Du må også åpne ",
     a_ticket: "en billett.",
     menu_tickets: "støtte billetter",
     new_tickets: "Ny billett",
     ticket_subject: "Emne",
     ticket_status: "Status",
     ticket_last_updated: "Sist oppdatert",
     ticket_details: "Billettdetaljer",
     ticket_view: "Billettvisning",
     posted_on: "på",
     posted_by: "Lagt inn av",
     ticket_replies: "Svar",
     ticket_reply: "Svar",
     notification_title: "Varsling",
     notification_empty: "Du har ingen varsler",
     usr_request_call: "Anropsforespørsel",
     usr_request_call_severity: "Alvorlighetsgrad",
     usr_request_call_date: "Ønsket ringedato",
     usr_request_call_time: "Ønsket samtaletid",
     usr_request_call_subject: "Samtaleemne",
     usr_request_call_severity_normal: "Normal",
     usr_request_call_severity_urgent: "Haster",
     usr_request_call_severity_very_u: "Veldig haster",
     usr_request_call_action_btn: "Be om en samtale",
     usr_request_call_request_sended:"Anropsforespørsel er mottatt, vi vil ringe deg på nevnte tidspunkt.",
     password_reset_desc: "Vennligst, skriv inn e-postadressen din for å tilbakestille passordet",
     password_reset_action_btn: "Tilbakestill passordet",
     password_recovery_action_btn: "Gjenopprett passordet",
     password_reset_reset: "Skriv inn og bekreft ditt nye passord",
     deposit_card_holder: "Kortinnehavers navn",
     deposit_hide_qr: "Skjul QR-kode",
     deposit_send_within: "Send bare {{currency}} på denne lommeboken innen {{timeout}}",
     TR_PENDING: "Venter",
     TR_CANCELED: "Avvist",
     TR_VALIDATED: "Validert",
     TR_AWAITING_FOR_APPROVAL: "Venter på godkjenning",
     TR_ON_HOLD: "På vent",
     menu_upload_document_to_verify_identity: "Last opp dokumentene dine for å bekrefte identiteten din.",
     ft_tin: "TIN",
     menu_upload_document_to_verify_identity_success: "Vi har mottatt dokumentene dine, vi vil sjekke dem og aktivere kontoen din hvis de er bekreftet.",
     upload_failed_file_too_large: "Beklager, størrelsen på filen du lastet opp er mer enn 2 Mb, og det er ikke tillatt.",
     text_copied_success: "Tekst kopiert!",
     ft_national_id_card_front: "ID-kort foran",
     ft_national_id_card_back: "ID-kort tilbake",
     ft_upload_national_id_card_back: "Last opp ID-kort tilbake",
     ft_upload_national_id_card_front: "Last opp ID-kort foran",
     account_status: "Kontobekreftelse",
     account_status_pending: "Bekreftelsen av informasjonen din pågår.",
     account_status_pending_text: "Vårt bekreftelsesteam har mottatt dokumentene dine, og deres bekreftelse pågår. Du vil motta en e-post når dokumentene dine er godkjent, så kan du logge på og begynne å investere.",
     account_status_pending_signature: "Vennlig hilsen StockBridge Capital-verifiseringsteamet",
     account_status_pending_nb: "NB: På grunn av en stor mengde bekreftelsesforespørsler som er sendt inn, kan valideringen av dokumentene dine ta fra 30 minutter til 24 timer. Takk for tålmodigheten, og vi ses snart for din første investering.",
     withdrawal_page_description: `Ved å klikke på "Be om uttak" vil vi bli informert om at
     du ønsker å ta ut penger. Pengene vil bli refundert til
     ditt finansieringskreditt- eller debetkort.`,
     dashboard_login_wtrader: "Logg på netthandler",
     transaction_create_success: "Transaksjonen ble opprettet!",
     transaction_create_error_message: " Transaksjonsopprettingsfeil! Prøv senere",
     transaction_loading_error_message: "Laster transaksjonsfeil !! Vennligst prøv senere",
     files_loading_error_message: "Fillasting mislyktes!",
     files_upload_success_message: "Filen er lastet opp",
     files_upload_error_message: "Filopplasting mislyktes. vennligst prøv igjen",
     wallets_creation_error_message: "Lommebok ble opprettet!",
     wallets_creation_success_message: "Lommebokopprettelsen mislyktes!",
     wallets_loading_error_message: "Lastingsfeil!",
     wallets_loading_success_message: "Lommebokopprettelsen mislyktes!",
     text_no_eth_wallet_for_swap: "Vi beklager, men du har ingen Ethereum-lommebok for å betale nettverksavgifter for denne byttetransaksjonen.",
      text_cannot_generate_eth_wallet: "Du kan ikke generere en ETH-lommebok, vennligst oppgrader kontotypen og prøv igjen.",
      to_bbm: "til StockBridge Capital TRADE",
     account_status_2: "Statut du compte",
     account_verified: "Verifisert",
     account_not_verified: "Ikke bekreftet",
     date_registered: "Dato registrert",
     txt_wm: "Uttaksmetoder",
     txt_cw:"Fortsett uttak",
     txt_tr:"HANDELSROM",
     txt_ob:"BESTILLINGSBOK",
     txt_wl:"Overvåkningsliste",
     txt_ar:"Varsler og rapporter",
     txt_fh:"Finansiell historie",
     txt_dashboard:"Dashboard",
     txt_tpv:"Total porteføljeverdi",
     txt_trader_room:"Trader Room",
     txt_ar2:"Kontorapport",
     txt_ab:"Tilgjengelig saldo",
     txt_e_pnl:"Estimert PNL",
     txt_td:"Totalt innskudd",
     txt_taw:"Totalt godkjente uttak",
     txt_p_bal:"Plan balanse",
     txt_d:"Dato",
     txt_ad:"Min kontodetaljer",
     txt_ud:"Opplastede dokumenter",
     txt_mt:"Mine billetter",
     txt_up:"Oppdater passord",
     txt_w:"Uttak",
     txt_s_n:"S/N",
     txt_pm:"Betalingsmåte",
     txt_at:"Alle transaksjoner",
     txt_w_l:"Ønskeliste",
     txt_sa:"Søk etter eiendeler",
     txt_ass:"Eiendeler",
     txt_sell:"SELG",
     txt_buy:"KJØP",
     txt_spread:"Spredning",
     txt_actions:"Handlinger",
     tekst_:"",
     menu_withdraw: "Ta tilbake",
     txt_close: "Lukk",
    txt_please_wait: "Vennligst, vent...",
    txt_position_closed: "Posisjon ble lukket",
    txt_position_closed_error: "Og det oppstod en feil under nedleggelse av stillingen din, prøv å kontakte din kontoadministrator.",
    txt_login_welcome: "Velkommen til <strong>StockBridge Capital</strong>",
    txt_sign_in: "Logg på",
    txt_sign_up: "Registrer deg",
    txt_to_access: "for å få tilgang til kontoen din",
    txt_to_open: "åpne kontoen din",
    txt_forgot_password: "Glemt passord?",
    txt_not_client: "Ikke en StockBridge Capital <br /> klient ennå?",
    txt_open_account: "Åpne en konto",
    txt_main_page: "StockBridge Capital hovedside ",
    txt_contact_us_at: "Kontakt oss på",
    txt_if_have_pb: "Hvis du har problemer",
    txt_footer: "Handling av CFD-er innebærer betydelig risiko for tap. Les StockBridge Capital-er",
    txt_risk: "'Erklæring om risikoavsløring'.",
    txt_not_membber: 'Ikke medlem?</span> <b className="ng-star-inserted">Registrer deg nå!',
    txt_already_member: "<span>Er du allerede medlem?</span> <b>Logg på nå!</b>",
    txt_f: "",
   },
};