import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useCreditCardValidator } from 'react-creditcard-validator';
import _ from 'lodash';
import CryptoPay2 from './CryptoPay';
import { API } from '../../../../../services/axios';
import { URLS } from '../../../../../services/urls/urls';
import { paymentData } from '../../../../../store/actions/utilsActions';
import { paymentMethods } from '../../../../components/utils/consts';
import useSWR from 'swr';
import PerfectScrollbar from "react-perfect-scrollbar";


const DepositMethods = ({initiatedPayment}) => {
  const { t } = useTranslation();

  const { paymentData: paymentdata } = useSelector((state) => state.utils);

  const {
    auth: { user },
  } = useSelector((state) => state.auth);
  const {data: walletList, isLoading: loadingWallets} = useSWR(URLS.USERS.MY_WALLETS(""))
  const [step, setStep] = useState(1);
  const [ccLoading, setCcLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [depositAmount, setDepositAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("bankCards");
  const [wallets, setWallets] = useState([]);
  const [depositWallet, setDepositWallet] = useState();
  const [extraData, setExtraData] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangeDepositAmount = (event) => {
    if (event.target.value > 5000 && paymentMethod === "bankCards") {
      toast.error("deposit_amount_error");
    } else {
      
      // Update transaction
      setDepositAmount(event.target.value);
      API.patch(URLS.TRANSACTIONS.EDIT(paymentdata?.transaction?.id), {'amount': event.target.value}).then((resp) => {

      }).catch((error) => {});
    }
  };

  const handleSelectPaymentMethod = (value) => {
    // console.log("------>", value);
    const method = value.value;
    if (method === "paypal" || method === "perfectMoney") {
      toast.error(
        "We are sorry, but this payment method is desabled for the moment."
      );
    } else if (method === "bankCards" && depositAmount > 5000) {
      toast.error(
        "You cannot use this payment method for an amount greater than 5000"
      );
    } else {
      setPaymentMethod(method);
      handlePay(method, value);
    }

    setExtraData(value);
  };

  const handlePay = (paymentMethod, extraData) => {
    console.log("paymentMethod ---->>", paymentMethod)
    // if (!depositAmount) {
    //   toast.error("Please, enter the deposit amount!!");
    // }
    if (!paymentMethod) {
      toast.error("Please, choose a payment method!!");
    }

    if (paymentMethod) {
      // setLoading(true);
      const trans = window.localStorage.getItem("initPayment");
      let data = {
        method: paymentMethod,
        amount: 0.0,
        depositWallet: depositWallet,
        wallets: wallets,
        extraData: extraData,
        transaction: JSON.parse(trans)
      };

      const defaultCurrency = user?.default_currency;

      let initTransaction = {
        amount: depositAmount,
        reference: "REF",
      };

      const userWallets = walletList?.results;

      // The amount will be deposited in user real wallet event if the user make a deposit in crypto.
      const depositWal = _.filter(userWallets, { currency: defaultCurrency });
      initTransaction["wallet"] = depositWal[0]?.id;

      dispatch(paymentData(data));
      if (
        initTransaction.amount &&
        initTransaction.wallet &&
        initTransaction.reference
      ) { 
      }
    }
  };

  useEffect(() => {
    // Load crypto payment details
    API.get(URLS.WALLETS.GLOBALS).then((resp) => {
      setWallets(resp.data);
    });
    setDepositAmount(paymentdata?.amount ?? 0);
    setPaymentMethod(paymentdata?.method ?? "");

  }, []);


  return (
    <div className="q-card deposit-methods" >
        <div className="q-card__section q-card__section--vert q-pa-none">
          <div className="align-items-start row">
            <div className="q-tabs col-sm-4 col-12 bg-primary p-0 row m-0 no-wrap items-center q-tabs--not-scrollable q-tabs--vertical q-tabs__arrow m-0s--inside">
                <div
                  className="nav text-black flex-column nav-pills m-0 p-0 deposit-methods-nav"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {
                      paymentMethods.map((method) => {
                          return (
                            <div
                              key={"pm-" + method.value}
                              onClick={() => handleSelectPaymentMethod(method)}
                              className={`q-tab rounded-0 nav-link ${paymentMethod === method.value ? 'active' : ''} relative-position self-stretch flex flex-center text-center q-tab--inactive q-tab--no-caps q-focusable q-hoverable cursor-pointer justify-start`}
                              tabIndex={-1}
                              role="tab"
                              aria-selected="false"
                              style={{
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid",
                                borderBottomColor: "rgb(195, 195, 195)",
                              }}
                              id={"pm-" + method.value} data-bs-toggle="pill" data-bs-target={`#${"pm-" + method.value}`} type="button" aria-controls={"pm-" + method.value}
                              >
                              <div className="q-focus-helper" tabIndex={-1} />
                              <div className="w-100 q-tab__content self-stretch flex-center relative-position q-anchor--skip non-selectable row m-0 no-wrap q-tab__content--inline">
                                  <div
                                  className="q-card q-card--flat no-shadow full-width bg-transparent text-left"
                                  // style={{ width: 180 }}
                                  >
                                  <div className="q-card__section q-card__section--vert row m-0 q-col-gutter-md">
                                      <div className="col-4 px-0 flex flex-center">
                                      <img
                                        src={method.favicon}
                                        alt=""
                                        style={{ height: 60 }}
                                      />
                                      </div>
                                      <div className="col-8 text-start">
                                        <div className="fs-6" style={{textTransform: 'uppercase'}}>{t(`${method.value}`)}</div>
                                        <div className="fw-lighter" style={{fontSize: '10px'}}>Instant</div>
                                      </div>
                                  </div>
                                  </div>
                              </div>
                              <div
                                  className="q-tab__indicator absolute-left text-positive"
                                  style={{ transition: "none", transform: "none" }}
                              />
                              </div>
                          )
                      })
                  }
                </div>
            </div>
            <div className="tab-content col-sm-8 col-12 p-0" id="v-pills-tabContent">
              <div
                className="tab- ps-4 pe-0"
              >
                <div className="form bg-none border-0">

                  <div className=" row mb-2">
                    <div className="col-12">
                      <div>
                        <p className='mb-1 text-black h4 text-uppercase'>
                        {t("wd_reference")}: {initiatedPayment?.reference}
                        </p>
                      </div>
                      <div>
                        <label htmlFor={"depositAmount-"} className='text-black'>
                          <p>
                            {t("wd_amount")} ({user?.default_currency ?? "USD"})
                          </p>
                        </label>
                        <input
                          id="depositAmount"
                          defaultValue={paymentdata?.amount ?? "0"}
                          name="depositAmount"
                          placeholder={t("wd_amount") + " *"}
                          type="number"
                          value={depositAmount}
                          onChange={handleChangeDepositAmount}
                          className='form-control'
                          min={0}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {paymentMethod && <CryptoPay2 paymentMethod={paymentMethod} />}
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default DepositMethods;
