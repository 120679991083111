import { toast } from "react-toastify";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";
import { loadingTransactionsFailed, loadingTransactionsRequest, loadingTransactionsSuccess,createTransactionFailed, createTransactionRequest, createTransactionSuccess, deleteTransactionFailed, deleteTransactionRequest, deleteTransactionSuccess, editTransactionFailed, editTransactionRequest, editTransactionSuccess, loadTransactionFailed, loadTransactionRequest, loadTransactionSuccess } from "../actions/transactionsActions";

import SecureLS from 'secure-ls';
let ls = new SecureLS({encodingType: 'base64'});


export function loadingTransactions(isAdmin, t) {
    return (dispatch) => {
        dispatch(loadingTransactionsRequest())
        API.get(URLS.TRANSACTIONS.MY)
            .then((response) => {
                dispatch(loadingTransactionsSuccess(response?.data, isAdmin));
				// history.push('/dashboard');                
            })
            .catch((error) => {
                toast.error(error?.response?.data.detail ?? t("transaction_create_error_message"));
                // const errorMessage = formatError(error?.response?.data);
                dispatch(loadingTransactionsFailed());
                // dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loadingWalletTransactions(walletId) {
    return (dispatch) => {
        dispatch(loadingTransactionsRequest())
        API.get( URLS.TRANSACTIONS.WALLET(walletId)) 
            .then((response) => {
                dispatch(loadingTransactionsSuccess(response?.data, false));
				// history.push('/dashboard');                
            })
            .catch((error) => {
                toast.error(error?.response?.data.detail ?? "Loading transaction error !! Please try later");
                // const errorMessage = formatError(error?.response?.data);
                dispatch(loadingTransactionsFailed());
                // dispatch(loginFailedAction(errorMessage));
            });
    };
}
export function createTransaction(data, history, isNormalUser, t) {
    return (dispatch) => {
        const { user } = ls.get("user-details");
        dispatch(createTransactionRequest())
        if(data.bankDetails && !data.bankDetails?.id){
            API.post(URLS.USERS.BANK_DETAILS.CREATE, {...data.bankDetails, user: user.id}).then(resp => {
                // dispatch(createTransactionSuccess({}));
                API.post(URLS.TRANSACTIONS.CREATE, {...data, account_details: resp.data.id})
            .then((response) => {
                const {data} = response;
                dispatch(createTransactionSuccess(data));
				// history.push(isNormalUser ? '/transactions' : '/admin/transactions');
            })
            .catch((error) => {
                toast.error(error?.response?.data.detail ?? error?.response?.data.error ?? t("transaction_create_error_message"));
                // const errorMessage = formatError(error?.response?.data);
                dispatch(createTransactionFailed());
                // dispatch(loginFailedAction(errorMessage));
            });
            }).catch(errr => {
                toast.error(errr.response?.data.detail ?? errr.response?.data.error ?? t("transaction_create_error_message"));

            });

        } else if(data.bankDetails && data.bankDetails?.id){
            API.post(URLS.TRANSACTIONS.CREATE, {...data, account_details: data.bankDetails.id})
            .then((response) => {
                const {data} = response;
                dispatch(createTransactionSuccess(data));
                toast.success(t("transaction_create_success"))
				// history.push(isNormalUser ? '/transactions' : '/admin/transactions');
            })
            .catch((error) => {
                toast.error(error?.response?.data.detail ?? error?.response?.data.error ?? t("transaction_create_error_message"));
                // const errorMessage = formatError(error?.response?.data);
                dispatch(createTransactionFailed());
                // dispatch(loginFailedAction(errorMessage));
            });

        } else {
            API.post(URLS.TRANSACTIONS.CREATE, data)
            .then((response) => {
                const {data} = response;
                dispatch(createTransactionSuccess(data));
				history.push(`/monetary-statement/${data?.wallet}/`);
                toast.success(t("transaction_create_success"))
                
            })
            .catch((error) => {
                toast.error(error?.response?.data.detail ?? error?.response?.data.error ?? t("transaction_create_error_message"));
                // const errorMessage = formatError(error?.response?.data);
                dispatch(createTransactionFailed());
                // dispatch(loginFailedAction(errorMessage));
            });
        }
    };
}

export function editTransaction(id, data, history) {
    return (dispatch) => {
        dispatch(editTransactionRequest())
        API.put(URLS.TRANSACTIONS.EDIT(id), data)
            .then((response) => {
                dispatch(editTransactionSuccess(id, response?.data));
				// history.push('/admin/transactions');                
            })
            .catch((error) => {
                toast.error(error?.response?.data.detail ?? "Transaction edit failed!! Please try later");
                // const errorMessage = formatError(error?.response?.data);
                dispatch(editTransactionFailed());
                // dispatch(loginFailedAction(errorMessage));
            });
    };
}

// Delete wallet effect
export function deleteTransaction(id, history) {
    return (dispatch) => {
        dispatch(deleteTransactionRequest());
        const idToast = toast.loading("Please wait...");
        API.delete(URLS.TRANSACTIONS.DELETE(id))
            .then((response) => {
                toast.update(idToast, {render: "Transaction deleted successfully", type: "success", isLoading: false});
                dispatch(deleteTransactionSuccess(id));
				history.push('/admin/transactions');                
            })
            .catch((error) => {
                toast.update(idToast, {render: error?.response?.data.detail ?? "Transaction deletion failed !! Please try later", type: "error", isLoading: false, autoClose: true });
                // const errorMessage = formatError(error?.response?.data);
                dispatch(deleteTransactionFailed());
                // dispatch(loginFailedAction(errorMessage));
            });
    };
}


// Load wallet effect
export function loadTransaction(id) {
    return (dispatch) => {
        dispatch(loadTransactionRequest())
        API.get(URLS.TRANSACTIONS.GET(id))
            .then((response) => {
                dispatch(loadTransactionSuccess(response?.data));
				// history.push('/dashboard');                
            })
            .catch((error) => {
                toast.error(error?.response?.data.detail ?? "Loading wallet error!! Please try later");
                // const errorMessage = formatError(error?.response?.data);
                dispatch(loadTransactionFailed());
                // dispatch(loginFailedAction(errorMessage));
            });
    };
}