export default {
  translation: {
    "shared.french": "Francais",
    "shared.english": "English",
    "shared.spanish": "Spanish",
    "shared.italian": "Italian",
    "shared.norvegian": "Norvegian",
    "shared.portugues": "Portugues",
    "shared.sweden": "Sweden",
    "shared.deutsch": "Deutsch",
    home: "Hogar",
    home_welcome: "Bienvenido",
    home_you_can:
      "Ahora puede usar una cuenta real para operar con Forex, CFD, materias primas, acciones, índices en múltiples plataformas (web y móvil). Gracias por elegirnos",
    menu_contact_us: "Contáctenos",
    menu_my_account: "Mi cuenta",
    menu_logout: "Cerrar sesión",
    menu_personal_details: "Datos personales",
    menu_change_password: "Cambiar Contraseña",
    menu_trading_history: "Historial de transacciones",
    menu_upload_document: "Subir Documentos",
    menu_monetary_transaction: "Transacciones Monetarias",
    menu_wallet: "Carteras",
    menu_withdrawal: "Retiro",
    pd_first_name: "Nombre",
    pd_last_name: "Apellido",
    pd_email: "Correo electrónico",
    pd_phone_number: "Número de teléfono",
    pd_country: "País",
    pd_edit: "Editar",
    pd_city: "Ciudad",
    pd_post_code: "Código postal",
    form_save: "Guardar",
    edit_account: "Editar cuenta",
    field_mandatory: "Todos los campos marcados como *son obligatorios",
    change_password: "Cambiar Contraseña",
    old_password: "Contraseña antigua",
    pw_weak: "Débil",
    pw_medium: "Medio",
    pw_strong: "Fuerte",
    pw_confirm_pass: "Confirmar contraseña",
    pw_set_pass: "Confirmar contraseña",
    form_submit: "Enviar",
    pw_new_password: "Nueva Contraseña",
    table_display: "Pantalla",
    display_record: "registros",
    table_empty: "No hay datos disponibles en la tabla",
    th_symbol: "Símbolo",
    th_quantity: "Cantidad",
    th_open_date: "Fecha de apertura",
    th_close_date: "Fecha de cierre",
    th_open_price: "Precio de apertura",
    th_close_price: "Precio de cierre",
    th_position_type: "Comprar/Vender",
    th_comments: "Comentarios",
    th_profit_lost: "Beneficio / pérdida",
    upload_documents: "Subir Documentos",
    ud_upload_type: "Tipo de carga",
    ud_upload_date: "Fecha de carga",
    select_choose_option: "Elige una opción",
    ft_bank_file: "Archivo",
    ft_bank_wire: "Transferencia bancaria",
    ft_credit_card_front: "Frente de la tarjeta de crédito",
    ft_driving_license: "Licencia de conducir",
    ft_national_id_card: "Tarjeta Nacional de Identidad",
    ft_passport_id: "Identificación del pasaporte",
    ft_utility_bill: "Factura de servicios públicos",
    ft_dod: "DOD",
    ft_por: "POR",
    ft_id: "Identificación",
    ft_wire_details: "Detalles del cable",
    ft_wd_request_form: "Formulario de solicitud de WD",
    ft_credit_card_back: "Devolución de tarjeta de crédito",
    ft_other_doc_type: "Otro tipo de documento",
    file_uploaded: "Archivos subidos",
    wallet_details: "Detalles de la billetera",
    w_currency: "Moneda",
    w_cantidad: "Cantidad",
    w_status: "Estado",
    w_status_valid: "VÁLIDO",
    w_status_invalid: "NO VÁLIDO",
    w_currency: "Dirección de depósito",
    w_transactions_history: "Historial de transacciones",
    w_currency: "Moneda",
    wd_reference: "Referencia",
    wd_transaction_type: "Tipo de transacción",
    wd_amount: "Cantidad",
    wd_wallet: "Cartera",
    wd_status: "Estado",
    wd_operation_date: "Fecha de operación",
    wd_note: "Nota",
    wallet_list: "Lista de carteras",
    new_wallet: "Nueva billetera",
    create_wallet_description:
      "Ingrese la información de su billetera para crear.",
    choose_currency: "Elegir moneda",
    removal_page_description: `Al hacer clic en "Solicitar un retiro" se nos informará que
     le gustaría retirar fondos. El dinero será devuelto a
     su financiación con tarjeta de crédito o débito.`,
    wt_choose_wallet: "Elige una billetera",
    wt_amount: "Cantidad",
    wt_beneficiary: "Beneficiario",
    wt_bank_name: "Nombre del banco",
    wt_bank_address: "Dirección del banco",
    wt_bank_account_number: "Número de cuenta bancaria",
    wt_swift_code: "Código Swift",
    wt_wallet_address: "Dirección de la billetera",
    wt_relation_between: "Relación entre usted y el receptor",
    wt_confirm:
      "Reconozco que las transacciones con monederos criptográficos son arriesgadas y autorizo esta transacción validando los datos proporcionados.",
    wt_request_a_withdrawal: "Solicitar un Retiro",
    l_loading: "Cargando",
    login_to_start: "Iniciar sesión para comenzar a operar ahora mismo",
    login_to_start_description: `Puede registrarse ahora mismo para comenzar a practicar el comercio en un
     Cuenta de demostración GRATIS. Una vez que haya acumulado suficiente confianza,
     puede comenzar a operar de verdad. Pruebe la plataforma, encuentre
     su enfoque, y comience.`,
    dont_have_account: "No tengo cuenta",
    register_here: "Regístrese aquí",
    login_password: "Contraseña",
    login_email: "Correo electrónico",
    login_button: "INICIAR SESIÓN",
    live_registration: "Registro en vivo",
    información_personal: "Información personal",
    login_information: "Información de inicio de sesión",
    login_i_have_read: "He leído, entendido y acepto el",
    term_and_conditions: "Términos y Condiciones",
    registrarse_ahora: "REGISTRARSE AHORA",
    login_now: "Iniciar sesión ahora",
    now_have_ccount: "Ya tengo una cuenta",
    edit_profile_language: "Idioma",
    edit_profile_gender: "Sexo",
    edit_profile_gender_male: "Hombre",
    edit_profile_gender_female: "Mujer",
    edit_profile_birth_day: "Fecha de nacimiento",
    edit_profile_address: "Dirección",
    te_pw_failed_message:
      "Error en el cambio de contraseña, verifique su entrada y vuelva a intentarlo.",
    closed_order: "Posiciones cerradas",
    open_order: "Posiciones abiertas",
    menu_video_academy: "Academia de videos",
    w_deposit_address: "Dirección de depósito de la billetera",
    button_deposit_text: "Depósito",
    button_deposit_description: "Información del depósito.",
    qr_code: "Código QR",
    wallet_details_overview: "Resumen",
    wt_wallet_address_type: "Tipo de billetera",
    wallet_details_details: "Detalles",
    no_email_validation:
      "No hay clave de confirmación de correo electrónico, inténtalo de nuevo más tarde",
    email_address_validated:
      "Su dirección de correo electrónico fue verificada con éxito, puede iniciar sesión ahora",
    email_address_validation_error:
      "Vaya, se produjo un error al verificar su dirección de correo electrónico. Por favor, intente actualizar la página o inténtelo más tarde.",
    email_verification_pending:
      "Verificando su dirección de correo electrónico...",
    w_deposit_iban: "IBAN",
    deposit_infos: "Información del depósito",
    new_wallet_generate_address: "Generar dirección.",
     new_wallet_success: "Cartera creada con éxito",
     new_wallet_error: "Error de creación de billetera",
     menu_swap_transfert: "Intercambiar transferencia",
     transfert_money: "Transferir dinero",
     transfert_money_between_wallets: "Transferir dinero entre billeteras",
     wallet_to_withdraw: "Monedero para debitar",
     wallet_to_send_to: "Monedero a crédito",
     transfert_amount: "Importe de la Transferencia",
     swap_transfert_success: "Fondos transferidos con éxito",
     swap_transfert_error: "Ocurrió un error al transferir fondos, intente más tarde.",
     swap_transfert_cannot_process: "No podemos procesar la transferencia, complete toda la información requerida y vuelva a intentarlo",
     transfert_amount: "Cantidad",
     transfert_execute: "Ejecutar",
     return_to_courses: "Regresar a los cursos",
     crypto_trading: "Negociación de criptomonedas",
     crypto_trading_description: "En este curso, aprenderá todos los conceptos básicos para comenzar a operar en el mundo de las criptomonedas",
     introduction_and_history: "Introducción e historia",
     blockchain_and_descentralisation: "Blockchain y descentralización",
     major_cryptocurencies: "Principales criptomonedas",
     other_crypto_and_stable_coin: "Otras criptomonedas y monedas estables",
     nft: "NFT",
     market_actors: "Agentes del mercado",
     key_factors: "Impulsores clave del mercado criptográfico",
     trading_tools: "Herramientas de negociación de criptomonedas",
     psychologie_of_trading_and_risk_management: "Psicología del comercio de criptomonedas y gestión de riesgos",
     trade_cryptos: "Cómo intercambiar criptomonedas",
     conclusion: "Conclusión",
     market_analysis: "Análisis de mercado",
     market_analysis_description: "Este curso de análisis de mercado está destinado a aquellos que desean desarrollar su ventaja comercial y perfeccionar sus habilidades de análisis fundamental y técnico para tomar mejores decisiones comerciales",
     introduction: "Introducción",
     economic_analyse: "Análisis Económico",
     informations_and_trading_events: "Noticias y eventos comerciales",
     trading_events: "Eventos comerciales",
     key_economic_data: "Datos económicos clave",
     technical_analyse: "Análisis técnico",
     support_and_resistance: "Soporte y Resistencia",
     tendencias: "Tendencias",
     hauss_trends: "Tendencias al alza",
     bass_trends: "Tendencias a la baja",
     course_resume: "Resumen del curso",
     trading_psychology: "Psicología comercial",
     trading_psychology_description: "En este curso innovador, aprenderá cómo vencer al mayor enemigo del trading: ¡las emociones! Adopte la mentalidad de un trader exitoso, genere confianza en su trading",
     introduction: "Intro",
     fear_of_lost: "Miedo a la pérdida",
     surmont_the_fear_of_lost: "Superando el Miedo a la Pérdida",
     gourmandise: "avaricia",
     surmont_gourmandise: "Superar la codicia",
     position_management: "Gestión de Puestos",
     solutions_: "Soluciones",
     risk_management: "Gestión de Riesgos",
     financial_management_solutions: "Soluciones de administración de dinero",
     course_resume: "Resumen del curso",
     forex_strategy: "Estrategias comerciales",
     forex_strategy_description: "Este curso revelará una amplia gama de estrategias de negociación de divisas bajo demanda para todo tipo de comerciantes",
     day_trading: "Negociación diaria",
     news_trading: "Comercio de noticias",
     carry_trading: "Acarreo de operaciones",
     binary_trends_trading: "Comercio de tendencias",
     binary_swing_trading: "Comercio oscilante",
     chart_level_trading_of_binaries_option: "Negociación a nivel de gráfico",
     chartists_classics_figures: "Comercio con patrones de gráficos clásicos",
     technical_indicators_trading: "Negociación con Indicadores Técnicos",
     course_resume: "Resumen",
     trading_sociology: "Comercio social",
     trading_sociology_description: "El comercio social es un tipo de comercio en el que los inversores pueden observar las estrategias comerciales de otros comerciantes. Conviértase en un copiador y reproduzca las operaciones del proveedor, donde un proveedor es un comerciante experimentado que comparte sus estrategias de inversión.",
     what_interest: "¿Por qué hacer trading social?",
     how_to_process: "Elija un proveedor",
     advantages_and_inconvenients: "Ventajas y desventajas",
     course_resume: "Resumen",
     technical_indicators: "Indicadores Técnicos",
     technical_indicators_description: "Los métodos más comunes para analizar los indicadores de los mercados financieros, gráficos de velas japonesas y más.",
     graphiques_en_chandeliers: "Gráfico de velas",
     figures_des_chandeliers_part_1: "Patrones de velas Parte 1",
     figures_des_chandeliers_part_2: "Patrones de velas Parte 2",
     support_et_resistances: "Soporte y Resistencia",
     moyenne_mobile: "Promedio móvil",
     bandes_de_bollinger: "Bandas de Bollinger",
     retracements_de_fibonacci: "Retrocesos de Fibonacci",
     indice_de_force_relative_ou_rsi: "Índice de fuerza relativa",
     diversification_and_timing: "diversificación y sincronización",
     video_academy: "Academia de videos",
     video_academy_description: "Aquí puedes encontrar cursos que contienen lecciones en video.",
     video_academy_courses: "Cursos",
     active_orders: "Pedidos Activos",
    closed_orders: "Órdenes Cerradas",
    wt_balance: "Saldo",
    w_volume: "Volumen",
    form_choose_file: "Elegir archivo",
    ft_bank_statement: "Estado de cuenta bancario",
     ft_other: "Otro",
     accept_im_major_and_not_living_in_usa: "Confirmo que soy mayor de edad y que no soy ciudadano de los EE. UU.",
     accept_general_risk: `y estoy de acuerdo con <a href="https://StockBridge Capitaltrade.com/legalcontent/General_risk_disclosure.pdf" target="_blank"class="text-primary">divulgación general de riesgos</a>` ,
     btn_continue: "Continuar",
     deposit_amount_error: "El monto no puede ser mayor a 5000, comuníquese con un administrador de cuenta para un depósito mayor a 5000",
     selecte_a_payment_method: "Seleccione un método de pago",
     btn_previous: "Anterior",
     send_the_exact_amount_of: "Para hacer su depósito, envíe la cantidad exacta de",
     to_this_wallet: "A la siguiente dirección",
     or_scan_the_qrcode: "O escanear el código QR",
     timeleft_before_deposit: "Tiempo restante antes del depósito: ",
     btn_return_on_app: "Regresar a la aplicación.",
     deposit_important: "Importante",
     deposit_select_coin: "seleccionar moneda",
     deposit_deposit_to: "Depositar en",
     deposit_send_only: `Enviar solo {{moneda}} a
     esta dirección de depósito. Envío de cualquier otra moneda
     a esta dirección puede resultar en la pérdida de su
     depósito.`,
     deposit_ensure_network_is: "Asegúrese de que la red esté",
     deposit_deposit_address: "Dirección de depósito",
     deposit_show_qr: "Mostrar código QR",
     deposit_copy_qr: "Copiar dirección",
     deposit_tips: "Consejos",
     deposit_tips_1: "Aviso: las monedas se depositarán inmediatamente después de la confirmación de la red 2",
     deposit_track_progress: "Después de hacer un depósito, puedes seguir su progreso en el",
     deposit_history_page: "página de historial.",
     deposit_enter_card_details: "Por favor, ingrese los detalles de su tarjeta para pagar.", 
     btn_copy_text: "Copiar",
     payment_view_trans_id: "Identificación de la transacción",
     you_also_have_create: "También tienes que abrir",
     a_ticket: "un boleto.",
     menu_tickets: "Tickets de soporte",
     new_tickets: "Nuevo Boleto",
     ticket_subject: "Asunto",
     ticket_status: "Estado",
     ticket_last_updated: "Última actualización",
     ticket_details: "Detalles del boleto",
     ticket_view: "Vista de boletos",
     posted_on: "en",
     posted_by: "Publicado por",
     ticket_replies: "Respuestas",
     ticket_reply: "Responder",
     notificación_título: "Notificación",
     notificación_empty: "No tienes notificaciones",
     usr_request_call: "Solicitud de llamada",
     usr_request_call_severity: "Gravedad",
     usr_request_call_date: "Fecha de llamada deseada",
     usr_request_call_time: "Horario de llamada deseado",
     usr_request_call_subject: "Asunto de la llamada",
     usr_request_call_severity_normal: "Normal",
     usr_request_call_severity_urgent: "Urgente",
     usr_request_call_severity_very_u: "Muy urgente",
     usr_request_call_action_btn: "Solicitar una llamada",
     usr_request_call_request_sended:"Se recibió la solicitud de llamada, lo llamaremos a la hora mencionada.",
     password_reset_desc: "Por favor, ingrese su dirección de correo electrónico para restablecer la contraseña",
     password_reset_action_btn: "Restablecer la contraseña",
     password_recovery_action_btn: "Recuperar la contraseña",
     password_reset_reset: "Ingrese y confirme su nueva contraseña",
     deposit_card_holder: "Nombre del titular de la tarjeta",
     deposit_hide_qr: "Ocultar código QR",
     TR_PENDING: "Pendiente",
     TR_CANCELED: "Rechazado",
     TR_VALIDATED: "Validado",
     TR_AWAITING_FOR_APPROVAL: "Esperando aprobación",
     TR_ON_HOLD: "En espera",
     menu_upload_document_to_verify_identity: "Sube tus documentos para verificar tu identidad.",
     ft_tin: "ESTAÑO",
     menu_upload_document_to_verify_identity_success: "Hemos recibido sus documentos, los revisaremos y activaremos su cuenta si están verificados.",
     upload_failed_file_too_large: "Lo siento, el tamaño del archivo que subiste es más de 2Mb, y eso no está permitido.",
     text_copied_success: "¡Texto copiado!",
     ft_national_id_card_front: "Frente de la tarjeta de identificación",
     ft_national_id_card_back: "Reverso de tarjeta de identificación",
     ft_upload_national_id_card_back: "Subir dorso de tarjeta de identificación",
     ft_upload_national_id_card_front: "Cargar el frente de la tarjeta de identificación",
     account_status: "Verificación de cuenta",
     account_status_pending: "La verificación de su información está en progreso.",
     account_status_pending_text: "Nuestro equipo de verificación ha recibido sus documentos y su verificación está en curso. Recibirá un correo electrónico una vez que se aprueben sus documentos, podrá iniciar sesión y comenzar a realizar sus inversiones.",
     account_status_pending_signature: "Atentamente, el equipo de verificación de los mercados alcistas negros",
     account_status_pending_nb: "Nota: debido a la gran cantidad de solicitudes de verificación enviadas, la validación de sus documentos puede demorar entre 30 minutos y 24 horas. Gracias por su paciencia y hasta pronto para su primera inversión",
     payment_page_description: `Al hacer clic en "Solicitar un retiro" se nos informará que
     le gustaría retirar fondos. El dinero será devuelto a
     su financiación con tarjeta de crédito o débito.`,
     dashboard_login_wtrader: "Iniciar sesión en Web Trader",
     transaction_create_success: "¡Transacción creada con éxito!",
     transaction_create_error_message: "¡Error en la creación de la transacción! Inténtalo más tarde",
     transaction_loading_error_message: "¡Error de transacción al cargar! Inténtalo más tarde",
     files_loading_error_message: "¡Error al cargar el archivo!",
     files_upload_success_message: "Archivo subido con éxito",
     files_upload_error_message: "Falló la carga del archivo. Inténtalo de nuevo",
     wallets_creation_error_message: "¡Cartera creada con éxito!",
     wallets_creation_success_message: "¡Falló la creación de la cartera!",
     wallets_loading_error_message: "¡Error de carga!",
     wallets_loading_success_message: "¡Falló la creación de la cartera!",
     text_no_eth_wallet_for_swap: "Lo sentimos, pero no tiene una billetera Ethereum para pagar las tarifas de la red por esta transacción de intercambio.",
      text_cannot_generate_eth_wallet: "No puede generar una billetera ETH, actualice su tipo de cuenta y vuelva a intentarlo.",
      to_bbm: "al StockBridge Capital",
     account_status_2: "Estado de cuenta",
     account_verified: "Verificado",
     account_not_verified: "No verificado",
     date_registered: "Fecha de registro",
     txt_wm: "Métodos de retiro",
     txt_cw:"Continuar retiro",
     txt_tr:"SALA DE COMERCIO",
     txt_ob:"LIBRO DE PEDIDOS",
     txt_wl:"Lista de vigilancia",
     txt_ar:"Alertas e informes",
     txt_fh:"Historia financiera",
     txt_dashboard:"Panel de control",
     txt_tpv:"Valor total de la cartera",
     txt_trader_room:"Sala de comerciantes",
     txt_ar2:"Informe de cuenta",
     txt_ab:"Saldo disponible",
     txt_e_pnl:"PNL estimado",
     txt_td:"Depósito total",
     txt_taw:"Retiros totales aprobados",
     txt_p_bal:"Saldo del plan",
     txt_d:"Fecha",
     txt_ad:"Detalles de mi cuenta",
     txt_ud:"Documentos cargados",
     txt_mt:"Mis entradas",
     txt_up:"Actualizar contraseña",
     txt_w:"Retiros",
     txt_s_n:"S/N",
     txt_pm:"Método de pago",
     txt_at:"Todas las transacciones",
     txt_w_l:"Lista de deseos",
     txt_sa:"Buscar Activos",
     txt_ass:"Activos",
     txt_sell:"VENDER",
     txt_buy:"COMPRAR",
     txt_spread:"Difundir",
     txt_actions:"Acciones",
     TXT_:"",
     menu_withdraw: "Retirar",
     txt_close: "Cerrar",
txt_please_wait: "Por favor, espera...",
txt_position_closed: "Posición cerrada exitosamente",
txt_position_closed_error: "Y se produjo un error al cerrar su posición, intente comunicarse con su administrador de cuentas.",
txt_login_welcome: "Bienvenido a <strong>StockBridge Capital</strong>",
    txt_sign_in: "Iniciar sesión",
    txt_sign_up: "Registrarse",
    txt_to_access: "para acceder a su cuenta",
    txt_to_open: "abre tu cuenta",
    txt_forgot_password: "¿Olvidaste tu contraseña?",
    txt_not_client: "¿Aún no eres cliente StockBridge Capital <br />?",
    txt_open_account: "Abrir una cuenta",
    txt_main_page: "Página principal de StockBridge Capital",
    txt_contact_us_at: "Contáctanos en",
    txt_if_have_pb: "Si tienes problemas",
    txt_footer: "Operar con CFD implica un riesgo significativo de pérdida. Lea StockBridge Capital",
    txt_risk: "'Declaración de divulgación de riesgos'.",
    txt_not_membber: '¿No eres miembro?</span> <b className="ng-star-inserted">¡Regístrate ahora!',
    txt_already_member: "<span>¿Ya eres miembro?</span> <b>¡Inicia sesión ahora!</b>",
    txt_f: "",
  },
};
