import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
// Validator
import * as Yup from "yup";
import YupPassword from "yup-password";
import { Button } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { API } from "../../../../../services/axios";
import { URLS } from "../../../../../services/urls/urls";
import { logout } from "../../../../../store/effects/usersEffect";

YupPassword(Yup); // extend yup
export default function ChangePassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    OldPassword: Yup.string()
      .required("Password required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .minLowercase(1, "password must contain at least 1 lower case letter")
      .minUppercase(1, "password must contain at least 1 upper case letter")
      .minNumbers(1, "password must contain at least 1 number")
      .minSymbols(1, "password must contain at least 1 special character"),
    NewPassword: Yup.string()
      .required("Password required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .minLowercase(1, "password must contain at least 1 lower case letter")
      .minUppercase(1, "password must contain at least 1 upper case letter")
      .minNumbers(1, "password must contain at least 1 number")
      .minSymbols(1, "password must contain at least 1 special character"),
    ConfirmPassword: Yup.string().oneOf(
      [Yup.ref("NewPassword"), null],
      "Passwords must match"
    ),
  });
  return (
    <div title={"Change password"}>
      <div 
        className="q-page-container trade-room-layout" >
          <div className="">
            <div className="mb-3">
              <h3>{t("change_password")}</h3>
            </div>

            <Formik
              initialValues={{
                OldPassword: "",
                NewPassword: "",
                ConfirmPassword: "",
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                const data = {
                  old_password: values.OldPassword,
                  new_password: values.NewPassword,
                };
                API.post(URLS.AUTHS.CHANGE_PASSWORD, data)
                  .then((response) => {
                    dispatch(logout({ push: navigate }));
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    toast.error(t("update_pw_failed_message"));
                    setSubmitting(false);
                  });
                // dispatch(
                //   loginAction(values.email, values.password, {
                //     push: navigate,
                //   })
                // );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                /* and other goodies */
              }) => (
                <form
                  className="form"
                  id="actionForm"
                  novalidate="novalidate"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}
                >
                  <div className="px-3">

                  <div className="mb-3">
                    <div className="">
                      <div>
                        <label htmlFor="OldPassword">
                          <span> {t("old_password")} <span className="text-error">*</span>:</span>
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className="ta-right">
                        <div className="row-control">
                          <div className="formset">
                            <input
                              id="OldPassword"
                              name="OldPassword"
                              type="password"
                              onChange={handleChange}
                              value={values.OldPassword}
                              className="form-control"
                            />
                            <span className="error">
                              <span className="field-validation-valid">
                                {errors.OldPassword}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="">
                      <div>
                        <label htmlFor="NewPassword">
                          <span>{t("pw_new_password")} <span className="text-error">*</span>:</span>
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className="ta-right">
                        <div className="row-control">
                          <div className="formset">
                            <input
                              className=" form-control"
                              id="NewPassword"
                              name="NewPassword"
                              type="password"
                              onChange={handleChange}
                              value={values.NewPassword}
                            />
                            <span className="error">
                              <PasswordStrengthBar password={values.NewPassword} />
                              <span className="field-validation-valid">
                                {errors.NewPassword}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row"></div>
                  <div className="">
                    <div className="">
                      <div>
                        <label htmlFor="ConfirmPassword">
                          <span>{t("pw_confirm_pass")} <span className="text-error">*</span>:</span>
                        </label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="ta-right">
                        <div className="row-control">
                          <div className="formset">
                            <input
                              id="ConfirmPassword"
                              name="ConfirmPassword"
                              type="password"
                              onChange={handleChange}
                              value={values.ConfirmPassword}
                              className="form-control"
                            />
                            <span className="error">
                              <span className="field-validation-valid">
                                {errors.ConfirmPassword}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ta-left">
                    <div className="col-md-12 py-15">
                      <div className="d-flex">
                        <div className="w-100 ml-auto">
                          <Button
                            type="submit"
                            className="button no-border py-3 w-100"
                            disabled={isSubmitting || !isValid}
                          >
                            <span>
                              <span>
                                {isSubmitting ? (
                                  <BeatLoader color="#ffffff" size={7} />
                                ) : (
                                  t("form_submit")
                                )}{" "}
                              </span>
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
    </div>
  );
}
