import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { useSSR, useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { URLS } from "../../../../services/urls/urls";
import useSWR from "swr";
import { editAccount } from "../../../../store/effects/usersEffect";
import ChangePassword from "./components/ChangePassword";


export default function AccountDetails() {
    const { user } = useSelector((state) => state.auth.auth);
    const { data: countriesList } = useSWR(URLS.UTILS.COUNTRIES.LIST(""));

    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const toggleLoader = (isLoading) => {
        setIsSubmitting(isLoading);
    }

    return (
        <Fragment title="Edit account">
            <div
            ><div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
                    <h2 className="font-w600 mb-2 me-auto ">{t("edit_account")}</h2>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className=" card-body">
                                <div className="required reversed mb-3">{t("field_mandatory")}</div>
                                {user && (
                                    <Formik
                                        initialValues={{
                                            email: user.email,
                                            FirstName: user.first_name,
                                            LastName: user.last_name,
                                            Country: user.country?.id,
                                            PhoneNumber: user.phone_number,
                                            City: user.city?.id ?? "",
                                            Area: user.area ?? "",
                                            PostCode: user.zip_code ?? "",
                                            gender: user.gender ?? "",
                                            zip_code: user.zip_code ?? "",
                                            language: user.language ?? "",
                                            address: user.address ?? "",
                                            dateOfBirth: user.date_of_birth ?? undefined,
                                        }}
                                        // validate={(values) => {
                                        //   const errors = {};

                                        //   if (!values.email) {
                                        //     errors.email = "Required";
                                        //   } else if (
                                        //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                        //   ) {
                                        //     errors.email = "Invalid email address";
                                        //   }

                                        //   return errors;
                                        // }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            // dispatch(
                                            //   loginAction(values.email, values.password, {
                                            //     push: navigate,
                                            //   })
                                            // );
                                            const data = {
                                                first_name: values.FirstName,
                                                last_name: values.LastName,
                                                email: values.email,
                                                country: values.Country,
                                                phone_number: values.PhoneNumber,
                                                city: values.City,
                                                address: values.address,
                                                zip_code: values.PostCode,
                                                language: values.language,
                                                date_of_birth: values.dateOfBirth,
                                                // address: values.address,
                                            };
                                            dispatch(editAccount(user.id, data, setSubmitting));
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            /* and other goodies */
                                        }) => (
                                            <form
                                                action="/edit-account"
                                                className="form"
                                                id="actionForm"
                                                method="post"
                                                role="form"
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="">
                                                    <div className="row">

                                                        <div className="col-md-6 mb-3">
                                                            <div>
                                                                <label htmlFor="FirstName">
                                                                    <span>{t("pd_first_name")}:</span>
                                                                </label>
                                                            </div>
                                                            <div className="">
                                                                <div className="formset">
                                                                    <input
                                                                        id="FirstName"
                                                                        name="FirstName"
                                                                        type="text"
                                                                        value={values.FirstName}
                                                                        onChange={handleChange}
                                                                        className="form-control"
                                                                    />
                                                                    <span className="error">
                                                                        <span className="field-validation-valid">
                                                                            {errors.FirstName}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="LastName">
                                                                <span>{t("pd_last_name")}:</span>
                                                            </label>
                                                            <div className="row-control">
                                                                <div className="formset">
                                                                    <input
                                                                        id="LastName"
                                                                        name="LastName"
                                                                        type="text"
                                                                        value={values.LastName}
                                                                        onChange={handleChange}
                                                                        className="form-control"
                                                                    />
                                                                    <span className="error">
                                                                        <span className="field-validation-valid">
                                                                            {errors.LastName}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="Email">
                                                                <span>{t("pd_email")}:</span>
                                                            </label>
                                                            <div className="row-control">
                                                                <div className="formset">
                                                                    <input
                                                                        id="email"
                                                                        name="email"
                                                                        readonly="readonly"
                                                                        type="email"
                                                                        value={values.email}
                                                                        onChange={handleChange}
                                                                        className="form-control"
                                                                    />
                                                                    <span className="error">
                                                                        <span className="field-validation-valid">
                                                                            {errors.email}
                                                                        </span>
                                                                    </span>
                                                                    <span
                                                                        id="uniqueEmailError"
                                                                        className="error"
                                                                        style={{ display: "none" }}
                                                                    >
                                                                        This Email is already registered
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="Country">
                                                                <span>
                                                                    {t("pd_country")}: <span className="text-danger">*</span>
                                                                </span>
                                                            </label>

                                                            {countriesList?.results && <div className="row-control">
                                                                <div className="formset">
                                                                    <div className="selector fixedWidth" id="uniform-Country">
                                                                        <select
                                                                            className="form-control"
                                                                            id="Country"
                                                                            name="Country"
                                                                            value={values.Country}
                                                                            onChange={handleChange}
                                                                        >
                                                                            <option value="">Select...</option>

                                                                            {countriesList?.results.map((country) => {
                                                                                return (
                                                                                    <option
                                                                                        key={country.slug}
                                                                                        value={
                                                                                            country.url.split("/")[
                                                                                            country.url.split("/").length - 2
                                                                                            ]
                                                                                        }
                                                                                    >
                                                                                        {country.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <span className="error">
                                                                    <span
                                                                        className="field-validation-valid"
                                                                    ></span>
                                                                </span>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="PhoneNumber">
                                                                <span>{t("pd_phone_number")}:</span>
                                                            </label>
                                                            <div className="row-control">
                                                                <div className="formset">
                                                                    <div className="input-group rounded-0">
                                                                        <div class="input-group-text">+{countriesList?.results.filter((countr) => countr.url.split("/")[
                                                                            countr.url.split("/").length - 2
                                                                        ] == values.Country)[0]?.phone}</div>
                                                                            <input
                                                                                autocomplete="off"
                                                                                id="PhoneNumber"
                                                                                name="PhoneNumber"
                                                                                placeholder="Phone Number: *"
                                                                                type="text"
                                                                                value={values.PhoneNumber}
                                                                                onChange={handleChange}
                                                                                className="form-control valid"
                                                                            />
                                                                    </div>
                                                                </div>
                                                                <span className="error">
                                                                    <span
                                                                        className="field-validation-valid"
                                                                        data-valmsg-for="PhoneAreaCode"
                                                                        data-valmsg-replace="true"
                                                                    ></span>
                                                                </span>
                                                                <span className="error">
                                                                    <span className="field-validation-valid"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="address">
                                                                <span>{t("edit_profile_address")}:</span>
                                                            </label>
                                                            <div className="row-control">
                                                                <div className="formset">
                                                                    <input
                                                                        id="address"
                                                                        name="address"
                                                                        type="text"
                                                                        value={values.address}
                                                                        onChange={handleChange}
                                                                        className="form-control"
                                                                    />
                                                                    <span className="error">
                                                                        <span className="field-validation-valid">
                                                                            {errors.address}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="language">
                                                                <span>
                                                                    {t("edit_profile_language")}: <span className="text-danger">*</span>
                                                                </span>
                                                            </label>
                                                            <div className="row-control">
                                                                <div className="formset">
                                                                    <div
                                                                        className="selector fixedWidth"
                                                                        id="uniform-language"
                                                                    >
                                                                        <select
                                                                            className="form-control valid"
                                                                            id="language"
                                                                            name="language"
                                                                            value={values.language}
                                                                            onChange={handleChange}
                                                                        >
                                                                            <option value="">Select...</option>
                                                                            <option value="FR">{t("shared.french")}</option>
                                                                            <option value="EN">{t("shared.english")}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <span className="error">
                                                                    <span className="field-validation-valid"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="gender">
                                                                <span>
                                                                    {t("edit_profile_gender")}: <span className="text-danger">*</span>
                                                                </span>
                                                            </label>
                                                            <div className="row-control">
                                                                <div className="formset">
                                                                    <div className="selector fixedWidth" id="uniform-gender">
                                                                        <select
                                                                            className="form-control valid"
                                                                            id="gender"
                                                                            name="gender"
                                                                            value={values.gender}
                                                                            onChange={handleChange}
                                                                        >
                                                                            <option value="">Select...</option>
                                                                            <option value="MALE">
                                                                                {t("edit_profile_gender_male")}
                                                                            </option>
                                                                            <option value="FEMALE">
                                                                                {t("edit_profile_gender_female")}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <span className="error">
                                                                    <span className="field-validation-valid"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="City">
                                                                <span>{t("edit_profile_birth_day")}:</span>
                                                            </label>
                                                            <div className="row-control">
                                                                <div className="formset">
                                                                    <input
                                                                        id="dateOfBirth"
                                                                        name="dateOfBirth"
                                                                        type="date"
                                                                        value={values.dateOfBirth}
                                                                        onChange={handleChange}
                                                                        className="form-control"
                                                                    />
                                                                    <span className="error">
                                                                        <span className="field-validation-valid">
                                                                            {errors.dateOfBirth}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label htmlFor="PostCode">
                                                                <span>{t("pd_post_code")}:</span>
                                                            </label>
                                                            <div className="row-control">
                                                                <div className="formset">
                                                                    <input
                                                                        id="PostCode"
                                                                        name="PostCode"
                                                                        type="text"
                                                                        onChange={handleChange}
                                                                        value={values.PostCode}
                                                                        className="form-control"
                                                                    />
                                                                    <span className="error">
                                                                        <span className="field-validation-valid">
                                                                            {errors.PostCode}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row ta-center">
                                                        <div className="">
                                                            <Button className="button no-border py-3 px-5" type="submit" disabled={isSubmitting}>
                                                                <span>
                                                                    {isSubmitting ? (
                                                                        <BeatLoader color="#ffffff" />
                                                                    ) : (
                                                                        <span>{t("form_submit")}</span>
                                                                    )}
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <ChangePassword />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
