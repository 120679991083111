import { useTranslation } from "react-i18next";



export default function TransactionHead() {

    const { t } = useTranslation();

    return (
        <thead>
            <tr role="row">
                <th className="text-left text-body1 text-info">
                </th>
                <th className="">
                    {t("wd_reference")}
                </th>
                <th className="">
                    {t("txt_pm")}
                </th>
                <th className="">
                    {t("w_status")}
                </th>
                <th className="">
                    {t("w_amount")}
                </th>
                <th className="">
                    {t("th_comments")}
                </th>
                <th className="">
                    {t("txt_d")}
                </th>
            </tr>
        </thead>
    )
}