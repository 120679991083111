import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../components/utils/functions";
import { API } from "../../../../services/axios";
import { URLS } from "../../../../services/urls/urls";
import useSWR from "swr";
import { IoCashOutline, IoBarChartOutline } from "react-icons/io5";
import { PiHandDeposit } from "react-icons/pi";
import { PiHandWithdraw } from "react-icons/pi";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";




const AlertReport = () => {
  const [totalPL, setTotalPL] = useState(0);
  const [selectedWallet, setSelectedWallet] = useState();
  const [totalPositions, setTotalPositions] = useState([]);

  const { t } = useTranslation();

  const { data: walletsList, isLoading: isLoadingWallets } = useSWR(`${URLS.USERS.MY_WALLETS("")}`);

  const {
    auth: { user },
  } = useSelector((state) => state.auth);



  useEffect(() => {
    if (walletsList) {
      setSelectedWallet(
        walletsList?.results?.filter((item) => item.currency === "USD")[0] ??
        walletsList?.results?.filter((item) => item.currency === "EUR")[0]
      );
      // {formatCurrency(selectedWallet?.currency) ?? ""}{" "} {selectedWallet?.balance ? Number(selectedWallet?.balance ?? 0.0).toFixed(2) : ""}
    }
  }, [walletsList]);

  useEffect(() => {
    API.get(URLS.USERS.MY_POSITIONS + '?page_size=10000000').then((resp) => {
      if(resp.data){
        setTotalPositions(resp.data.results);
        setTotalPL(_.sum(totalPositions.map(pos => pos.profit_lost)));
      }
    }).catch((error) => {});
  }, []);

  return (
    <Fragment>
    <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
            <h3 className="font-w600 mb-0 me-auto mb-2 text-black">{t("txt_ar2")}</h3>
        </div>
      <div
        className=""
      >
        <div
          className=""
          style={{ minHeight: 508 }}
        >
          <div className="absolute-cente bg-primary q-mt-sm"></div>
          <div className="row text-white q-col-gutter-md q-mt-sm">
            <div
              className="col-xs-12 col-sm-6 col-md-4 mb-3"
              style={{ height: 200 }}
            >
              <div
                className="border-start border-3 p-3 d-block h-100"
                style={{ borderRadius: 4, backgroundColor: "rgb(8, 102, 198)" }}
              >
                <div className="q-card__section q-card__section--vert">
                  <div className="text-subtitle1 h3">{t("txt_ab")}</div>
                  <div className="text-h4 h4">
                    {formatCurrency(selectedWallet?.currency) ?? ""}{" "}
                    {selectedWallet?.balance
                      ? Number(selectedWallet?.balance ?? 0.0).toFixed(2)
                      : ""}
                  </div>
                </div>
                <div className="q-card__section q-card__section--vert" side="">
                  <span
                    className="q-icon q-pb-sm"
                    aria-hidden="true"
                    role="presentation"
                    style={{ fontSize: 44 }}
                  >
                    <IoCashOutline />
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-6 col-md-4 mb-3"
              style={{ height: 200 }}
            >
              <Link
              to={"/my-orders-book"}
                className="border-start border-3 p-3 d-block h-100"
                style={{ borderRadius: 4, backgroundColor: "rgb(35, 191, 8)" }}
              >
                <div className="q-card__section q-card__section--vert">
                  <div className="text-subtitle1 h3">{t("txt_e_pnl")}</div>
                  <div className={`text-h4 h4 text-${user?.estimated_pnl > 0 ? 'white' : 'danger'}`}>{formatCurrency(user?.default_currency)}{user?.estimated_pnl}</div>
                </div>
                <div className="q-card__section q-card__section--vert" side="">
                  <span
                    className="text-white"
                    aria-hidden="true"
                    role="presentation"
                    style={{ fontSize: 44 }}
                  >
                    <IoBarChartOutline />
                  </span>
                </div>
              </Link>
            </div>
            <div
              className="col-xs-12 col-sm-6 col-md-4 mb-3"
              style={{ height: 200 }}
            >
              <Link
              to={"/transactions"}
                className="border-start border-3 p-3 d-block h-100"
                style={{
                  borderRadius: 4,
                  backgroundColor: "rgb(244, 153, 23)",
                }}
              >
                <div className="q-card__section q-card__section--vert">
                  <div className="text-subtitle1 h3">{t("txt_td")}</div>
                  <div className="h4">{formatCurrency(user?.default_currency)}{user?.total_deposits}</div>
                </div>
                <div className="q-card__section q-card__section--vert" side="">
                  <span
                    className="text-white"
                    aria-hidden="true"
                    role="presentation"
                    style={{ fontSize: 44 }}
                  >
                    <PiHandDeposit />
                  </span>
                </div>
              </Link>
            </div>
            <div
              className="col-xs-12 col-sm-6 col-md-4 mb-3 mt-3"
              style={{ height: 200 }}
            >
              <Link
              to={"/transactions"}
                className="border-start border-3 p-3 d-block h-100"
                style={{ borderRadius: 4, backgroundColor: "rgb(206, 36, 14)" }}
              >
                <div className="q-card__section q-card__section--vert">
                  <div className="h3">
                    {t("txt_taw")}
                  </div>
                  <div className="h4">{formatCurrency(user?.default_currency)}{user?.total_withdrawals}</div>
                </div>
                <div className="q-card__section q-card__section--vert" side="">
                  <span
                    className="text-white"
                    aria-hidden="true"
                    role="presentation"
                    style={{ fontSize: 44 }}
                  >
                    <PiHandWithdraw />
                  </span>
                </div>
              </Link>
            </div>
            <div
              className="col-xs-12 col-sm-6 col-md-4 mb-3"
              style={{ height: 200 }}
            >
              <div
                className="border-start border-3 p-3 d-block h-100 mt-3"
                style={{ borderRadius: 4, backgroundColor: "rgb(32, 175, 19)" }}
              >
                <div className="q-card__section q-card__section--vert">
                  <div className="h3">{t("txt_p_bal")}</div>
                  <div className="h4">{formatCurrency(user?.default_currency)}{user?.plan_balance}</div>
                </div>
                <div className="q-card__section q-card__section--vert" side="">
                  <span
                    className="q-icon q-pb-sm"
                    aria-hidden="true"
                    role="presentation"
                    style={{ fontSize: 44 }}
                  >
                    <MdOutlineAccountBalanceWallet />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AlertReport;
