import { useState, useEffect, Fragment, useContext } from "react";
import { Spinner } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { URLS } from "../../../../services/urls/urls";
import useSWR from "swr";
import { API } from "../../../../services/axios";
import { formatAmount, formatCurrency } from "../../../components/utils/functions";
import { ETH_BASED_CURRENCIES, ETH_BASED_NETWORK } from "../../../components/utils/consts";
import { ThemeContext } from "../../../../context/ThemeContext";
import { SiConvertio } from "react-icons/si";


export default function TransfertMoney() {
	const { background } = useContext(ThemeContext);
  const [transfering, setTransfering] = useState();
  const [walletToDebit, setWalletToDebit] = useState();
  const [walletToCredit, setWalletToCredit] = useState();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [amountToReceive, setAmountToReceive] = useState(0);

  const { t } = useTranslation();
  const { data: wallets, isLoading: isLoadingWallets } = useSWR(`${URLS.USERS.MY_WALLETS("")}`);

  const handleChangeDebitWallet = (event) => {
    const wallet = wallets.results.filter((wal) => wal.id === event.target.value);
    const ethWallet = wallets.results.filter((wal) => wal.currency === "ETH");
    if (wallet) {
      if(ETH_BASED_CURRENCIES.includes(wallet[0].currency) && ETH_BASED_NETWORK.includes(wallet[0].crypto_deposits?.wallet_type) && !ethWallet?.length > 0){
        // If user want to swap from Ethereum network based wallet and don't have an ETH wallet, we must not allow the transaction, because, we need to pay gas.

        toast.error(t("text_no_eth_wallet_for_swap"));
        // setWalletToDebit(wallet[0]);

      } else {

        setWalletToDebit(wallet[0]);
      }
    }
  };

  const handleChangeCreditWallet = (event) => {
    const wallet = wallets.results.filter((wal) => wal.id === event.target.value);
    if (wallet) {
      setWalletToCredit(wallet[0]);
    }
  };

  const handleChangeAmount = (event) => {
    const value = event.target.value;
    if(walletToDebit){
      if (value <= walletToDebit.balance) {
        setAmount(value);
      }
    }
  };

  const handleConvertMoney = () => {

    setLoading(true);
    const data = {
      frm: walletToDebit?.currency,
      to: walletToCredit?.currency,
      amount,
    };
    API.post(URLS.WALLETS.CONVERT, data)
      .then((resp) => {
        const { data } = resp;
        setAmountToReceive(data.result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleTransfert = () => {
    const ethWallet = wallets.results.filter((wal) => wal.currency === "ETH");
    if(walletToDebit && ETH_BASED_CURRENCIES.includes(walletToDebit?.currency) && ETH_BASED_NETWORK.includes(walletToDebit?.crypto_deposits?.wallet_type) && !ethWallet?.length > 0){
      // If user want to swap from Ethereum network based wallet and don't have an ETH wallet, we must not allow the transaction, because, we need to pay gas.

      toast.error(t("text_no_eth_wallet_for_swap"));
      // setWalletToDebit(wallet[0]);

    } else {
    if (amount && walletToCredit && walletToDebit) {
      setTransfering(true);
      const data = {
        debited_amount: parseFloat(amount),
        credited_amount: 0,
        reference: "string",
        debited_wallet: walletToDebit.id,
        credited_wallet: walletToCredit.id,
      };
      API.post(URLS.WALLETS.SWAP_TRANSFERT, data)
        .then((reps) => {
          toast.success(t("swap_transfert_success"));
          setTransfering(false);
        })
        .catch((error) => {
          toast.error(t("swap_transfert_error"));
          setTransfering(false);
        });
    } else {
      toast.error(t("swap_transfert_cannot_process"));
    }
  }
  };

  const dispatch = useDispatch();


  useEffect(() => {
    if (walletToCredit && walletToDebit && amount) {
      handleConvertMoney();
    }
  }, [walletToCredit, walletToDebit, amount]);
  return (
    <Fragment title={t("transfert_money_between_wallets")}>
      <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
            <h3 className="font-w600 mb-0 me-auto mb-2 text-black">{t("transfert_money")}</h3>
        </div>
      <div
        className="q-page-container trade-room-layout"
      >
        <div
        >
      <div id="targetDiv" className="loading-area">
        <div className="money-transfer">
          <div className="form">
            <fieldset>
              <div className="row position-relative">
                <div className="convertion-icon text-center">
                  <SiConvertio size={40} />
                </div>
                <div className="col-lg-6">
                  <div className="card my-2">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-6 col-12">
                          <div>
                            <label>
                              <span>{t("wallet_to_withdraw")}: </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div>
                            <div
                              className="ml-auto fixedWidth"
                              id="uniform-TpAccountNameFrom"
                            >
                              {/* <span style="user-select: none;">193160</span> */}
                              <select
                                id="TpAccountNameFrom"
                                name="TpAccountNameFrom"
                                onChange={handleChangeDebitWallet}
                                value={walletToDebit?.id ?? ""}
                                className="form-control"
                                style={{backgroundColor: background.value === "dark" ? "#191b26" : "#f2f4fa"}}
                              >
                                <option value="">
                                  {t("wt_choose_wallet")}
                                </option>
                                {wallets?.results?.map((wallet) => {
                                  return (
                                    <option key={wallet.id} value={wallet.id}>
                                      {formatAmount(wallet.balance)} ({formatCurrency(wallet.currency)})
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6 col-12">
                          <div>
                            <label htmlFor="Amount">
                              <span>
                                {t("transfert_amount")}: <span>*</span>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div>
                            <div className="">
                              <div className="clearfix">
                                <div className="formitems">
                                  <div className="formitem col-xs-9">
                                    <input
                                      id="amount"
                                      name="amount"
                                      type="number"
                                      value={amount}
                                      onChange={handleChangeAmount}
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="formitem col-xs-3 formtext fw500"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card my-2">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-6 col-12">
                          <div>
                            <label>
                              <span>{t("wallet_to_send_to")}: </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div>
                            <div
                              className="ml-auto fixedWidth"
                              id="uniform-TpAccountNameFrom"
                            > 
                              {/* <span style="user-select: none;">193160</span> */}
                              <select
                                id="TpAccountNameFrom1"
                                name="TpAccountNameFrom1"
                                className="form-control"
                                onChange={handleChangeCreditWallet}
                              >
                                <option value="">
                                  {t("wt_choose_wallet")}
                                </option>
                                {wallets?.results
                                  ?.filter((wal) => wal.id != walletToDebit?.id)
                                  .map((wallet) => {
                                    return (
                                      <option key={wallet.id} value={wallet.id}>
                                        {formatAmount(wallet.balance)} ({formatCurrency(wallet.currency)})
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6 col-12">
                          <div>
                            <label>
                              <span>
                                {t("transfert_amount")}:{" "}
                                <i
                                  className="icon-question-right tooltip"
                                  data-tooltip-content="#transfermoney-tooltip-content-to"
                                >
                                  &nbsp;
                                </i>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div>
                            <div className="">
                              <div className="profile-info">
                                {!loading && (
                                  <span id="balanceTo">{amountToReceive} {formatCurrency(walletToCredit?.currency)}</span>
                                )}
                                <span id="currencyTo">
                                  {loading && <Spinner />}
                                </span>
                                {/* <span id="currencyTo">USD</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 
              <div className="row">
                <label>
                  <span>Rate: </span>
                </label>
                <div className="profile-info">
                  <span id="rateSpan">1</span>
                </div>
              </div>
              <div className="row">
                <label>
                  <span>Transfer amount: </span>
                </label>
                <div className="profile-info">
                  <span id="transferAmountSpan">0</span>
                </div>
              </div> */}
              <div className="row text-center">
                <div className="col-12">
                  <Link
                    disabled={transfering}
                    href="#"
                    className="btn btn-primary btn-resize-mb"
                    id="submitButton"
                    // href="#"
                    onClick={handleTransfert}
                  >
                    <span>
                      {!transfering && <span>{t("transfert_execute")}</span>}
                      {transfering && <Spinner />}
                    </span>
                  </Link>
                </div>
              </div>
              <div
                id="loadingDiv"
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                  display: "none",
                }}
              >
                <img src="/Content/images/AjaxLoader.gif" />
              </div>
              <input
                id="CurrencyFrom"
                name="CurrencyFrom"
                type="hidden"
                value=""
              />
              <input id="CurrencyTo" name="CurrencyTo" type="hidden" value="" />
              <input id="Rate" name="Rate" type="hidden" value="1" />
              <input
                data-val="true"
                data-val-number="The field TransferAmount must be a number."
                data-val-required="The TransferAmount field is required."
                id="TransferAmount"
                name="TransferAmount"
                type="hidden"
                value="0"
              />
              <input
                id="TransferMoneyConfirm"
                type="hidden"
                value="Are you sure?"
              />
              <input
                id="SetRateAndTransferAmountError"
                type="hidden"
                value="You can't transfer different currency now. Please, try later"
              />
            </fieldset>
          </div>{" "}
        </div>
      </div>
      </div>
      </div>
    </Fragment>
  );
}
