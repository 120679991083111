import { useTranslation } from "react-i18next";
import Webtrader from "./Webtrader";
import { Fragment, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { IoAdd, IoClose } from "react-icons/io5";
import SecureLS from "secure-ls";


const ls = new SecureLS({encodingType: "base64"});

const TradingRoom = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState();

  const handleAddSymbol = () => {
    setTabs((prev) => prev.concat({ panel: prev.length + 1, title: "BTC" }));
    setActiveTab({ panel: tabs.length + 1, title: "BTC" });
  };

  const handleRemoveSymbol = (index) => {
    setTabs((prev) => prev.filter((item) => item?.panel !== index));
  };

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const handleRenameTab = (tab) => {
    setTabs((prev) => prev.map((item) => item.panel === tab.panel ? { ...item, title: tab.title } : item));
  }

  useEffect(() => {
    const deaultTab = {
      panel: "1",
      title: "BTC"
    }
    const savedTabs = ls.get("chartTabs");
    if (savedTabs?.length > 0) {
      console.log(savedTabs);
      setTabs(savedTabs);
      setActiveTab(savedTabs[0]);
    } else {
      setTabs([deaultTab]);
      setActiveTab(deaultTab);
    }
  }, []);

  useEffect(() => {
    ls.set("chartTabs", tabs);
  }, [tabs])


  return (
    <Fragment>
      <div
        className=""
      >
        <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
          <h3 className="font-w600 mb-0 me-auto mb-2 text-black">{t("txt_trader_room")}</h3>
        </div>
        <div className="card-action card-tabs mb-3 style-1">
          {/* <Nav as="ul" className="nav-tabs" role="tablist">
            {
              tabs?.map((tab, index) => (
                <Nav.Item as="li" className="nav-item d-flex me-2 mb-2" key={"tabitem-" + index}>
                  <Nav.Link className="active rounded-0 mb-0" to={"#"} onClick={() => handleChangeTab(tab)}>{tab.title}</Nav.Link>
                  <button className="btn btn-default h-100 rounded-0" onClick={() => handleRemoveSymbol(tab.panel)}>
                    <IoClose />
                  </button>
                </Nav.Item>
              ))
            }
            <Nav.Item as="li" className="nav-item">
              <Nav.Link to={"#"} eventKey="balance" className="rounded-0 btn-primary" style={{ height: "41px" }}  onClick={handleAddSymbol} >
                <IoAdd />
              </Nav.Link>
            </Nav.Item>
          </Nav> */}
        </div>
        <div
          className=""
        >
          {
            <Webtrader tab={activeTab} handleRenameTab={handleRenameTab} />
          }
        </div>
      </div>
    </Fragment>
  );
};

export default TradingRoom;
