

export const LOADING_COUNTRIES_REQUEST = "LOADING_COUNTRIES_REQUEST";
export const LOADING_COUNTRIES_SUCCESS = "LOADING_COUNTRIES_SUCCESS";
export const LOADING_COUNTRIES_FAILED = "LOADING_COUNTRIES_FAILED";

export const PAYMENTS_METHODS = "PAYMENTS_METHODS";
export const CHANGE_DARK_MODE = "CHANGE_DARK_MODE";


export const LOAD_MARKET_PRICES = "LOAD_MARKET_PRICES";


export const changeDarkMode = (data) => {

    return {
        type: CHANGE_DARK_MODE,
        payload: data
    }
}

export const loadingCountriesRequest = () => {

    return {
        type: LOADING_COUNTRIES_REQUEST
    }
}

export const loadingCountriesSuccess = (data) => {

    return {
        type: LOADING_COUNTRIES_SUCCESS,
        payload: data
    }
}

export const loadingCountriesFiled = () => {

    return {
        type: LOADING_COUNTRIES_FAILED
    }
}

export const loadingMarketPrices = (data) => {

    return {
        type: LOAD_MARKET_PRICES,
        payload: data
    }
}

export const paymentData = (data) => {

    return {
        type: PAYMENTS_METHODS,
        payload: data
    }
}