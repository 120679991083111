import { TbLayoutDashboard } from "react-icons/tb";
import { MdOutlineManageAccounts } from "react-icons/md";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { AiOutlineDashboard } from "react-icons/ai";
import { IoBookSharp } from "react-icons/io5";
import { PiProjectorScreenChart } from "react-icons/pi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { MdOutlineHistory } from "react-icons/md";
import { AiOutlineSwap } from "react-icons/ai";
import { MdOndemandVideo, MdDashboard } from "react-icons/md";


export const MenuList = [
    {
        title: 'Dashboard',
        iconStyle: <MdDashboard size={20} />,
        to: 'dashboard',					
    },
    {
        title: 'My Account',
        iconStyle: <MdOutlineManageAccounts size={20} />,
        classsChange: 'mm-collapse',
        content: [
            {
                title: 'Account Details',
                to: 'my-account',
            },
            {
                title: 'Uploaded Documents',
                to: 'uploaded-documents',
            },
            {
                title: 'Support Tickets',
                to: 'support-tickets',
            },
            // {
            //     title: 'Change Password',
            //     to: 'change-password',
            // },
        ]
    },
    {
        title: 'Trading Room',
        iconStyle: <AiOutlineDashboard size={20} />,
        to: 'trading-room',
    },
    {
        title: 'Order Book',
        iconStyle: <IoBookSharp size={20} />,
        to: 'my-oders-book',
    },
    {
        title: 'Watchlist',
        iconStyle: <PiProjectorScreenChart size={20} />,
        to: 'my-watchlist',
    },
    {
        title: 'Alerts & Reports',
        iconStyle: <IoIosNotificationsOutline size={20} />,
        to: 'alert-report',
    },
    {
        title: 'Financial History',
        iconStyle: <MdOutlineHistory size={20} />,
        to: 'transactions',
    },
    {
        title: 'Wallets',
        iconStyle: <MdOutlineAccountBalanceWallet size={20} />,
        to: 'my-wallets',
    },
    {
        title: 'Swap Transferts',
        iconStyle: <AiOutlineSwap size={20} />,
        to: 'swap-transfert',
    },
    // {
    //     title: 'Portofolio',
    //     iconStyle: <AiOutlineSwap size={20} />,
    //     to: 'portofolio',                
    // },
    {
        title: 'Video Academy',
        iconStyle: <MdOndemandVideo size={20} />,
        to: 'video-academy',                
    },
]