import { Fragment } from "react";
import PageTitle from "../../../layouts/PageTitle";



const ChangePassword = () => {
    return (
        <Fragment>
            <PageTitle activeMenu="Change Password" pageContent="Change Password" motherMenu="My Account" />
        </Fragment>
    );
}

export default ChangePassword;