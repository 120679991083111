export const LOADING_TRANSACTIONS_REQUEST = 'LOADING_TRANSACTIONS_REQUEST';
export const LOADING_TRANSACTIONS_SUCCESS = 'LOADING_TRANSACTIONS_SUCCESS';
export const LOADING_TRANSACTIONS_FAILED = 'LOADING_TRANSACTIONS_FAILED';

export const CREATE_TRANSACTION_REQUEST = 'CREATE_TRANSACTION_REQUEST';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILED = 'CREATE_TRANSACTION_FAILED';

export const EDIT_TRANSACTION_REQUEST = 'EDIT_TRANSACTION_REQUEST';
export const EDIT_TRANSACTION_SUCCESS = 'EDIT_TRANSACTION_SUCCESS';
export const EDIT_TRANSACTION_FAILED = 'EDIT_TRANSACTION_FAILED';

export const DELETE_TRANSACTION_REQUEST = 'DELETE_TRANSACTION_REQUEST';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILED = 'DELETE_TRANSACTION_FAILED';

export const LOAD_TRANSACTION_REQUEST = 'LOAD_TRANSACTION_REQUEST';
export const LOAD_TRANSACTION_SUCCESS = 'LOAD_TRANSACTION_SUCCESS';
export const LOAD_TRANSACTION_FAILED = 'LOAD_TRANSACTION_FAILED';


export function loadingTransactionsRequest(data) {
    return {
        type: LOADING_TRANSACTIONS_REQUEST,
    };
}

export function loadingTransactionsSuccess(data, isAdmin) {
    return {
        type: LOADING_TRANSACTIONS_SUCCESS,
        payload: {data, isAdmin},
    };
}

export function loadingTransactionsFailed() {
    return {
        type: LOADING_TRANSACTIONS_FAILED,
    };
}



// Create transaction action

export function createTransactionRequest(data) {
    return {
        type: CREATE_TRANSACTION_REQUEST,
    };
}

export function createTransactionSuccess(data) {
    return {
        type: CREATE_TRANSACTION_SUCCESS,
        payload: data,
    };
}

export function createTransactionFailed() {
    return {
        type: CREATE_TRANSACTION_FAILED,
    };
}


// Edit transaction actions

export function editTransactionRequest() {
    return {
        type: EDIT_TRANSACTION_REQUEST,
    };
}

export function editTransactionSuccess(id, data) {
    return {
        type: EDIT_TRANSACTION_SUCCESS,
        payload: {id, data},
    };
}

export function editTransactionFailed() {
    return {
        type: EDIT_TRANSACTION_FAILED,
    };
}

// Delete Transaction actions 

export function deleteTransactionRequest() {
    return {
        type: DELETE_TRANSACTION_REQUEST,
    };
}

export function deleteTransactionSuccess(id) {
    return {
        type: DELETE_TRANSACTION_SUCCESS,
        payload: {"id": id},
    };
}

export function deleteTransactionFailed() {
    return {
        type: DELETE_TRANSACTION_FAILED,
    };
}
// Load Transaction actions 

export function loadTransactionRequest() {
    return {
        type: LOAD_TRANSACTION_REQUEST,
    };
}

export function loadTransactionSuccess(transaction) {
    return {
        type: LOAD_TRANSACTION_SUCCESS,
        payload: transaction,
    };
}

export function loadTransactionFailed() {
    return {
        type: LOAD_TRANSACTION_FAILED,
    };
}
