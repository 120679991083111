

export default {
  translation: {
     "shared.french": "Francês",
     "shared.english": "Inglês",
     "shared.spanish": "Spanish",
     "shared.italian": "Italian",
     "shared.norvegian": "Norvegian",
     "shared.portugues": "Portugues",
     "shared.sweden": "Sweden",
     "shared.deutsch": "Deutsch",
     home: "Casa",
     home_welcome: "Bem-vindo",
     home_you_can: "Agora você pode usar uma conta real para negociar Forex, CFDs, Commodities, Stocks, Índices em várias plataformas (Web e Mobile). Obrigado por nos escolher",
     menu_contact_us: "Fale Conosco",
     menu_my_account:"Minha conta",
     menu_logout: "Sair",
     menu_personal_details: "Detalhes Pessoais",
     menu_change_password: "Alterar Senha",
     menu_trading_history: "Histórico de Negociação",
     menu_upload_document: "Enviar Documentos",
     menu_monetary_transaction: "Transações Monetárias",
     menu_wallet: "Carteiras",
     menu_withdrawal: "Retirada",
     pd_first_name: "Nome",
     pd_last_name: "Sobrenome",
     pd_email: "E-mail",
     pd_phone_number: "Número de telefone",
     pd_country: "País",
     pd_edit: "Editar",
     pd_city: "Cidade",
     pd_post_code: "Código postal",
     form_save: "Salvar",
     edit_account: "Editar conta",
     field_mandatory: "Todos os campos marcados como *são obrigatórios",
     change_password: "Alterar Senha",
     old_password: "Senha antiga",
     pw_weak: "Fraco",
     pw_medium: "Médio",
     pw_strong: "Forte",
     pw_confirm_pass: "Confirmar senha",
     pw_set_pass: "Confirmar Senha",
     form_submit: "Enviar",
     pw_new_password: "Nova senha",
     table_display: "Exibir",
     display_record: "registros",
     table_empty: "Nenhum dado disponível na tabela",
     th_symbol: "Símbolo",
     th_quantity: "Quantidade",
     th_open_date: "Data de Abertura",
     th_close_date: "Data de Fechamento",
     th_open_price: "Preço Aberto",
     th_close_price: "Fechar preço",
     th_position_type: "Comprar/Vender",
     th_comments: "Comentários",
     th_profit_lost:"Lucro / perda",
     upload_documents: "Carregar documentos",
     ud_upload_type: "Tipo de upload",
     ud_upload_date: "Data de upload",
     select_choose_option: "Escolha uma opção",
     ft_bank_file: "Arquivo",
     ft_bank_wire: "Transferência Bancária",
     ft_credit_card_front: "Frente do cartão de crédito",
     ft_driving_license: "Carta de Condução",
     ft_national_id_card: "Cartão de Identificação Nacional",
     ft_passport_id: "ID do passaporte",
     ft_utility_bill: "Conta de luz",
     ft_dod: "DOD",
     ft_por: "POR",
     ft_id: "ID",
     ft_wire_details: "Detalhes do fio",
     ft_wd_request_form: "Formulário de Solicitação WD",
     ft_credit_card_back: "Verso do cartão de crédito",
     ft_other_doc_type: "Outro tipo de documento",
     file_uploaded: "Arquivos enviados",
     wallet_details: "Detalhes da Carteira",
     w_currency:"Moeda",
     w_amount:"Quantidade",
     w_status:"Estado",
     w_status_valid:"VALID",
     w_status_invalid:"INVALIDO",
     w_currency:"Endereço de depósito",
     w_transactions_history:"Histórico de transações",
     w_currency:"Moeda",
     wd_reference:"Referência",
     wd_transaction_type:"Tipo de transação",
     wd_amount:"Quantidade",
     wd_wallet:"Carteira",
     wd_status:"Estado",
     wd_operation_date:"Data da operação",
     wd_note: "Nota",
     wallet_list: "Lista de carteiras",
     new_wallet: "Nova carteira",
     create_wallet_description:"Informe sua carteira para criar.",
     choose_currency: "Escolha a moeda",
     removal_page_description: `Ao clicar em "Solicitar uma retirada" seremos informados de que
     você gostaria de retirar fundos. O dinheiro será devolvido a
     seu cartão de crédito ou débito de financiamento.`,
     wt_choose_wallet: "Escolha uma carteira",
     wt_amount: "Quantidade",
     wt_beneficiary: "Beneficiário",
     wt_bank_name: "Nome do banco",
     wt_bank_address: "Endereço do banco",
     wt_bank_account_number: "Número da conta bancária",
     wt_swift_code: "Código Swift",
     wt_wallet_address: "Endereço da carteira",
     wt_relation_between: "Relação entre você e o receptor",
     wt_confirm: "Reconhecendo que as transações de carteira criptográfica são arriscadas, eu aprovo esta transação validando os dados fornecidos.",
     wt_request_a_withdrawal: "Solicite uma retirada",
     l_loading: "Carregando",
     login_to_start: "Faça login para começar a negociar agora mesmo",
     login_to_start_description: `Você pode se inscrever agora mesmo para começar a praticar a negociação em um
     Conta de demonstração GRATUITA. Uma vez que você tenha confiança suficiente,
     você pode começar a negociar de verdade. Teste a plataforma, encontre
     sua abordagem e comece.`,
     dont_have_account:"Não tem uma conta",
     register_here: "Registre-se aqui",
     login_password :"Senha",
     login_email :"E-mail",
     login_button :"LOGIN",
     live_registration: "Registro ao vivo",
     personal_informations: "Informações pessoais",
     login_information: "Informações de login",
     login_i_have_read:"Li, compreendi e aceito o",
     term_and_conditions: "Termos e Condições",
     register_now: "CADASTRE-SE AGORA",
     login_now: "Entrar agora",
     already_have_ccount: "Já tem uma conta",
     edit_profile_language: "Idioma",
     edit_profile_gender: "Gênero",
     edit_profile_gender_male: "Masculino",
     edit_profile_gender_female: "Feminino",
     edit_profile_birth_day: "Data de nascimento",
     edit_profile_address: "Endereço",
     te_pw_failed_message: "Falha na alteração da senha, verifique sua entrada e tente novamente.",
     closed_order: "Posições fechadas",
     open_order: "Posições abertas",
     menu_video_academy: "Academia de vídeo",
     w_deposit_address: "Endereço de depósito na carteira",
     button_deposit_text: "Depositar",
     button_deposit_description: "Informações de depósito.",
     qr_code: "Código QR",
     wallet_details_overview: "Visão geral",
     wt_wallet_address_type: "Tipo de carteira",
     wallet_details_details: "Detalhes",
     "no_email_validation": "Sem chave de confirmação de e-mail, tente novamente mais tarde.",
     email_address_validated: 'Seu endereço de e-mail foi verificado com sucesso, você pode fazer o login agora',
     email_address_validation_error: 'Opa, ocorreu um erro ao verificar seu endereço de e-mail. Por favor, tente atualizar a página ou tente mais tarde.',
     email_verification_pending: "Verificando seu endereço de e-mail...",
     w_deposit_iban: "IBAN",
     deposit_infos: "Informações de depósito",
     new_wallet_generate_address: "Gerar endereço.",
     new_wallet_success: "Carteira criada com sucesso",
     new_wallet_error: "Erro ao criar carteira",
     menu_swap_transfert: "Trocar transferência",
     transfert_money: "Transferir dinheiro",
     transfert_money_between_wallets: "Transferir dinheiro entre carteiras",
     wallet_to_withdraw: "Carteira para débito",
     wallet_to_send_to: "Carteira para crédito",
     transfert_amount: "Valor da transferência",
     swap_transfert_success: "Fundos transferidos com sucesso",
     swap_transfert_error: "Ocorreu um erro durante a transferência de fundos, tente mais tarde.",
     swap_transfert_cannot_process: "Não foi possível processar a transferência, por favor preencha todas as informações necessárias e tente novamente.",
     transfert_amount: "Valor",
     transfert_execute: "Executar",
     return_to_courses: "Retornar aos cursos",
     crypto_trading: "Negociação de criptomoedas",
     crypto_trading_description: "Neste curso, você aprenderá todos os fundamentos para começar a negociar no mundo das criptomoedas.",
     introduction_and_history: "Introdução e história",
     blockchain_and_descentralisation: "Blockchain e descentralização",
     major_cryptocurencies: "Principais criptomoedas",
     other_crypto_and_stable_coin: "Outras criptomoedas e stablecoins",
     nft: "NFT",
     market_actors: "Atores do mercado",
     key_factors: "Principais impulsionadores do mercado de criptomoedas",
     trading_tools: "Ferramentas de negociação de criptografia",
     psychologie_of_trading_and_risk_management: "Psicologia de negociação de criptografia e gerenciamento de risco",
     trade_cryptos: "Como negociar criptomoedas",
     conclusion: "Conclusão",
     market_analysis: "Análise de mercado",
     market_analysis_description: "Este curso de Análise de Mercado destina-se àqueles que desejam desenvolver sua vantagem comercial e aprimorar suas habilidades de análise fundamental e técnica para tomar melhores decisões comerciais.",
     introduction: "Introdução",
     economic_analyse: "Análise Econômica",
     informations_and_trading_events: "Notícias e eventos de negociação",
     trading_events: "Eventos de Negociação",
     key_economic_data: "Dados econômicos importantes",
     technic_analyse: "Análise Técnica",
     support_and_resistance: "Suporte e Resistência",
     trends: "Tendências",
     hauss_trends: "Tendências ascendentes",
     bass_trends: "Tendências Descendentes",
     course_resume: "Resumo do Curso",
     trading_psychology: "Psicologia do Trading",
     trading_psychology_description: "Neste curso inovador, você aprenderá como superar o maior inimigo do trading: as emoções! Adquira a mentalidade de um trader de sucesso, ganhe confiança em seu trading.",
     introdução: "Introdução",
     fear_of_lost: "Medo de perder",
     surmont_the_fear_of_lost: "Superando o medo da perda",
     gourmandise: "Ganância",
     surmont_gourmandise: "Superando a Ganância",
     position_management: "Gerenciamento de posição",
     solutions_: "Soluções",
     risk_management: "Gerenciamento de Riscos",
     finance_management_solutions: "Soluções de gerenciamento de dinheiro",
     course_resume: "Resumo do Curso",
     forex_strategy: "Estratégias de Negociação",
     forex_strategy_description: "Este curso revelará uma ampla gama de estratégias de negociação forex sob demanda para todos os tipos de traders",
     day_trading: "Dia de Negociação",
     news_trading: "Negociação de notícias",
     carry_trading: "Carry Trading",
     binary_trends_trading: "Negociação de Tendências",
     binary_swing_trading: "Swing Trading",
     chart_level_trading_of_binaries_option: "Negociação em nível de gráfico",
     chartists_classics_figures: "Negociação de padrões gráficos clássicos",
     technical_indicators_trading: "Indicador Técnico de Negociação",
     course_resume: "Resumo",
     trading_sociology: "Negociação social",
     trading_sociology_description: "A negociação social é um tipo de negociação onde os investidores podem observar as estratégias de negociação de outros comerciantes. Torne-se um copiador e replique as negociações do provedor, onde um provedor é um comerciante experiente que compartilha suas estratégias de investimento.",
     what_interest: "Por que fazer negociação social?",
     how_to_process: "Escolha um provedor",
     advantages_and_inconvenients: "Vantagens e desvantagens",
     course_resume: "Resumo",
     technic_indicators: "Indicadores Técnicos",
     technic_indicators_description: "Os métodos mais comuns para analisar os indicadores dos mercados financeiros, gráficos de velas e muito mais.",
     graphiques_en_chandeliers: "Gráficos de velas",
     figure_des_chandeliers_part_1: "Padrões de Candlestick Parte 1",
     figure_des_chandeliers_part_2: "Padrões de castiçal Parte 2",
     support_et_resistances: "Suporte e Resistência",
     moyenne_mobile: "Média móvel",
     bandes_de_bollinger: "Bandas de Bollinger",
     retracements_de_fibonacci: "Retrações de Fibonacci",
     indice_de_force_relative_ou_rsi: "Índice de Força Relativa",
     diversification_and_timing: "diversificação e timing",
     video_academy: "Academia de Vídeo",
     video_academy_description: "Aqui você encontra cursos com videoaulas.",
     video_academy_courses: "Cursos",
     active_orders: "Pedidos ativos",
    closed_orders: "Pedidos Fechados",
    wt_balance: "Saldo",
    w_volume: "Volume",
    form_choose_file: "Escolher arquivo",
    ft_bank_statement: "Extrato bancário",
     ft_other: "Outro",
     agree_im_major_and_not_living_in_usa: "Confirmo que sou maior de idade e que não sou cidadão dos EUA.",
     accept_general_risk: `e eu concordo com <a href="https://StockBridge Capitaltrade.com/legalcontent/General_risk_disclosure.pdf" target="_blank"class="text-primary">divulgação de risco geral</a>` ,
     btn_continue: "Continuar",
     deposit_amount_error: "O valor não pode ser superior a 5000, entre em contato com um gerente de conta para um depósito superior a 5000",
     selecte_a_payment_method: "Selecione um método de pagamento",
     btn_previous: "Anterior",
     send_the_exact_amount_of: "Para fazer seu depósito, por favor envie o valor exato de",
     to_this_wallet: "Para o seguinte endereço",
     or_scan_the_qrcode: "Ou escaneie o código QR",
     timeleft_before_deposit: "Tempo restante antes do depósito: ",
     btn_return_on_app: "Retornar no aplicativo.",
     deposit_important: "Importante",
     deposit_select_coin: "selecionar moeda",
     deposit_deposit_to: "Depositar para",
     deposit_send_only: `Enviar apenas {{currency}} para
     este endereço de depósito. Enviando qualquer outra moeda
     para este endereço pode resultar na perda do seu
     depósito.`,
     deposit_ensure_network_is: "Certifique-se de que a rede esteja",
     deposit_deposit_address: "Endereço de Depósito",
     deposit_show_qr: "Mostrar código QR",
     deposit_copy_qr: "Copiar Endereço",
     deposit_tips: "Dicas",
     deposit_tips_1: "Aviso: as moedas serão depositadas imediatamente após a confirmação da 2 Rede",
     deposit_track_progress: "Depois de fazer um depósito, você pode acompanhar seu progresso no",
     deposit_history_page: "página de histórico.",
     deposit_enter_card_details: "Por favor, insira os detalhes do seu cartão para pagar.",
     btn_copy_text: "Copiar",
     payment_view_trans_id: "ID da transação",
     you_also_have_create: "Você também tem que abrir ",
     a_ticket: "um bilhete.",
     menu_tickets: "Tickets de suporte",
     new_tickets: "Novo Bilhete",
     ticket_subject: "Assunto",
     ticket_status: "Estado",
     ticket_last_updated: "Última atualização",
     ticket_details: "Detalhes do ingresso",
     ticket_view: "Visualização do ticket",
     posted_on: "em",
     post_by: "Postado por",
     ticket_replies: "Respostas",
     ticket_reply: "Responder",
     notification_title: "Notificação",
     notification_empty: "Você não tem notificações",
     usr_request_call: "Pedido de chamada",
     usr_request_call_severity: "Gravidade",
     usr_request_call_date: "Data de chamada desejada",
     usr_request_call_time: "Tempo de chamada desejado",
     usr_request_call_subject: "Assunto da chamada",
     usr_request_call_severity_normal: "Normal",
     usr_request_call_severity_urgent: "Urgente",
     usr_request_call_severity_very_u: "Muito urgente",
     usr_request_call_action_btn: "Solicite uma chamada",
     usr_request_call_request_sended:"A solicitação de chamada foi recebida, ligaremos para você no horário mencionado.",
     password_reset_desc: "Por favor, digite seu endereço de e-mail para redefinir a senha",
     password_reset_action_btn: "Redefinir a senha",
     password_recovery_action_btn: "Recuperar a senha",
     password_reset_reset: "Digite e confirme sua nova senha",
     deposit_card_holder: "Nome do titular do cartão",
     deposit_hide_qr: "Ocultar código QR",
     deposit_send_within: "Envie apenas {{currency}} nesta carteira dentro de {{timeout}}",
     TR_PENDING: "Pendente",
     TR_CANCELED: "Rejeitado",
     TR_VALIDATED: "Validado",
     TR_AWAITING_FOR_APPROVAL: "Aguardando aprovação",
     TR_ON_HOLD: "Em espera",
     menu_upload_document_to_verify_identity: "Carica i tuoi documenti per verificare la tua identità.",
     ft_tin: "TIN",
     menu_upload_document_to_verify_identity_success: "Abbiamo ricevuto i tuoi documenti, li controlleremo e attiveremo il tuo account se sono verificati.",
     upload_failed_file_too_large: "Spiacenti, la dimensione del file che hai caricato è superiore a 2 Mb, e non è consentito.",
     text_copied_success: "Testo copiato!",
     ft_national_id_card_front: "carta d'identità anteriore",
     ft_national_id_card_back: "Retro carta d'identità",
     ft_upload_national_id_card_back: "Ricarica carta d'identità",
     ft_upload_national_id_card_front: "Carica la parte anteriore della carta d'identità",
     account_status: "Verifica dell'account",
     account_status_pending: "La verifica delle tue informazioni è in corso.",
     account_status_pending_text: "Il nostro team di verifica ha ricevuto i tuoi documenti e la loro verifica è in corso. Riceverai un'email una volta approvati i tuoi documenti, quindi potrai accedere e iniziare a fare i tuoi investimenti.",
     account_status_pending_signature: "Cordiali saluti, il team di verifica dei mercati dei tori neri",
     account_status_pending_nb: "NB: a causa dell'elevato numero di richieste di verifica inviate, la convalida dei tuoi documenti può richiedere da 30 minuti a 24 ore. Grazie per la pazienza e a presto per il tuo primo investimento.",
     withdrawal_page_description: `Facendo clic su "Richiedi un prelievo" verremo informati
     desideri prelevare fondi. Il denaro sarà rimborsato a
     la tua carta di credito o di debito per il finanziamento.`,
     dashboard_login_wtrader: "Accedi a Web Trader",
     transaction_create_success: "Transazione creata con successo!",
     transaction_create_error_message: "Errore nella creazione della transazione!! Riprova più tardi",
     Transaction_loading_error_message: "Errore di transazione durante il caricamento!! Riprova più tardi",
     files_loading_error_message: "Caricamento file non riuscito!",
     files_upload_success_message: "File caricato con successo",
     files_upload_error_message: "Caricamento file non riuscito. Per favore, riprova",
     wallets_creation_error_message: "Portafoglio creato con successo!",
     wallets_creation_success_message: "Creazione portafoglio fallita!",
     wallets_loading_error_message: "Errore di caricamento!",
     wallets_loading_success_message: "Creazione portafoglio fallita!",
     text_no_eth_wallet_for_swap: "Lamentamos, mas você não tem uma carteira Ethereum para pagar taxas de rede por esta transação de swap.",
      text_cannot_generate_eth_wallet: "Você não pode gerar uma carteira ETH, atualize seu tipo de conta e tente novamente.",
      to_bbm: "para StockBridge Capital TRADE",
     account_status_2: "Estatuto da conta",
     account_verified: "Verificado",
     account_not_verified: "Não verificado",
     date_registered: "Data de registro",
     txt_wm: "Métodos de saque",
     txt_cw:"Continuar retirada",
     txt_tr:"SALA DE COMÉRCIO",
     txt_ob:"LIVRO DE PEDIDOS",
     txt_wl:"Lista de observação",
     txt_ar:"Alertas e Relatórios",
     txt_fh:"Histórico Financeiro",
     txt_dashboard:"Painel",
     txt_tpv:"Valor total do portfólio",
     txt_trader_room:"Sala do Trader",
     txt_ar2:"Relatório da conta",
     txt_ab:"Saldo Disponível",
     txt_e_pnl:"PNL estimado",
     txt_td:"Depósito total",
     txt_taw:"Total de retiradas aprovadas",
     txt_p_bal:"Saldo do Plano",
     txt_d:"Data",
     txt_ad:"Detalhes da minha conta",
     txt_ud:"Documentos enviados",
     txt_mt:"Meus ingressos",
     txt_up:"Atualizar senha",
     txt_w:"Retiradas",
     txt_s_n:"S/N",
     txt_pm:"Forma de pagamento",
     txt_at:"Todas as transações",
     txt_w_l:"Lista de desejos",
     txt_sa:"Pesquisar ativos",
     txt_ass:"Ativos",
     txt_sell:"VENDER",
     txt_buy:"COMPRAR",
     txt_spread:"Espalhar",
     txt_actions:"Ações",
     TXT_:"",
     menu_withdraw: "Retirar",
     txt_close: "Fechar",
txt_please_wait: "Por favor, espere...",
txt_position_closed: "Posição fechada com sucesso",
txt_position_closed_error: "E ocorreu um erro ao fechar sua posição, tente entrar em contato com seu gerente de conta.",
txt_login_welcome: "Bem-vindo ao <strong>StockBridge Capital</strong>",
    txt_sign_in: "Entrar",
    txt_sign_up: "Inscreva-se",
    txt_to_access: "para acessar sua conta",
    txt_to_open: "abra sua conta",
    txt_forgot_password: "Esqueceu a senha?",
    txt_not_client: "Ainda não é cliente StockBridge Capital <br />?",
    txt_open_account: "Abra uma conta",
    txt_main_page: "Página principal do StockBridge Capital",
    txt_contact_us_at: "Entre em contato conosco em",
    txt_if_have_pb: "Se você tiver problemas",
    txt_footer: "Negociar CFDs envolve risco significativo de perda. Leia StockBridge Capital's",
    txt_risk: "‘Declaração de divulgação de riscos’.",
    txt_not_membber: 'Não é membro?</span> <b className="ng-star-inserted">Inscreva-se agora!',
    txt_already_member: "<span>Já é membro?</span> <b>Faça login agora!</b>",
   },
};