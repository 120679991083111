import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import WatchListWidget from "./WatchList";



export default function WatchList() {

    return (

        <Fragment>
            <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
                <h2 className="font-w600 mb-0 me-auto mb-2 text-black">Watchlist</h2>
            </div>
            <div className="card">
                <div style={{ minHeight: '70vh' }} className="w-100">
                    <WatchListWidget />
                </div>
            </div>
        </Fragment>
    )
}

