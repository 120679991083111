// swrConfig.js
import useSWR, { SWRConfig } from 'swr';
import { API } from './axios';

// Create a custom fetcher that uses the Axios instance
const fetcher = (url) => API.get(url).then(res => res.data);

// Create a global SWR config with your desired options
const swrConfig = {
  fetcher,
  revalidateOnFocus: false,  // Disable revalidation when the window is focused
  dedupingInterval: 2000,     // Prevent multiple requests for the same data within 2 seconds
  errorRetryInterval: 5000,   // Retry errors every 5 seconds
  errorRetryCount: 3,         // Retry up to 3 times in case of an error
  refreshInterval: 60000,     // Refresh data every 60 seconds
};

export default swrConfig;
