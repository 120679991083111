import { Link } from "react-router-dom";
import { ArrowDown, ArrowUp, CoinIcon4 } from "../../../../components/zenix/Transactions/TableData";
import moment from "moment";
import { formatCurrency } from "../../../../components/utils/functions";


const TransactionRow = ({ transaction }) => {

    const isDeposit = transaction?.type === "CREDIT";

    return (
        <tr role="row" className="odd">
            <td className="pr-0 sorting_1">
                {
                    isDeposit ? <ArrowUp /> : <ArrowDown />
                }
            </td>
            <td>#{transaction?.reference}</td>
            {transaction?.type !== "WITHDRsAW" ? <td>
                {transaction?.wallet_address ? `Crypto (${transaction?.wallet_address})` : transaction?.transaction_notes === "Invested" ? "Invested" : "Bank transfert"}
            </td>
                :
                <td></td>
            }
            <td className="q-td text-left">
                <div className="text-subtitle1">
                    <div
                        className={`q-badge flex inline items-center no-wrap q-badge--single-line text-${transaction?.status === "VALIDATED" ? 'success' : 'danger'}`}
                        role="alert"
                        aria-label={transaction?.status}
                    >
                        {transaction?.status}
                    </div>
                </div>
            </td>
            <td><span className={`font-w600  ${isDeposit ? 'crypt-up' : 'crypt-down'}`}>{formatCurrency(transaction?.wallet?.currency ?? "EUR")}{Number(transaction?.amount).toFixed(2)}</span></td>
            <td><p className="mb-0 wspace-no"> 
                
            {transaction?.transaction_notes}
            </p></td>
            <td>{moment(transaction?.date).format('MM/DD/YYYY HH:mm')}</td>
        </tr>
    )

}

export default TransactionRow;