

export const URLS = {
    BASE_URL: "https://api.letustradetogether.com/",
    BASE_URL1: "https://api.letustradetogether.com",
    
    CLIENT_ID: "1I8AycdsKkJiieicog43pudIkVPA6gZRWR736SRM",

    remoteLink: (symbol, exchange) => `trade/symbol/${symbol}/exchange/${exchange}`,

    AUTHS: {
        LOGIN: "auth/login/",
        REFRESH: "auth/token/refresh/",
        LOGOUT: 'auth/logout/',
        REGISTRATION: 'accounts/registration/',
        registratioWithReferral: (ref) => `accounts/registration/${ref}/`,
        VERIFY_EMAIL: '/accounts/email/verification/',
        CHANGE_PASSWORD: '/accounts/password/change/',
        RESET_PASSWORD: '/accounts/password/reset/',
        RESET_PASSWORD_CONFIRM: '/accounts/password/reset/confirm/',
    },
    BANK: {
        CREATE: "",
    },
    USERS: {
        LIST: (search) => `users/?search=${search}`,
        UPDATE: (id) => `users/update-info/`,
        CREDIT_CARD: `users/cc/`,
        ME: `users/me/`,
        NORMAL: (search) => `users/normal-users/?search=${search}`,
        MANAGER: (search) => `users/manager-users/?search=${search}`,
        MY_WALLETS: (walletId) => `users/wallet/?wallet=${walletId}`,
        MY_WALLET_TRANSACTIONS: (walletId) => `users/transactions/?wallet=${walletId}`,
        MY_TRANSACTIONS: 'users/transactions/',
        TRASACTIONS: {
            my: `type`
        },
        MY_POSITIONS: 'users/positions/',
        FILES: {
            CREATE: 'users/files/',
            LIST: 'users/files/',
            MY: 'users/files/me/',
        },
        GROUP: {
            ADD_USER: "/users/add-to-group/"
        },
        BANK_DETAILS: {
            CREATE: "users/bank_account/",
            ME: "users/bank_account/me/",
        }
    },
    WALLETS: {
        LIST: (search) => `wallets/wallets/?search=${search}`,
        INIT: 'wallets/init/',
        CREATE: 'wallets/wallets/',
        EDIT: (id) => `wallets/wallets/${id}/`,
        ME: `users/wallets/`,
        DELETE: (id) => `wallets/wallets/${id}/`,
        PICK: (currency) => `wallets/pick/${currency}/`,
        GET: (id) => `wallets/wallets/${id}/`,
        RECREATE: (id) => `wallets/wallets/${id}/create_wallet/`,
        SWAP_TRANSFERT: 'swap-transfert/',
        CONVERT: 'conversion/rate/',
        GLOBALS: 'wallets/globals/?is_active=true',
    },
    POSITIONS: {
        LIST: 'positions/',
        CREATE: 'positions/',
        USER_CREATE: 'users/open_position/',
        EDIT: (id) => `positions/${id}/`,
        DELETE: (id) => `positions/${id}/`,
        CLOSE: (id) => `positions/${id}/close/`,
        userClose: (id) => `positions/${id}/user_close/`,
        GET: (id) => `positions/${id}/`,
    },
    TRANSACTIONS: {
        LIST: 'transactions/',
        INIT: 'transactions/init/',
        MY: 'transactions/my/',
        CREATE: 'transactions/',
        EDIT: (id) => `transactions/${id}/`,
        WALLET: (wallet) => `transactions/wallet/${wallet}/?page_size=10000`,
        DELETE: (id) => `transactions/${id}/`,
        GET: (id) => `transactions/${id}/`,
    },
    PROSPECTS: {
        LIST: 'prospect/',
        CREATE: 'prospect/',
        EDIT: (id) => `prospect/${id}/`,
        DELETE: (id) => `prospect/${id}/`,
        GET: (id) => `prospect/${id}/`,
    },
    MESSAGES: {
        LIST: 'message/messages/',
        ROOM_MESSAGES: (roomId) => `message/${roomId}/messages/`,
        CONVERSATION: (user, manager) => `message/?user=${user}&manager=${manager}`,
        CREATE: 'message/messages/',
        EDIT: (id) => `message/${id}/`,
        DELETE: (id) => `message/${id}/`,
        GET: (id) => `message/${id}/`,
        ROOMS: {
            MY: 'message/my-rooms/',
            LIST: 'message/',
            CONVERSATION: (user, manager) => `message/?user=${user}&manager=${manager}`,
            CREATE: 'message/rooms/',
            EDIT: (id) => `message/${id}/`,
            DELETE: (id) => `message/${id}/`,
            GET: (id) => `message/${id}/`,
        },
    },
    UTILS: {
        COUNTRIES: {
            LIST: () => `countries/api/countries/?page_size=300`
        }
    },
    TICKETS: {
        CREATE: 'tickets/create/',
        MY: 'tickets/my/',
        MY_DETAILS: (id) => `/tickets/${id}/my/details`,
        EDIT: (id) => `prospect/${id}/`,
        DELETE: (id) => `prospect/${id}/`,
        GET: (id) => `prospect/${id}/`,
    },
    NOTIFICATIONS: {
        MY: (user) => `users/notifications/`,
        READ: (notif) => `notifications/${notif}/read`,
    },
    CALL_REQUEST: {
        CREATE: `call-request/c-r/`,
    },
    TV: {
        symbols: (symbol, market) => `trade/symbols/all/${symbol}/${market}/`,
        allSymbols: "trade/symbols",
        assets: "https://tradingarea.live/api/v1/assets",
    },
    EXPORT: {
        transactions: (startDate, endDate) => `exports/transactions/?start_date=${startDate}&end_date=${endDate}`,
        pnl: (startDate, endDate) => `exports/pnl/?start_date=${startDate}&end_date=${endDate}`,
    }
}