import { Fragment } from "react";
import { Link } from "react-router-dom";
import {Nav, Tab} from 'react-bootstrap';
import ActiveOrders from "./components/ActiveOrders";
import ClosedOrders from "./components/ClosedOrders";
import AccountBalance from "./components/Balance";
import useSWR from "swr";
import { URLS } from "../../../../services/urls/urls";



const OrderBook = () => {

    

    const {data: positionsList, isLoading} = useSWR(`${URLS.USERS.MY_POSITIONS}?page_size=100000`);
    const {data: walletList, isLoading: isLoadingWallets} = useSWR(`${URLS.USERS.MY_WALLETS("")}`);

    return (
        
		<Fragment>
            <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
                <h2 className="font-w600 mb-0 me-auto mb-2 text-black">OrderBook</h2>
                <Link to={"#"} className="btn btn-secondary mb-2">Export Report</Link>
            </div>
            <div className="card">
				<Tab.Container defaultActiveKey="ActiveOrders">
					<div className="card-header pb-2 d-block d-sm-flex flex-wrap border-0">
						<div className="mb-3">
							<h4 className="fs-20 text-black">Recent Trading Activities</h4>
							{/* <p className="mb-0 fs-12">Lorem ipsum dolor sit amet, consectetur</p> */}
						</div>
						<div className="card-action card-tabs mb-3 style-1">
							<Nav as="ul" className="nav-tabs" role="tablist">
								<Nav.Item as="li" className="nav-item"><Nav.Link to={"#"} eventKey="ActiveOrders" >Active Orders</Nav.Link></Nav.Item>
								<Nav.Item as="li" className="nav-item"><Nav.Link to={"#"} eventKey="ClosedOrders">Closed Orders</Nav.Link></Nav.Item>
								<Nav.Item as="li" className="nav-item"><Nav.Link to={"#"} eventKey="Balance" >Balance</Nav.Link></Nav.Item>
							</Nav>
						</div>
					</div>
					<div className="card-body tab-content p-0">
						<div className="tab-pane active show fade" id="activeOrders">
							<Tab.Content>
								<ActiveOrders positionsList={positionsList} isLoading={isLoading} />
                                <ClosedOrders  positionsList={positionsList} isLoading={isLoading} />
                                <AccountBalance walletsList={walletList}  isLoading={isLoadingWallets} />
							</Tab.Content>
						</div>	
					</div>
				</Tab.Container>	
			</div>
        </Fragment>
    )

}


export default OrderBook;