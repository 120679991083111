

export const COURSES = [
    {
        id: 154,
        title: "crypto_trading",
        image: "/assets/images/courses/img/crypto-trading.png",
        lessons: [
            {
                id: 900,
                name: "introduction_and_history",
                time: "00:01:27"
            },
            {
                id: 904,
                name: "blockchain_and_descentralisation",
                time: "00:01:19"
            },
            {
                id: 906,
                name: "major_cryptocurencies",
                time: "00:01:27"
            },
            {
                id: 907,
                name: "other_crypto_and_stable_coin",
                time: "00:01:49"
            },
            {
                id: 908,
                name: "nft",
                time: "00:01:20"
            },
            {
                id: 909,
                name: "market_actors",
                time: "00:01:18"
            },
            {
                id: 910,
                name: "key_factors",
                time: "00:00:59"
            },
            {
                id: 911,
                name: "trading_tools",
                time: "00:00:59"
            },
            {
                id: 912,
                name: "diversification_and_timing",
                time: "00:01:21"
            },
            {
                id: 913,
                name: "psychologie_of_trading_and_risk_management",
                time: "00:00:55"
            },
            {
                id: 914,
                name: "trade_cryptos",
                time: "00:01:23"
            },
            {
                id: 915,
                name: "conclusion",
                time: "00:01:23"
            }
        ],
        totalTime: "21:39",
        description: "crypto_trading_description",
    },
    {
        id: 3,
        title: "market_analysis",
        image: "/assets/images/courses/img/market-analysis.png",
        lessons: [
            {
                id: 36,
                name: "introduction",
                time: "00:00:20"
            },
            {
                id: 37,
                name: "economic_analyse",
                time: "00:00:29"
            },
            {
                id: 38,
                name: "informations_and_trading_events",
                time: "00:00:44"
            },
            {
                id: 39,
                name: "trading_events",
                time: "00:00:59"
            },
            {
                id: 40,
                name: "key_economic_data",
                time: "00:00:53"
            },
            {
                id: 41,
                name: "technical_analyse",
                time: "00:00:53"
            },
            {
                id: 42,
                name: "support_and_resistance",
                time: "00:00:48"
            },
            {
                id: 43,
                name: "trends",
                time: "00:00:24"
            },
            {
                id: 44,
                name: "hauss_trends",
                time: "00:00:27"
            },
            {
                id: 45,
                name: "bass_trends",
                time: "00:00:26"
            },
            {
                id: 46,
                name: "course_resume",
                time: "00:00:17"
            },

        ],
        totalTime: "6:21",
        description: "market_analysis_description",
    },
    {
        id: 4,
        title: "trading_psychology",
        image: "/assets/images/courses/img/trading-psychology.png",
        lessons: [
            {
                id: 47,
                name: "intro",
                time: "00:00:18"
            },
            {
                id: 48,
                name: "fear_of_lost",
                time: "00:00:32"
            },
            {
                id: 49,
                name: "surmont_the_fear_of_lost",
                time: "00:00:18"
            },
            {
                id: 50,
                name: "gourmandise",
                time: "00:00:36"
            },
            {
                id: 51,
                name: "surmont_gourmandise",
                time: "00:00:45"
            },
            {
                id: 52,
                name: "positions_management",
                time: "00:00:23"
            },
            {
                id: 53,
                name: "solutions_",
                time: "00:00:47"
            },
            {
                id: 54,
                name: "risks_management",
                time: "00:00:48"
            },
            {
                id: 55,
                name: "financial_management_solutions",
                time: "00:00:50"
            },
            {
                id: 56,
                name: "course_resume",
                time: "00:00:15"
            },
        ],
        totalTime: "5:34",
        description: "trading_psychology_description",
    },
    {
        id: 5,
        title: "forex_strategy",
        image: "/assets/images/courses/img/trading-strategy.png",
        lessons: [
            {
                id: 57,
                name: "intro",
                time: "00:00:22"
            },
            {
                id: 58,
                name: "day_trading",
                time: "00:00:18"
            },
            {
                id: 59,
                name: "news_trading",
                time: "00:00:49"
            },
            {
                id: 60,
                name: "carry_trading",
                time: "00:00:46"
            },
            {
                id: 61,
                name: "binary_trends_trading",
                time: "00:00:58"
            },
            {
                id: 62,
                name: "binary_swing_trading",
                time: "00:00:48"
            },
            {
                id: 63,
                name: "chart_level_trading_of_binaries_option",
                time: "00:00:48"
            },
            {
                id: 64,
                name: "chartists_classics_figures",
                time: "00:00:59"
            },
            {
                id: 65,
                name: "technical_indicators_trading",
                time: "00:01:01"
            },
            {
                id: 66,
                name: "course_resume",
                time: "00:00:48"
            },
        ],
        totalTime: "8:04",
        description: "forex_strategy_description",
    },
    {
        id: 165,
        title: "trading_sociology",
        image: "/assets/images/courses/img/trading-sociology.png",
        lessons: [
            {
                id: 998,
                name: "intro",
                time: "00:01:06"
            },
            {
                id: 999,
                name: "what_interest",
                time: "00:01:00"
            },
            {
                id: 1000,
                name: "how_to_process",
                time: "00:01:02"
            },
            {
                id: 1001,
                name: "advantages_and_inconvenients",
                time: "00:01:18"
            },
            {
                id: 1002,
                name: "course_resume",
                time: "00:00:48"
            },

        ],
        totalTime: "7:54",
        description: "trading_sociology_description",
    },
    {
        id: 64,
        title: "technical_indicators",
        image: "/assets/images/courses/img/technical-indicators.png",
        lessons: [
            {
                id: 281,
                name: "graphiques_en_chandeliers",
                time: "00:01:08"
            },
            {
                id: 282,
                name: "figures_des_chandeliers_part_1",
                time: "00:01:31"
            },
            {
                id: 283,
                name: "figures_des_chandeliers_part_2",
                time: "00:01:07"
            },
            {
                id: 564,
                name: "supports_et_resistances",
                time: "00:01:06"
            },
            {
                id: 565,
                name: "moyenne_mobile",
                time: "00:01:16"
            },
            {
                id: 566,
                name: "bandes_de_bollinger",
                time: "00:00:51"
            },
            {
                id: 567,
                name: "retracements_de_fibonacci",
                time: "00:01:10"
            },
            {
                id: 568,
                name: "indice_de_force_relative_ou_rsi",
                time: "00:01:31"
            },
        ],
        totalTime: "14:05",
        description: "technical_indicators_description",
    }
]