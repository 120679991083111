import { toast } from "react-toastify";
import SecureLS from "secure-ls";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";
import {
  loadingUsersFailed,
  loadingUsersRequest,
  loadingUsersSuccess,
} from "../actions/usersActions";

var ls = new SecureLS({ encodingType: "base64" });

export function loadingUsers(search, userType) {
  return (dispatch) => {
    dispatch(loadingUsersRequest());
    API.get(
      userType === "NORMAL_USERS"
        ? URLS.USERS.NORMAL(search)
        : userType === "MANAGER"
        ? URLS.USERS.MANAGER(search)
        : URLS.USERS.LIST(search)
    )
      .then((response) => {
        dispatch(loadingUsersSuccess(response?.data, userType));
        // history.push('/dashboard');
      })
      .catch((error) => {
        toast.error(
          error?.response?.data.detail ?? "Login error !! Please try later"
        );
        // const errorMessage = formatError(error?.response?.data);
        dispatch(loadingUsersFailed());
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function editAccount(id, data, toggleLoader) {
  return (dispatch) => {
    API.patch(URLS.USERS.UPDATE(id), data)
      .then((response) => {
        let userDetails = ls.get("user-details");
        const { data } = response;
        const usr = ls.get("user-details");
        const updatedUser = { ...usr, user: {...usr.user, ...data} };

        ls.set("user-details", updatedUser);
        // history.push('/dashboard');
        toast.success("Account successfuly updated");
        toggleLoader(false);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data.detail ?? "Update error !! Please try later"
        );
        toggleLoader(false);
      });
  };
}

export function logout(history) {
  return (dispatch) => {
    const idToast = toast.loading("Loging out...");
    ls.removeAll();
    history.push("/login");
    window.location.reload();
    // API.get(URLS.AUTHS.LOGOUT, {headers: {"ClientId": URLS.CLIENT_ID}}).then((resp) => {
    //     toast.update(idToast, {render: "Successfully logged out..", type: "success", isLoading: false, autoClose: true});
    //     ls.removeAll();
    //     history.push('/');
    // }).catch((error) => {
    //     ls.removeAll();
    //     history.push('/');
    //     console.log("Error ===============>",  error)
    //     toast.update(idToast, {render: error?.response?.data.detail ?? "Error while logging ou !! Please try later", type: "error", isLoading: false, autoClose: true });
    // })
  };
}
