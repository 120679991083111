import { useState, useRef, Fragment } from "react";
import { Editor } from "@tinymce/tinymce-react";

import { Button, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API } from "../../../../../services/axios";
import { URLS } from "../../../../../services/urls/urls";


const CreateTickets = () => {

  
  const { user } = useSelector((state) => state.auth.auth);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [subject, setSubject] = useState("");
  const [file, setFile] = useState();

  const editorRef = useRef(null);
  const { t } = useTranslation();

  const handleChangeFile = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    const content = editorRef.current.getContent();
    if (content && subject) {
      setLoading(true);
      let formData = new FormData();
      if(file) {
        formData.append("joined_file", file);
      }
      formData.append("subject", subject);
      formData.append("content", content);
      formData.append("parent", "");
      formData.append("user", user?.id);
      formData.append("created_by", user?.id);
      API.post(URLS.TICKETS.CREATE, formData).then((resp) => {
        const { data } = resp;
        setLoading(false);
        toast.success("Ticket created!")
      }).catch((error) => {
        toast.error(error?.response?.data?.detail ?? "Creation error!");
        setLoading(false);
      });
    }
  };

  return (
    <Fragment title={t("menu_tickets")}>
      <div
        className="q-page-container my-account trade-room-layout">
          <div className="">
            <h3>New Support Ticket</h3>

            <div style={{ marginBottom: "50px" }}>
              <div>
                <Form.Group>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder={t("ticket_subject")}
                    value={subject}
                    onChange={(event) => setSubject(event.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="mt-4">
                <div className="mb-4">
                  <Editor
                    tinymceScriptSrc={
                      process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
                    }
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue="<p></p>"
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "preview",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
                <div>
                  <div className="border p-3">
                    <div className="d-flex">
                      <div>{file?.name}</div>
                      <div className="ml-auto">
                        <input
                          type="file"
                          id="file"
                          hidden
                          onChange={handleChangeFile}
                        />
                        <label htmlFor="file" className="btn btn-primary py-3">
                          {t("form_choose_file")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-3">
                  <div className="d-flex">
                    <div className="ml-auto">
                      <Button disabled={loading} className=" py-3 px-4" onClick={handleSubmit}>{
                        loading ? <Spinner/> : t("form_save")
                      }</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
    </Fragment>
  );
};

export default CreateTickets;
