import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import reportWebVitals from "./reportWebVitals";
import  ThemeContext  from "./context/ThemeContext"; 
import { SWRConfig } from "swr";
import swrConfig from "./services/swr";
import "./translations"
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store = {store}>
		<ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />        
			<BrowserRouter>
				<ThemeContext>
    				<SWRConfig value={swrConfig}>
						<App />
					</SWRConfig>
				</ThemeContext>  
			</BrowserRouter>                
        </Provider>	
	</React.StrictMode>
);
reportWebVitals();
