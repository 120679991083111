import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import "./style-black.css";
import ReactPlayer from "react-player";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import _ from "lodash";
import { COURSES } from "../../../../mock/courses";
import { FaRegCirclePlay } from "react-icons/fa6";


export default function VideoClassRoom() {
  const [selectedCourse, setSelectedCourse] = useState();
  const [currentLesson, setCurrentLesson] = useState();

  const { locale } = useSelector((state) => state.i18n);
  const { t } = useTranslation();

  const { courseId } = useParams();

  const handleLessonFinished = () => {
    const index = _.findIndex(
      selectedCourse?.lessons,
      (lesson) => lesson.id === currentLesson?.id
    );
    if (index < selectedCourse?.lessons?.length) {
      setCurrentLesson(selectedCourse?.lessons[index + 1]);
    }
  };
  const handleChangeLesson = (lesson) => {
    setCurrentLesson(lesson);
  };

  useEffect(() => {
    const course = COURSES.filter((c) => c.id == courseId);
    if (course) {
      setSelectedCourse(course[0]);
      setCurrentLesson(course[0].lessons[0]);
    }
  }, []);
  return (
    <Fragment >
      <div
      >
        <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
          <h3 className="font-w600 mb-0 me-auto mb-2 text-black">{t(selectedCourse?.title)}</h3>
          <Link
                      to="/video-academy"
                      className="btn rounded-50 btn-primary backbook d-block ml-auto py-3"
                      style={{
                        fontSize: "12pt",
                        borderRadius: 50
                      }}
                    >
                      {/* <ArrowLeftOutlined /> */}
                      {t("return_to_courses")}{" "}
                      <i className="fa fa-long-arrow-left"></i>
                    </Link>
        </div>
        <div className="">
          <div id="wrapper-gui4" className="education">
            <section className="videos_single">
              <div className="">
                <div className="row">
                  <div className="col-md-4 col-lg-4 pt-2">
                    <div className="card rounded-0">
                      <ul className="nav nav-pills flex-md-column" id="" role="">
                        {selectedCourse?.lessons?.map((lesson) => {
                          return (
                            <li key={lesson.id} className="nav-item rounded-0" data="281">
                              <Link
                                className={`nav-link rounded-0 ${lesson.id === currentLesson?.id ? "active" : ""
                                  }`}
                                to="#"
                                style={{ fontSize: "12pt" }}
                                onClick={() => handleChangeLesson(lesson)}
                              >
                                <span className="me-1">
                                  <FaRegCirclePlay />
                                </span>
                                {"  "}
                                <span className="ml-1" style={{ fontSize: "9pt" }}>
                                  {t(lesson.name)}
                                </span>
                                <span
                                  className="timeSpan"
                                  style={{ fontSize: "9pt" }}
                                >
                                  {lesson.time}
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-8">
                    <div className="card">
                      <div className="tab-content p-0" id="v-pills-tabContent2">
                        <div className="tab-pane fade show active">
                          <div className="videoWrapper rounded-0">
                            <div
                              id="vod"
                              className="jwplayer jw-reset jw-state-error jw-stretch-uniform jw-flag-aspect-mode jw-breakpoint-5 jw-flag-user-inactive"
                              tabindex="0"
                              aria-label="Video Player"
                              style={{
                                width: "100%",
                              }}
                            >
                              <div
                                className="jw-media jw-reset"
                                style={{
                                  height: "440px",
                                  borderRadius: "0",
                                  overflow: "hidden",
                                }}
                              >
                                <ReactPlayer
                                  onEnded={handleLessonFinished}
                                  playing
                                  controls
                                  controlsList="nodownload"
                                  height={"446px"}
                                  width={"100%"}
                                  url={`https://d2ikij6pcyb4st.cloudfront.net/files/videos/${locale === "fr" ? "fr" : locale === "de" ? "de" : locale === "pt" ? "pt" : locale === "es" ? "es" : "en"}/${courseId}/${currentLesson?.id}.mp4`}
                                // url={`https://training.StockBridge Capitaltrade.com/files/courses/${locale === "fr" ? "fr" : locale === "de" ? "de" : locale === "pt" ? "pt" : locale === "es" ? "es" : "en"}/${courseId}/${currentLesson?.id}.mp4`}
                                />
                              </div>
                              <div
                                className="jw-captions jw-reset p-3"
                                style={{ fontSize: "23px" }}

                              >
                                <div className="jw-overlays jw-reset">
                                  <div
                                    id="vod_related"
                                    className="jw-plugin jw-reset jw-plugin-related"
                                  ></div>
                                  <div
                                    id="vod_jwpsrv"
                                    className="jw-plugin jw-reset"
                                  ></div>
                                </div>
                                <div className="jw-controls jw-reset">
                                  <div className="jw-display jw-reset">
                                    <div className="jw-display-container jw-reset">
                                      <div className="jw-display-controls jw-reset">
                                        <div className="jw-display-icon-container jw-display-icon-rewind jw-reset">
                                          <div
                                            className="jw-icon jw-icon-rewind jw-button-color jw-reset"
                                            role="button"
                                            tabindex="0"
                                            aria-label="Rewind 10 Seconds"
                                          ></div>
                                        </div>
                                      </div>
                                      <div id="scriptvod"></div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="d-flex align-items-center justify-content-between space20">
                              <h3 className="video-tag mb-0">Progression</h3>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </div>
                            <div className="progress space15">
                              <div
                                className="progress-bar"
                                style={{ width: "0%" }}
                              ></div>
                            </div> */}
                                <h5 className="video- mb-0 space20">
                                  {t(selectedCourse?.title)}
                                </h5>
                                <p className="mb-0 space10 h6 m14">
                                  {t(selectedCourse?.description)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer"></div>
            </section>

            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: "none !important" }}
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <p className="tagTwo text-center text-uppercase txt_yellow">
                      INSCRIVEZ-VOUS POUR DÉVERROUILLER
                    </p>
                    <p className="txt_white mb-0">
                      Ces pages sont verrouillées. Pour accéder au contenu
                      verrouillé, créez un compte et connectez-vous. L`inscription
                      prend généralement moins de 2 minutes.
                    </p>
                    <button onclick="redirect()" className="btn joinbtn">
                      INSCRIVEZ-VOUS POUR DÉBLOQUER
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}
