import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from "react-bootstrap";
import { API } from "../../../../services/axios";
import { URLS } from "../../../../services/urls/urls";
import { formatCurrency } from "../../../components/utils/functions";


const FinancialHistory = () => {

  const [transactions, setTransactions] = useState([]);

  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selected, setSelected] = useState("last_7_days");
  const [pdfData, setPdfData] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      key: 'selection'
    }
  ]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { t } = useTranslation();

  const handleGenerate = () => {

    setDisabled(true);
    let startDate = "";
    let endDate = "";
    const today = moment();

    switch (selected) {
      case "last_7_days":
        endDate = today.format('YYYY-MM-DD');
        startDate = moment(today).subtract(7, 'days').format('YYYY-MM-DD');
        break;
      case "current_month":
        startDate = moment().startOf('month').format('YYYY-MM-DD');
        // Get current month end date
        endDate = moment().endOf('month').format('YYYY-MM-DD');
        break;
      case "last_6_months":
        startDate = today.clone().subtract(6, 'months').startOf('month');
        // Calculate the end date of the last 6 months
        endDate = today.clone().subtract(1, 'months').endOf('month');
        break;
      case "current_year":
        const currentYear = moment().year();

        // Get the start and end dates of the current year
        startDate = moment().year(currentYear).startOf('year').format('YYYY-MM-DD');
        endDate = moment().year(currentYear).endOf('year').format('YYYY-MM-DD');

        break;

      case "last_year":
        // Get the start date of the last year
        startDate = today.subtract(1, 'year').startOf('year');

        // Get the end date of the last year
        endDate = today.endOf('year');
        break;

      default:
        break;
    }

    API.get(URLS.EXPORT.transactions(startDate, endDate), {
      responseType: 'arraybuffer' // Set responseType to 'arraybuffer' to receive binary data
    }).then(response => {

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/pdf' });
      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);
      // Set the URL to the state
      // setPdfData(url);
      var link = document.createElement("a");
      link.href = url;
      link.download = "transactions-reports.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDisabled(false);
    })
      .catch(error => {
        console.error('Error downloading PDF:', error);
        setDisabled(false);
      });
  }


  useEffect(() => {
    API.get(URLS.USERS.MY_TRANSACTIONS).then((resp) => {
      setTransactions(resp.data)
    })

  }, []);

  return (
    <Fragment>
      <div
        className="q-page-container trade-room-layout"
        style={{ paddingTop: 80, paddingBottom: 40, paddingLeft: 79 }}
      >
        <main
          className="q-page container-fluid fit text-info relative pt-0"
          style={{ minHeight: 508 }}
        >
          <h3>{t("txt_fh")}</h3>
          <div className="d-flex">
            <div className="ms-auto" style={{ marginBottom: '10px' }}>
              <Button variant="primary" className="p-3" onClick={handleShow}>
                {t("txt_export_report")}
              </Button>
            </div>
            <Modal show={show} onHide={handleClose}
              size="lg">
              <Modal.Header closeButton>
                <Modal.Title>{t("txt_export_report")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* {pdfData} */}
                <div class="row">
                  <div class="col-md-6">
                    <div class="card-radio">
                      <input type="radio" id="last_7_days" name="options" className={`${selected === "last_7_days" ? 'checked' : ''}`} value="last_7_days" />
                      <label class="card mb-3 border border-1" for="last_7_days" onClick={() => setSelected("last_7_days")}>
                        <div class="card-body">
                          <h5 class="card-title  mb-0 p-3 text-center">{t("last_7_days")}</h5>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card-radio">
                      <input type="radio" id="current_month" name="options" className={`${selected === "current_month" ? 'checked' : ''}`} value="current_month" />
                      <label class="card mb-3 border border-1" for="current_month" onClick={() => setSelected("current_month")}>
                        <div class="card-body">
                          <h5 class="card-title  mb-0 p-3 text-center">{t("current_month")}</h5>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card-radio">
                      <input type="radio" id="last_6_months" name="options" className={`${selected === "last_6_months" ? 'checked' : ''}`} value="last_6_months" />
                      <label class="card mb-3 border border-1" for="last_6_months" onClick={() => setSelected("last_6_months")}>
                        <div class="card-body">
                          <h5 class="card-title mb-0 p-3 text-center">{t("last_6_months")}</h5>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card-radio">
                      <input type="radio" id="current_year" name="options" className={`${selected === "current_year" ? 'checked' : ''}`} value="current_year" />
                      <label class="card mb-3 border border-1" for="current_year" onClick={() => setSelected("current_year")}>
                        <div class="card-body">
                          <h5 class="card-title  mb-0 p-3 text-center">{t("current_year")}</h5>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card-radio">
                      <input type="radio" id="last_year" name="options" className={`${selected === "last_year" ? 'checked' : ''}`} value="last_year" />
                      <label class="card mb-3 border border-1" for="last_year" onClick={() => setSelected("last_year")}>
                        <div class="card-body">
                          <h5 class="card-title  mb-0 p-3 text-center">{t("last_year")}</h5>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" className="p-3" onClick={handleClose}>
                  Close
                </Button>
                <Button disabled={disabled} variant="primary" className="p-3" onClick={handleGenerate}>
                  {t("txt_export_report")}
                  {
                    disabled && <Spinner className="ms-2" size="sm" animation="border"
                      role="status" />
                  }
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div>
            <Tabs
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
              className="mb-3 p-0 bg- financial-history-tabs"
            >
              <Tab
                eventKey="home"
                title={
                  <div
                    className="q-tab relative-position self-stretch flex flex-center text-center q-tab--inactive q-tab--no-caps q-focusable q-hoverable cursor-pointer"
                    tabIndex={-1}
                    role="tab"
                    aria-selected="false"
                  >
                    <div className="q-focus-helper" tabIndex={-1} />
                    <div className="q-tab__content self-stretch flex-center relative-position q-anchor--skip non-selectable column">
                      <div className="q-tab__label">{t("txt_w")}</div>
                    </div>
                    <div
                      className="q-tab__indicator absolute-bottom"
                      style={{
                        transition: "none 0s ease 0s",
                        transform: "none",
                      }}
                    />
                  </div>
                }
                className=""
              >
                <div
                  className="q-card no-border-radius"
                  style={{ minHeight: 200 }}
                >
                  <div
                    className="q-table__container q-table--horizontal-separator column no-wrap q-table__card q-table__card--dark q-table--flat q-table--light q-table--dense q-table--no-wrap full-width my-sticky-header-table-position-pos"
                    style={{ height: "76vh" }}
                  >
                    {/**/}
                    <div className="q-table__middle scroll">
                      <table className="q-table">
                        <thead>
                          <tr className="q-tr">
                            <th className="text-left text-body1 text-info">
                              {t("txt_s_n")}
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("txt_pm")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("w_status")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("w_amount")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("th_comments")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("txt_d")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            _.filter(transactions, { "type": "WITHDRAW" })?.map((transaction) => (

                              <tr key={'withdraw-' + transaction.id} className="q-tr ">
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {transaction.reference}
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {transaction.wallet_address ? `Crypto (${transaction.wallet_address})` : transaction?.transaction_notes === "Invested" ? "Invested" : "Bank transfert"}
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    <div
                                      className={`q-badge flex inline items-center no-wrap q-badge--single-line bg-${transaction.status === "VALIDATED" ? 'positive' : 'negative'}`}
                                      role="alert"
                                      aria-label={transaction.status}
                                    >
                                      {transaction.status}
                                    </div>
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {" "}
                                    {formatCurrency(transaction?.wallet?.currency ?? "EUR")}{Number(transaction.amount).toFixed(2)}
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {" "}
                                    {transaction?.transaction_notes}
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {moment(transaction.date).format("YYYY-MM-DD HH:MM")}
                                  </div>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    {/**/}
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="profile"
                title={
                  <div
                    className="q-tab relative-position self-stretch flex flex-center text-center q-tab--inactive q-tab--no-caps q-focusable q-hoverable cursor-pointer"
                    tabIndex={-1}
                    role="tab"
                    aria-selected="false"
                  >
                    <div className="q-focus-helper" tabIndex={-1} />
                    <div className="q-tab__content self-stretch flex-center relative-position q-anchor--skip non-selectable column">
                      <div className="q-tab__label">{t("button_deposit_text")}</div>
                    </div>
                    <div
                      className="q-tab__indicator absolute-bottom"
                      style={{
                        transition: "none 0s ease 0s",
                        transform: "none",
                      }}
                    />
                  </div>
                }
              >
                <div
                  className="q-card no-border-radius"
                  style={{ minHeight: 200 }}
                >
                  <div
                    className="q-table__container q-table--horizontal-separator column no-wrap q-table__card q-table__card--dark q-table--flat q-table--light q-table--dense q-table--no-wrap full-width  my-sticky-header-table-position-pos"
                    style={{ height: "76vh" }}
                  >
                    {/**/}
                    <div className="q-table__middle scroll">
                      <table className="q-table">
                        <thead>
                          <tr className="q-tr">
                            <th className="text-left text-body1 text-info">
                              {t("txt_s_n")}
                            </th>
                            {/* <th className="text-left sortable text-body1 text-info">
                              Deposit Method
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th> */}
                            <th className="text-left sortable text-body1 text-info">
                              {t("w_status")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("w_amount")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("th_comments")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("txt_d")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            _.filter(transactions, { "type": "CREDIT" })?.map((transaction) => (

                              <tr key={'deposit-' + transaction.id} className="q-tr ">
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {transaction.reference}
                                  </div>
                                </td>
                                {/* <td className="q-td text-left">
                              <div className="text-subtitle1">
                                Deposite
                              </div>
                            </td> */}
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    <div
                                      className={`q-badge flex inline items-center no-wrap q-badge--single-line bg-${transaction.status === "VALIDATED" ? 'positive' : 'negative'}`}
                                      role="alert"
                                      aria-label={transaction.status}
                                    >
                                      {transaction.status}
                                    </div>
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {" "}
                                    {formatCurrency(transaction?.wallet?.currency ?? "EUR")}{Number(transaction.amount).toFixed(2)}
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {" "}
                                    {transaction?.transaction_notes}
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {moment(transaction.date).format("YYYY-MM-DD HH:MM")}
                                  </div>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    {/**/}
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="contact"
                title={
                  <div
                    className="q-tab relative-position self-stretch flex flex-center text-center q-tab--inactive q-tab--no-caps q-focusable q-hoverable cursor-pointer"
                    tabIndex={-1}
                    role="tab"
                    aria-selected="false"
                  >
                    <div className="q-focus-helper" tabIndex={-1} />
                    <div className="q-tab__content self-stretch flex-center relative-position q-anchor--skip non-selectable column">
                      <div className="q-tab__label">{t("txt_at")}</div>
                    </div>
                    <div
                      className="q-tab__indicator absolute-bottom"
                      style={{
                        transition: "none 0s ease 0s",
                        transform: "none",
                      }}
                    />
                  </div>
                }
              >
                <div
                  className="q-card  no-border-radius"
                  style={{ minHeight: 200 }}
                >
                  <div
                    className="q-table__container q-table--horizontal-separator column no-wrap q-table__card q-table__card--dark q-table--flat q-table--light q-table--dense q-table--no-wrap full-width my-sticky-header-table-position-pos"
                    style={{ height: "76vh" }}
                  >
                    {/**/}
                    <div className="q-table__middle scroll">
                      <table className="q-table">
                        <thead>
                          <tr className="q-tr">
                            <th className="text-left text-body1 text-info">
                              {t("txt_s_n")}
                            </th>
                            {/* <th className="text-left sortable text-body1 text-info">
                              Deposit Method
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th> */}
                            <th className="text-left sortable text-body1 text-info">
                              {t("w_status")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("w_amount")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("th_comments")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                            <th className="text-left sortable text-body1 text-info">
                              {t("txt_d")}
                              <i
                                className="q-icon notranslate material-icons q-table__sort-icon q-table__sort-icon--left"
                                aria-hidden="true"
                                role="presentation"
                              >
                                arrow_upward
                              </i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            transactions?.map((transaction) => (

                              <tr key={'tr-' + transaction.id} className="q-tr ">
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {transaction.reference}
                                  </div>
                                </td>
                                {/* <td className="q-td text-left">
                              <div className="text-subtitle1">
                                Deposite
                              </div>
                            </td> */}
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    <div
                                      className={`q-badge flex inline items-center no-wrap q-badge--single-line bg-${transaction.status === "VALIDATED" ? 'positive' : 'negative'}`}
                                      role="alert"
                                      aria-label={transaction.status}
                                    >
                                      {transaction.status}
                                    </div>
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {" "}
                                    {formatCurrency(transaction?.wallet?.currency ?? "EUR")}{Number(transaction.amount).toFixed(2)}
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {" "}
                                    {transaction?.transaction_notes}
                                  </div>
                                </td>
                                <td className="q-td text-left">
                                  <div className="text-subtitle1">
                                    {moment(transaction.date).format("YYYY-MM-DD HH:MM")}
                                  </div>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    {/**/}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </main>
      </div>
    </Fragment>
  );
};

export default FinancialHistory;
