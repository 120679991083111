import { toast } from "react-toastify";




export const copyText = (text, successMessage) => {

    navigator.clipboard.writeText(text).then(() => {
        toast.success(successMessage ?? "Text copied in clipboard")
        /* Resolved - text copied to clipboard successfully */
      },() => {
        console.error('Failed to copy');
        /* Rejected - text failed to copy to the clipboard */
      });
}