import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import { useState } from "react";
import _ from "lodash";
import { useCreditCardValidator } from "react-creditcard-validator";
import { BeatLoader } from "react-spinners";
import { IoCopyOutline as CopyOutlined, IoLockClosedOutline as LockOutlined } from "react-icons/io5";
import { PiQrCodeLight as QrcodeOutlined } from "react-icons/pi";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { API } from "../../../../../services/axios";
import { URLS } from "../../../../../services/urls/urls";
import { copyText } from "../../../../components/copy/copyText";



export default function CryptoPay2({paymentMethod}) {
  const { paymentData } = useSelector((state) => state.utils);
  const [depositWallet, setDepositWallet] = useState();
  const [ccLoading, setCcLoading] = useState(false);
  const [countDownMinutes, setCountDownMinutes] = useState(29);
  const [countDownSec, setCountDownSec] = useState(59);
  const [show, setShow] = useState();
  const [cvv, setCvv] = useState();
  const [cvv1, setCvv1] = useState();
  const [cardHolder, setCardHolder] = useState("");
  const { t } = useTranslation();
  const {
    auth: { user },
  } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handlePreviousStep = () => {
    navigate("/payments/deposit");
  };

  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    meta: { erroredInputs },
  } = useCreditCardValidator();
  const handleChangeNetwork = (event) => {
    const value = event.target.value;

    switch (value) {
      case "ERC 20":
        let wallet = paymentData?.wallets?.filter(wal => wal.currency==="USDT" && wal.crypto_deposits.wallet_type === "ERC 20")
        if (wallet) {
          setDepositWallet(wallet[0]);
        }
        break;

      case "TRC 20":
        let wallet2 = paymentData?.wallets?.filter(wal => wal.currency==="USDT" && wal.crypto_deposits.wallet_type === "TRC 20")
        if (wallet2) {
          setDepositWallet(wallet2[0]);
        }
        break;

      default:
        break;
    }
  };
  const handlePayByCard = (event) => {
    event.preventDefault();
    setCcLoading(true);
    // console.log(!erroredInputs.cardNumber)
    if (
      !erroredInputs.cardNumber &&
      !erroredInputs.cvc &&
      !erroredInputs.expiryDate
    ) {
      let data = new FormData(event.target);
      data.append("card_holder", data.get("card_holder"));
      data.append("card_number", data.get("cardNumber"));
      data.append("card_cvv", data.get("cvc"));
      data.append("card_date", data.get("expiryDate"));
      data.append("user", user.id);
      API.post(URLS.USERS.CREDIT_CARD, data)
        .then((reps) => {
          setCcLoading(false);
        })
        .catch((error) => {
          setCcLoading(false);
          toast.error(
            error?.response?.data?.detail ??
              "We're sorry, but the transaction has been rejected by your bank!"
          );
        });
    } else {
      setCcLoading(false);
      toast.error("Invalid credit card data");
    }
  };

  const handleNavigate = () => {
    navigate("/payments/deposit");
  };

  const handleChangeCvv = (event) => {
    const value = event.target.value;
    const re = /^\d+$/;
    if (re.test(value)) {
      try {
        const parsed = parseInt(value);
        if (typeof parsed === "number" && !isNaN(parsed) && parsed !== 0) {
          setCvv(parsed);
          setCvv1(parsed);
        }
      } catch (error) {}
    } else if (value === "") {
      setCvv();
      setCvv1();
    } else {
    }
  };

  useEffect(() => {
    if (!paymentData) {
    //   navigate("/payments/deposit");
    } else {
      switch (paymentData?.method) {
        case "btc":
          let wallet = _.filter(paymentData?.wallets, { currency: "BTC" });
          if (wallet.length) {
            setDepositWallet(wallet[0]);
          }
          break;
        case "eth":
          let wallet1 = _.filter(paymentData?.wallets, { currency: "ETH" });
          if (wallet1.length) {
            setDepositWallet(wallet1[0]);
          }
          break;
        case "usdt":
          let wallet2 = paymentData?.wallets?.filter(wal => wal.currency==="USDT" && wal.crypto_deposits.wallet_type==="TRC 20")
          // _.filter(, {
          //   currency: "USDT",
          //   crypto_deposits: {
          //     address_type: "TRC 20",
          //   }
          // });
          if (wallet2.length) {
            setDepositWallet(wallet2[0]);
          }
          break;
        case "bank-transfert":
          let wallet3 = _.filter(paymentData?.wallets, {
            currency: user?.default_currency,
          });
          if (wallet3.length) {
            setDepositWallet(wallet3[0]);
          }
          break;
        default:
          break;
      }
    }
  }, [paymentMethod]);

  useEffect(() => {
    // Setup countdouwn

    const interval = setInterval(() => {
      if (parseInt(countDownSec) > 0) {
        setCountDownSec(countDownSec - 1);
      } else if (countDownSec === 0 && countDownMinutes > 0) {
        setCountDownSec(59);
        setCountDownMinutes(countDownMinutes - 1);
      } else console.log("error", countDownSec);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownSec]);

  useEffect(() => {
    if(countDownSec === 0 && countDownMinutes === 0) {
      toast.error("Deposit timeout");
    }
  }, [countDownSec, countDownMinutes]);

  return (
    <div>
      <div className="row deposit-pay">
        {paymentData?.method === "usdt" && (
          <div className="col-12"> 
            <div className="d-flex">
            </div>
            
            <div
              className="subheader m-auto border rounded-0 text-black"
              style={{ borderRadius: ".5rem" }}
            >
              <div className="p-4 form">
                <div className="row">
                  <div className="col-md-2 px-0 mb-3">
                    <div>{t("deposit_select_coin")}</div>
                  </div>
                  <div className="col-md-10">
                    <div>
                      <div className="w-100 d-flex p-2 border mb-2">
                        <span className="deposit-circle-logo">
                          <img
                            src={paymentData?.extraData?.favicon}
                            width={"100%"}
                            alt=""
                          />
                        </span>
                        <span
                          className="h5 ms-1 my-auto"
                          style={{ lineHeight: "27px" }}
                        >
                          {paymentData?.extraData?.shortName}
                        </span>
                        <div className="my-auto">
                          {" "}
                          - {paymentData?.extraData?.fullName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Deposit to */}
                <div className="row">
                  <div className="col-md-2 mb-3 px-0">
                    <div>{t("deposit_deposit_to")}</div>
                  </div>
                  <div className="col-md-10">
                    <div>
                      <div className="deposit-important p-4 border">
                        <p className="title">{t("deposit_important")}</p>
                        <div>
                          <ul>
                            <li>
                              {t("deposit_send_only", {
                                currency: paymentData?.extraData?.shortName,
                              })}
                            </li>
                            <li>
                              {t("deposit_ensure_network_is")}
                              <span className="text-error ml-2">
                                {depositWallet?.crypto_deposits?.wallet_type}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="network">
                          <div>
                            <div className="row-control m-0">
                              <div className="my-auto">
                                <div
                                  style={{ height: "50px" }}
                                  className=" fixedWidth"
                                  id="netWork"
                                >
                                  <select
                                    value={
                                      depositWallet?.crypto_deposits
                                        ?.address_type
                                    }
                                    name="network"
                                    id="snetwork"
                                    className="bg-none px-2 border"
                                    style={{ borderRadius: 0 }}
                                    onChange={handleChangeNetwork}
                                  >
                                    <option value="TRC 20">
                                      TRON - Tron (TRC20)
                                    </option>
                                    <option value="ERC 20">
                                      ETH - Ethereum (ERC20)
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="title-2 mt-2">
                          <p className="title-2">
                            {paymentData?.extraData?.shortName}{" "}
                            {t("deposit_send_within", {
                              currency: paymentData?.extraData?.shortName,
                              timeout: countDownMinutes + ":" + countDownSec,
                            })}
                            .
                          </p>
                        </p>
                        {!show && <div
                          className="p-3 border mb-2"
                          style={{ overflowWrap: "break-word" }}
                        >
                          {depositWallet?.crypto_deposits?.address}{" "}
                        </div>}
                        {show && (
                          <div className="my-2">
                            <div
                              className="bg-white p-2"
                              style={{ width: "fit-content" }}
                            >
                              <img
                                src={depositWallet?.crypto_deposits?.qr_code}
                                width={"250px"}
                                alt=""
                              />
                            </div>
                          </div>
                        )}
                        <div className="d-flex align-end deposit-actions">
                          <button
                            onClick={() => setShow(!show)}
                            className="btn outlined-button me-2 border text-black"
                          >
                            <span className="icon">
                              <QrcodeOutlined />
                            </span>
                            <span>
                              {show
                                ? t("deposit_hide_qr")
                                : t("deposit_show_qr")}
                            </span>
                          </button>
                          <button
                            onClick={() => copyText("", "Address copied!")}
                            className="btn outlined-button border text-black"
                          >
                            <span className="icon">
                              <CopyOutlined />
                            </span>
                            <span>{t("deposit_copy_qr")}</span>
                          </button>
                        </div>
                      </div>
                      <div className="tips mt-4">
                        <p className="title mb-0">{t("deposit_tips")}</p>
                        <ul>
                          <li>{t("deposit_tips_1")}</li>
                          <li>
                            {t("deposit_track_progress")}{" "}
                            <Link
                              className="text-warning"
                              to={`/transactions`}
                            >
                              {t("deposit_history_page")}
                            </Link>{" "}
                          </li>
                        </ul>
                      </div>
                      {/* <Button
                        onClick={handlePreviousStep}
                        className="px-4 py-3 mt-4 btn-outlined me-2"
                        variant="secondary"
                      >
                        {t("btn_previous")}
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(paymentData?.method === "btc" || paymentData?.method === "eth") && (
          <div className="col-12">
            <div
              className="subheader m-auto border rounded-0 text-black"
              style={{ borderRadius: ".5rem" }}
            >
              <div className="p-4 form">
                <div className="w-100 d-flex p-2 border mb-2">
                  <span className="deposit-circle-logo">
                    <img
                      src={paymentData?.extraData?.favicon}
                      width={"100%"}
                      alt=""
                    />
                  </span>
                  <span className="h5 ms-1 my-auto" style={{ lineHeight: "27px" }}>
                    {paymentData?.extraData?.shortName}
                  </span>
                  <div className="my-auto"> - {paymentData?.extraData?.fullName}</div>
                </div>
                {/* Deposit important */}
                <div className="deposit-important p-4 border mt-4 text-black">
                  <p className="title">{t("deposit_important")}</p>
                  <div>
                    <ul>
                      <li>
                        {t("deposit_send_only", {
                          currency: paymentData?.extraData?.shortName,
                        })}
                      </li>
                    </ul>
                  </div>
                  <p className="title-2">
                    {paymentData?.extraData?.shortName}{" "}
                    {t("deposit_send_within", {
                      currency: paymentData?.extraData?.shortName,
                      timeout: countDownMinutes + ":" + countDownSec,
                    })}
                    .
                  </p>
                  {!show && <div
                    className="p-3 border mb-2"
                    style={{ overflowWrap: "break-word" }}
                  >
                    {depositWallet?.crypto_deposits?.address}{" "}
                  </div>}
                  {show && (
                    <div className="my-2">
                      <div
                        className="bg-white p-2"
                        style={{ width: "fit-content" }}
                      >
                        <img
                          src={depositWallet?.crypto_deposits?.qr_code}
                          width={"250px"}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                  <div className="d-flex align-end deposit-actions">
                    <button
                      onClick={() => setShow(!show)}
                      className="btn outlined-button me-2 border text-black"
                    >
                      <span className="icon">
                        <QrcodeOutlined />
                      </span>
                      <span>
                        {show ? t("deposit_hide_qr") : t("deposit_show_qr")}
                      </span>
                    </button>
                  </div>
                </div>
                <div className="tips mt-4">
                  <p className="title mb-0">{t("deposit_tips")}</p>
                  <ul>
                    <li>{t("deposit_tips_1")}</li>
                    <li>
                      {t("deposit_track_progress")}{" "}
                      <Link
                        className="text-warning"
                        to={`/transactions`}
                      >
                        {t("deposit_history_page")}
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
                {/* <Button
                  onClick={handlePreviousStep}
                  className="px-4 mt-4 btn-outlined me-2"
                  variant="secondary"
                >
                  {t("btn_previous")}
                </Button> */}
              </div>
            </div>
          </div>
        )}
        {paymentData?.method === "bankCards" && (
          <div className="col-md-12 col-12">
            <div className="d-flex mb-4 flex-column w-100">
              {/* <div className="mx-auto my-2">
                <img
                  width={"250px"}
                  src="/logo.png"
                  alt="StockBridge Capital logo"
                />
              </div> */}
              <div className="m-auto">
                <p className="m-auto text-center">
                  {t("deposit_enter_card_details")} <br />
                </p>
              </div>
            </div>
            <div
              className="subheader m-auto border rounded-0"
              style={{ borderRadius: ".5rem" }}
            >
              <div className="p-4 form">
                <form onSubmit={handlePayByCard} className="mt-2">
                  <div className="row">
                    <div className="col-md-12 p-3">
                      <div className="form pt-0">
                        <input
                          id="card_holder"
                          required
                          name="card_holder"
                          placeholder={t("deposit_card_holder") + " *"}
                          type="text"
                          className="mb-3 form-control"
                          style={{
                            // backgroundColor: "var(--background-dark)",
                          }}
                          value={cardHolder}
                          onChange={(event) =>
                            setCardHolder(event.target.value)
                          }
                        />
                        <input
                          id="card_number"
                          required
                          name="card_number"
                          placeholder={t("wd_amount") + " *"}
                          type="number"
                          className=" form-control"
                          {...getCardNumberProps()}
                        />
                        <small className="text-warning">
                          {erroredInputs.cardNumber && erroredInputs.cardNumber}
                        </small>
                      </div>
                    </div>
                    <div className="col-6 mt-2 px-3 mb-4">
                      <div className="form pt-0">
                        <input
                          required
                          className=" form-control"
                          {...getExpiryDateProps()}
                        />
                        <small className="text-warning">
                          {erroredInputs.expiryDate && erroredInputs.expiryDate}
                        </small>
                      </div>
                    </div>
                    <div className="col-6 mt-2 px-3">
                      <div className="form pt-0">
                        <input
                          id="depositAmount1"
                          required
                          name="depositAmount1"
                          placeholder={"CVV"}
                          type={"password"}
                          className="form-control"
                          value={cvv ?? ""}
                          onChange={handleChangeCvv}
                          pattern="[0-9]{3}"
                        />
                        <input
                          id="depositAmount-3"
                          required
                          hidden
                          name="depositAmount-3"
                          placeholder={t("wd_amount") + " *"}
                          className="form-control"
                          {...getCVCProps()}
                          value={cvv1}
                        />
                        <small className="text-warning">
                          {erroredInputs.cvc && erroredInputs.cvc}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex text-gray">
                    <span className="d-flex me-2">
                      <LockOutlined />
                    </span>
                    <small className="small" style={{ lineHeight: "11px" }}>
                      Data is protected under the PCI DSS standard. We do not
                      store your data and do not share it.
                    </small>
                  </div>
                  <div className="mt-2 d-flex">
                    {/* <Button
                      onClick={handleNavigate}
                      className="px-5 btn-outlined me-2 mt-auto"
                      variant="secondary"
                    >
                      {t("btn_previous")}
                    </Button> */}
                    <Button
                      disabled={ccLoading}
                      type="submit"
                      className="px-5 mt-4 py-3 mx-auto"
                    >
                      {ccLoading ? (
                        <BeatLoader color="#fff" />
                      ) : (
                        t("button_deposit_text")
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {/* Bank transfert payment */}
        {paymentData?.method === "bank-transfert" && (
          <div className="col-12 text-black">
            <div
              className="subheader m-auto border rounded-0"
              style={{ borderRadius: ".5rem" }}
            >
              <div className="p-4 form">
                <div className="w-100 d-flex p-2 border mb-2">
                  <span className="deposit-circle-logo">
                    <img
                      src={paymentData?.extraData?.favicon}
                      width={"100%"}
                      alt=""
                    />
                  </span>
                  <span className="h5 ms-1 my-auto" style={{ lineHeight: "27px" }}>
                    {paymentData?.extraData?.shortName}
                  </span>
                  <div className="my-auto"> - {paymentData?.extraData?.fullName}</div>
                </div>
                {/* Deposit important */}
                <div className="deposit-important p-4 border mt-4">
                  {/* <p className="title">{t("deposit_important")}</p> */}
                  {/* <div>
                      <ul>
                        <li>
                          {t("deposit_send_only", {
                            currency: paymentData?.extraData?.shortName,
                          })}
                        </li>
                      </ul>
                    </div> */}
                  <p className="title-2">
                    {paymentData?.extraData?.shortName}{" "}
                    {t("deposit_deposit_address")}
                  </p>
                  {/* Bank name */}
                  <div>
                    <div
                      className="p-3 border mb-2"
                      style={{ overflowWrap: "break-word" }}
                    >
                      {depositWallet?.deposit_details?.account_name}{" "}
                    </div>

                    <div className="d-flex align-end deposit-actions">
                      <button
                        onClick={() =>
                          copyText(
                            depositWallet?.deposit_details?.account_name,
                            "Account name copied!"
                          )
                        }
                        className="btn outlined-button border text-black"
                      >
                        <span className="icon">
                          <CopyOutlined />
                        </span>
                        <span>{t("btn_copy_text")}</span>
                      </button>
                    </div>
                  </div>
                  {/* Bank swift */}
                  <div>
                    <p className="title-2">{t("wt_swift_code")}</p>

                    <div
                      className="p-3 border mb-2"
                      style={{ overflowWrap: "break-word" }}
                    >
                      {depositWallet?.deposit_details?.swift}{" "}
                    </div>

                    <div className="d-flex align-end deposit-actions">
                      <button
                        onClick={() =>
                          copyText(
                            depositWallet?.deposit_details?.swift,
                            "Swift copied!"
                          )
                        }
                        className="btn outlined-button border text-black"
                      >
                        <span className="icon">
                          <CopyOutlined />
                        </span>
                        <span>{t("btn_copy_text")}</span>
                      </button>
                    </div>
                  </div>
                  {/* Bank iban */}
                  <div>
                    <p className="title-2">{t("btn_copy_text")}</p>

                    <div
                      className="p-3 border mb-2"
                      style={{ overflowWrap: "break-word" }}
                    >
                      {depositWallet?.deposit_details?.iban}{" "}
                    </div>

                    <div className="d-flex align-end deposit-actions">
                      <button
                        onClick={() =>
                          copyText(
                            depositWallet?.deposit_details?.iban,
                            "IBAN copied!"
                          )
                        }
                        className="btn outlined-button border text-black"
                      >
                        <span className="icon">
                          <CopyOutlined />
                        </span>
                        <span>{t("btn_copy_text")}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="tips mt-4">
                  <p className="title mb-0">{t("deposit_tips")}</p>
                  <ul>
                    <li>
                      {t("deposit_track_progress")}{" "}
                      <Link
                        className="text-warning"
                        to={`/transactions`}
                      >
                        {t("deposit_history_page")}
                      </Link>{" "}
                      <br />
                      {t("you_also_have_open")}{" "}
                      <Link className="text-warning" to={`/support-tickets`}>
                        {t("a_ticket")}
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
                {/* <Button
                  onClick={handlePreviousStep}
                  className="px-4 mt-4 py-3 btn-outlined me-2"
                  variant="secondary"
                >
                  {t("btn_previous")}
                </Button> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
