import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _, { set } from "lodash";
import "./styles.css";
import moment from "moment";
import PositionRow from "./PositionRow";
import { toast } from "react-toastify";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls/urls";
import useSWR from "swr";
import { Nav, Tab, Tabs } from "react-bootstrap";



export default function WebtraderFooter(props) {

  const { change, activeWallet, user, conversioRate } = props;
  const [pageSize, setPageSize] = useState(10);
  const [processing, setProcessing] = useState("");

  const [buyAmount, setBuyAmount] = useState(0);
  const [buyTotal, setBuyTotal] = useState();
  const [buySL, setBuySL] = useState(0);
  const [buyTP, setBuyTP] = useState(0);
  const [buyPips, setBuyPips] = useState(1);

  const [sellAmount, setSellAmount] = useState();
  const [sellTotal, setSellTotal] = useState();
  const [sellSL, setSellSL] = useState(0);
  const [sellTP, setSellTP] = useState(0);
  const [pips, setPips] = useState(1);

  const {data: walletsList, isLoading: isLoadingWallets, mutate: mutateWallets} = useSWR(`${URLS.USERS.MY_WALLETS("")}`);
  const {data: positionsList, isLoading: isLoadingPositions, mutate: mutatePositions} = useSWR(`${URLS.USERS.MY_POSITIONS}?page_size=100000`);

  const handleChangePips = (value, side) => {

    if(value > 0, change) {
      if(side === "SELL") {
        setPips(value);
        setSellTotal(change?.last_price * value * conversioRate);
      } else {
        setBuyPips(value);
        setBuyTotal(change?.last_price * value * conversioRate);
      }
    }
  }

  const handleOpenPosition = (event, side) => {
    event.preventDefault();
    // side can be SELL or BUY

    if(activeWallet && parseFloat(buyTotal) > 0) {

      const positionData = {
        "wallet": activeWallet?.id,
        "symbol": change.short_name,
        "quantity": parseFloat(buyTotal),
        // "quantity": side === "SELL" ? parseFloat(sellAmount) * parseFloat(change.last_price) : parseFloat(buyAmount) * parseFloat(change.last_price),
        "opening_price": change.last_price,
        "ox_p": change.last_price,
        "ox_pq": parseFloat(buyTotal),
        // "ox_pq": side === "SELL" ? parseFloat(sellAmount) * parseFloat(change.last_price) : parseFloat(buyAmount) * parseFloat(change.last_price),
        "ox_sw": change.short_name, 
        "ox_ss": activeWallet?.id, 
        "ox_usr": side,
        "ox_sd": user.id,
        "position_type": side,
        "created_by": user.id,
        "profit_lost": 0,
        "status": "OPENED",
        "closing_price": 0,
        "commission": 0,
        "take_profit": buyTP,
        "stop_loss": buySL,
      }
  
  
      setProcessing(side);
      API.post(URLS.POSITIONS.USER_CREATE, positionData).then((resp) => {
        setProcessing();
        toast.success(t("textPositionOpenSuccess"));
        setPageSize(_.uniqueId())
      }).catch((error) => {
        setProcessing();
        toast.error(error?.response?.data?.detail ?? error?.response?.data?.error ?? t("textPositionOpenError"));
      })
    }
    
  }

  const handleChangeBuyAmount = (event) => {
    const val = event.target.value;
    setBuyAmount(val);
    setBuyTotal(val * change.last_price)
  }

  const handleChangeSellAmount = (event) => {
    const val = event.target.value;
    setSellAmount(val);
    setSellTotal(val * change.last_price)
  }


  const handleChangeInvestAmount = (event, side) => {
    if(parseFloat(event.target.value) <= activeWallet?.balance) {

      setBuyTotal(event.target.value);

      if(parseFloat(event.target.value) >= 0 && change?.last_price > 0){
        // Evaluate pips
        const pips = parseFloat(event.target.value) / change.last_price;
        setBuyPips(pips);
      }
    }
  }

  const { t } = useTranslation();

  const reload = () => {
    mutateWallets();
  }

  return (
    <div>
      <div className="mt-3">
        <div className="row sm-gutters">
          <div className="col-xl-4">
            <div className="card">
              <div className="card-body">
                <div className="crypt-boxed-area">
                  <h6 className="crypt-bg-head d-flex flex-column flex-sm-row">
                    <span>
                      <b className="crypt-up">BUY</b> / <b className="crypt-down">SELL</b>
                    </span> 
                    <br />
                    <span className="ms-auto">
                      <span className="mr-4">{change?.original_name}</span>
                    <span className={`ms-2 ${change?.change_percent > 0 ? 'crypt-up' : 'crypt-down'}`}>{change?.change_percent} %</span>
                    </span>
                  </h6>
                  <div className="row no-gutters">
                    <div className="col-md-12">
                      <div className="crypt-buy-sell-form mt-3">
                        <div className="crypt-buy">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              {" "}
                              <span className="input-group-text h-100 rounded-end-0">{t("text_pips")}</span>{" "}
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("text_pips")}
                              value={buyPips}
                              // readOnly
                              onChange={(e) => handleChangePips(e?.target?.value, "BUY")}
                            />
                          </div>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              {" "}
                              <span className="input-group-text h-100 rounded-end-0">{t("textPrice")}</span>{" "}
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={change.last_price * conversioRate}
                              readOnly
                            />
                          </div>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              {" "}
                              <span className="input-group-text h-100 rounded-end-0">{t("w_amount")}</span>{" "}
                            </div>
                            <input type="text" className="form-control" value={buyTotal} onChange={(e) => handleChangeInvestAmount(e, "BUY")} />
                            <div className="input-group-append">
                              {" "}
                              <span className="input-group-text h-100 rounded-start-0">{activeWallet?.currency}</span>{" "}
                            </div>
                          </div>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              {" "}
                              <span className="input-group-text h-100 rounded-end-0">{t("text_stop_loss")}</span>{" "}
                            </div>
                            <input type="number" className="form-control"  min={1} max={activeWallet?.balance} onChange={(event) => setBuySL(event.target.value)} value={buySL}/>
                            <div className="input-group-append">
                              {" "}
                              <span className="input-group-text h-100 rounded-start-0">{activeWallet?.currency}</span>{" "}
                            </div>
                          </div>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              {" "}
                              <span className="input-group-text h-100 rounded-end-0">{t("text_take_profit")}</span>{" "}
                            </div>
                            <input type="text" className="form-control" onChange={(event) => setBuyTP(event.target.value)} value={buyTP}/>
                            <div className="input-group-append">
                              {" "}
                              <span className="input-group-text h-100 rounded-start-0">{activeWallet?.currency}</span>{" "}
                            </div>
                          </div>
                          <div className="menu-green ringing-bell">                              
                            <div class="buttons">
                              <button onClick={(event) => handleOpenPosition(event, "BUY")}>
                                {t("textBuy")}
                                {processing === "BUY" && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft: '10px'}}></span>
                              }
                              </button>
                              <button onClick={(event) => handleOpenPosition(event, "SELL")}>
                                {t("textSell")}
                                {processing === "SELL" && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft: '10px'}}></span>
                              }
                              </button>
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="card">
              <div className="card-body">
                <div>
                  
                  <Tab.Container defaultActiveKey="active-orders">
                      <div className="card-action card-tabs mb-3 style-1">
                        <Nav as="ul" className="nav-tabs" role="tablist">
                          <Nav.Item as="li" className="nav-item"><Nav.Link to={"#"} eventKey="active-orders">{t("active_orders")}</Nav.Link></Nav.Item>
                          <Nav.Item as="li" className="nav-item"><Nav.Link to={"#"} eventKey="closed-orders">{t("closed_orders")}</Nav.Link></Nav.Item>
                          <Nav.Item as="li" className="nav-item"><Nav.Link to={"#"} eventKey="balance" >{t("wt_balance")}</Nav.Link></Nav.Item>
                        </Nav>
                      </div>
                    <Tab.Content>
                      <Tab.Pane eventKey="active-orders">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">{t("th_symbol")}</th>
                            <th scope="col">{t("th_position_type")}</th>
                            <th scope="col">{t("th_quantity")}</th>
                            <th scope="col">{t("th_open_price")}</th>
                            <th scope="col">{t("th_close_price")}</th>
                            <th scope="col">{t("th_profit_lost")}</th>
                            <th scope="col">{t("text_stop_loss")}</th>
                            <th scope="col">{t("text_take_profit")}</th>
                            <th scope="col">{t("txt_actions")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Filter closed positions */}
                          {_.filter(positionsList?.results ?? [], { status: "OPENED" })?.map(
                            (position, index) => {
                              return (
                                <PositionRow position={position} index={index} change={change} reload={reload} isOpen={position?.status === "OPENED"} />
                              );
                            }
                          )}
                        </tbody>
                      </table>
                      </Tab.Pane>
                      <Tab.Pane eventKey="closed-orders">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">{t("th_symbol")}</th>
                                <th scope="col">{t("th_position_type")}</th>
                                <th scope="col">{t("th_quantity")}</th>
                                <th scope="col">{t("th_open_price")}</th>
                                <th scope="col">{t("th_close_price")}</th>
                                <th scope="col">{t("th_profit_lost")}</th>
                                <th scope="col">{t("text_stop_loss")}</th>
                                <th scope="col">{t("text_take_profit")}</th>
                                <th scope="col">{t("th_comments")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* Filter closed positions */}
                              {_.filter(positionsList?.results ?? [], { status: "CLOSED" })?.map(
                                (position, index) => {
                                  return (
                                    <tr key={position.id + index}>
                                      <th>
                                        {moment(position?.created_at)?.format(
                                          "lll"
                                        )}
                                      </th>
                                      <td className="">{position?.symbol}</td>
                                      <td
                                        className={
                                          position?.position_type == "SELL"
                                            ? "crypt-up"
                                            : "crypt-down"
                                        }
                                      >
                                        {position?.position_type}
                                      </td>
                                      <td className="">{Number(position?.quantity).toFixed(2)}</td>
                                      <td className="">{Number(position?.opening_price).toFixed(2)}</td>
                                      <td className="">{Number(position?.closing_price).toFixed(2)}</td>
                                      <td
                                        className={
                                          position?.profit_lost >= 0
                                            ? "crypt-up"
                                            : "crypt-down"
                                        }
                                      >
                                        {Number(position?.profit_lost).toFixed(2)}
                                      </td>
                                      <td className="">
                                        {Number(position?.stop_loss).toFixed(2)} {position?.wallet?.currency}
                                      </td>
                                      <td className="">
                                        {Number(position?.take_profit).toFixed(2)} {position?.wallet?.currency}
                                      </td>
                                      <td>{position?.comments}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                      </Tab.Pane>
                      <Tab.Pane eventKey="balance">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">{t("w_currency")}</th>
                                <th scope="col">{t("w_amount")}</th>
                                <th scope="col">{t("w_volume")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {walletsList?.results?.map((wallet) => {
                                return (
                                  <tr>
                                    <th>{wallet?.currency}</th>
                                    <td>{wallet?.balance}</td>
                                    <td>{wallet?.volume}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
