import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Fragment, useEffect, useState } from "react";
import { formatCurrency } from "../utils/functions";
import useSWR from "swr";
import { URLS } from "../../../services/urls/urls";
import { LiaCertificateSolid } from "react-icons/lia";


ChartJS.register(ArcElement, Tooltip);

const data = {
  labels: ["Brokerage & Trading"],
  datasets: [
    {
      label: "Brokerage & Trading",
      data: [100],
      backgroundColor: ["#007bff"],
      borderColor: ["#fff"],
      borderWidth: 1,
    },
  ],
};

export default function Home() {
  const { user } = useSelector((state) => state.auth.auth);
  const [dataSet, setDataSet] = useState(data);
  const [selectedWallet, setSelectedWallet] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {data: walletsList, isLoading} = useSWR(URLS.USERS.MY_WALLETS(""));

  useEffect(() => {
    if (selectedWallet) {
      setDataSet((prev) => {
        return {
          ...prev,
          datasets: prev.datasets.map((dataset) => {
            dataset.data = [
              selectedWallet?.balance
                ? Number(selectedWallet?.balance ?? 0.0).toFixed(2)
                : 0,
            ];
            return dataset;
          }),
        };
      });
    }
  }, [selectedWallet]);

  useEffect(() => {
    if (walletsList?.results?.length > 0) {
      const results = walletsList?.results;
      setSelectedWallet(
        results?.filter((item) => item.currency === "USD")[0] ??
          results?.filter((item) => item.currency === "EUR")[0]
      );
    }
  }, [walletsList]);

  return (
    <Fragment>
      <div
        className=""
      >
        <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
        <h2 className="font-w600 mb-2 me-auto ">{t("txt_dashboard")}</h2>
        </div>
        <main
          className=""
          style={{ minHeight: 508 }}
        >
          <div className="w1page">
            <div id="page-account" className="w">
              <div cla>
                <div
                  className="q-scrollarea"
                  style={{ width: "100%", height: "85vh" }}
                >
                  <div className="q-scrollarea__container scroll relative-position fit hide-scrollbar">
                    <div className="">
                      {/**/}
                      <div
                        className="text-weight-bold text-primary q-mb-md"
                        style={{ fontSize: 28 }}
                      >
                        {" "}
                        {" "}
                      </div>
                      <div>
                        <div className="row m-0">
                          <div
                            className="col-md-9 col-12 top-card"
                            style={{
                              backgroundColor: "rgb(54, 61, 79)",
                              borderRadius: 8,
                              backgroundImage: "url(/assets/images/home-banner.png)",
                            }}
                          >
                            <div className="h-100 d-flex flex-column justify-content-center">
                              <div>
                                {/**/}
                                <div className="q-mt-lg text-white p-4">
                                  <div className="h2">
                                    {" "}
                                    {t("home_welcome")}{" "}
                                    <span className=" text-weight-bold">
                                      {`${user?.first_name} ${user?.last_name}`}
                                    </span> 
                                    <br />
                                  </div>
                                  <div className="text-primary fs-4">
                                    {t("to_bbm")}
                                  </div>
                                  <div className="text-weight-light text-h6 q-mt-md d-flex flex-column flex-sm-row">
                                    {" "}
                                    {t("account_status_2")} :{" "}
                                    {user?.account_verified ? (
                                      <span className="d-flex" style={{color: "#0866FF"}}>
                                        <span className="ms-2">
                                        {t("account_verified")}{" "}
                                        </span>
                                        <span className="ms-2 my-auto h-fit d-flex">

                                        <LiaCertificateSolid size={14} />
                                        </span>
                                        
                                      </span>
                                    ) : (
                                      <span className="text-negative">
                                        {t("account_not_verified")}{" "}
                                        <div
                                          className="q-img q-img--menu"
                                          role="img"
                                          style={{ width: 25, height: 25 }}
                                        ></div>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-12 bg-negative-">
                            <div className="pie-parent q-mt-md">
                              <div className="pie-center">
                                <div className="q-item__label text-center text-bold">
                                  {t("txt_tpv")}
                                </div>
                                <div className="q-item__label text-primary text-bold text-center text-h6">
                                  {formatCurrency(selectedWallet?.currency) ??
                                    ""}{" "}
                                  {selectedWallet?.balance
                                    ? Number(
                                        selectedWallet?.balance ?? 0.0
                                      ).toFixed(2)
                                    : ""}
                                </div>
                              </div>
                              <div className="q-card q-card--flat no-shadow bg-transparent q-pl-md q-pr-md">
                                <div
                                  className=""
                                  style={{
                                    maxWidth: "100%",
                                    position: "relative",
                                  }}
                                >
                                  <Doughnut data={dataSet} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row mt-3"
                        data-v-8c5a2bc0=""
                      >
                        <div className="news-box col-12" data-v-8c5a2bc0="">
                          <div id="tradingview-snaps" data-v-8c5a2bc0="">
                            <div style={{ width: "100%", height: 430 }}>
                              <style
                                dangerouslySetInnerHTML={{
                                  __html:
                                    "\n\t.tradingview-widget-copyright {\n\t\tfont-size: 13px !important;\n\t\tline-height: 32px !important;\n\t\ttext-align: center !important;\n\t\tvertical-align: middle !important;\n\t\t/* @mixin sf-pro-display-font; */\n\t\tfont-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;\n\t\tcolor: #B2B5BE !important;\n\t}\n\n\t.tradingview-widget-copyright .blue-text {\n\t\tcolor: #2962FF !important;\n\t}\n\n\t.tradingview-widget-copyright a {\n\t\ttext-decoration: none !important;\n\t\tcolor: #B2B5BE !important;\n\t}\n\n\t.tradingview-widget-copyright a:visited {\n\t\tcolor: #B2B5BE !important;\n\t}\n\n\t.tradingview-widget-copyright a:hover .blue-text {\n\t\tcolor: #1E53E5 !important;\n\t}\n\n\t.tradingview-widget-copyright a:active .blue-text {\n\t\tcolor: #1848CC !important;\n\t}\n\n\t.tradingview-widget-copyright a:visited .blue-text {\n\t\tcolor: #2962FF !important;\n\t}\n\t",
                                }}
                              />
                              <iframe
                                id="tradingview-snaps-script"
                                scrolling="no"
                                allowTransparency="true"
                                frameBorder={0}
                                src="https://www.tradingview-widget.com/embed-widget/timeline/?locale=en#%7B%22frameElementId%22%3A%22tradingview-snaps-script%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22compact%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A830%2C%22utm_source%22%3A%22tradingarea.live%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22timeline%22%2C%22page-uri%22%3A%22tradingarea.live%2F%23%2F%22%7D"
                                title="timeline TradingView widget"
                                lang="en"
                                style={{
                                  boxSizing: "border-box",
                                  display: "block",
                                  height: "100%",
                                  width: "100%",
                                  background: "#ffffffc7",
                                  backdropFilter: "blur(10px)",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/**/}
                      </div>
                    </div>
                    {/**/}
                  </div>
                  {/**/}
                  <div
                    className="q-scrollarea__bar q-scrollarea__bar--v absolute-right q-scrollarea__bar--invisible"
                    aria-hidden="true"
                  />
                  <div
                    className="q-scrollarea__bar q-scrollarea__bar--h absolute-bottom q-scrollarea__bar--invisible"
                    aria-hidden="true"
                  />
                  <div
                    className="q-scrollarea__thumb q-scrollarea__thumb--v absolute-right q-scrollarea__thumb--invisible"
                    aria-hidden="true"
                    style={{ top: 0, height: 134 }}
                  />
                  <div
                    className="q-scrollarea__thumb q-scrollarea__thumb--h absolute-bottom q-scrollarea__thumb--invisible"
                    aria-hidden="true"
                    style={{ left: 0, width: 1443 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
}
