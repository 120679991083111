import { PiHandWithdrawThin } from "react-icons/pi";
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import useSWR from "swr";
import { URLS } from "../../../../services/urls/urls";
import { API } from "../../../../services/axios";
import { formatAmount, formatCurrency } from "../../../components/utils/functions";
import { createTransaction } from "../../../../store/effects/transactionsEffect";

const fiat = ["EUR", "USD"];


export default function Withdraw() {


    const [show, setShow] = useState(false);
    const [isCreating, setIsCreating] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [creating, setCreating] = useState(false);
    const { user } = useSelector((state) => state.auth.auth);

    const {data: walletList, isLoading: isWalletListLoading} = useSWR(URLS.USERS.MY_WALLETS(""));

    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <li className="nav-item dropdown notification_dropdown">
                <button className="btn btn-danger" onClick={handleShow}>
                    <span className="me-1"><PiHandWithdrawThin size={24} /></span>
                    {t("menu_withdrawal")}
                </button>
                <Modal
                    show={show}
                    onHide={handleClose}
                    keyboard={false}
                    verticallyCentered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t("menu_withdrawal")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Formik
                            initialValues={{
                                TradeAccount: "",
                                Amount: "",
                                WithdrawalType: "WireTransfer",
                                Beneficiary: "",
                                BankName: "",
                                BankAddress: "",
                                BankAccountNumber: "",
                                SwiftCode: "XXX",
                                WalletAddress: "",
                                Relation: "",
                                Accept: false,
                            }}
                            validate={(values) => {
                                const errors = {};

                                if (!values.email) {
                                    errors.email = "Required";
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = "Invalid email address";
                                }

                                // Wallet amount
                                const amount =
                                walletList?.results.filter((item) => item.id === values.TradeAccount)[0]
                                        ?.balance ?? 0;
                                // Validate form
                                if (
                                    fiat.includes(
                                        walletList?.results.filter((item) => item.id === values.TradeAccount)[0]
                                            ?.currency
                                    )
                                ) {
                                    // Validate for bank wire.
                                    if (
                                        values.Amount &&
                                        values.BankAddress &&
                                        values.Beneficiary &&
                                        values.BankName &&
                                        values.BankAccountNumber
                                        // parseInt(amount) <= parseInt(values.Amount)
                                    ) {
                                        setDisabled(false);
                                    } else {
                                        setDisabled(true);
                                    }
                                } else {
                                    // Validate for crypto transfert
                                    console.log(
                                        (
                                            /([13]|bc1)[A-HJ-NP-Za-km-z1-9]{26,34}/.test(
                                                values.WalletAddress
                                            )
                                        ))
                                    // /([13]|bc1)[A-HJ-NP-Za-km-z1-9]{26,34}/.test(
                                    //   values.WalletAddress
                                    // ) &&
                                    if (
                                        values.Amount &&
                                        amount >= parseInt(values.Amount) &&
                                        values.Accept === true
                                    ) {
                                        setDisabled(false);
                                    } else {
                                        setDisabled(true);
                                    }
                                }

                                // return errors;
                                return {};
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                // dispatch(
                                //   loginAction(values.email, values.password, {
                                //     push: navigate,
                                //   })
                                // );

                                // Create a transaction according to withdrawal type
                                if (
                                    fiat.includes(
                                        walletList?.results.filter((item) => item.id === values.TradeAccount)[0]
                                            ?.currency
                                    )
                                ) {
                                    // Save bank details
                                    setCreating(true);
                                    const bankDetails = {
                                        bank_name: values.BankName,
                                        bank_account_name: values.Beneficiary,
                                        bank_address: values.BankAddress,
                                        account_number: values.BankAccountNumber,
                                        user: user.id,
                                        bank_swift_code: values.SwiftCode,
                                        user: user.id,
                                    };
                                    API.post(URLS.USERS.BANK_DETAILS.CREATE, bankDetails)
                                        .then((response) => {
                                            setCreating(false);
                                            const { id } = response.data;
                                            // Create now the transaction
                                            const data = {
                                                wallet: values.TradeAccount,
                                                user: user.id,
                                                reference: "ref",
                                                amount: values.Amount,
                                                type: "WITHDRAW",
                                                account_details: id,
                                            };
                                            dispatch(createTransaction(data, { push: navigate }, true, t));
                                        })
                                        .catch((error) => {
                                            setCreating(false);
                                            toast.error(error?.response?.data?.detail ?? "")
                                        });
                                } else {
                                    const data = {
                                        wallet: values.TradeAccount,
                                        user: user.id,
                                        reference: "ref",
                                        wallet_address: values.WalletAddress,
                                        amount: values.Amount,
                                        relationship_with_crypto_receiver: values.Relation,
                                        user_accept_risk: values.Accept,
                                        type: "WITHDRAW",
                                    };
                                    dispatch(createTransaction(data, { push: navigate }, true, t));
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <form
                                    className="form px-3"
                                    id="actionForm"
                                    role="form"
                                    novalidate="novalidate"
                                    onSubmit={handleSubmit}
                                >
                                    {" "}
                                    <p>{t("withdrawal_page_description")}</p>
                                    <div className="form-area sel-choice">
                                        <div className="row">
                                            <div className="col-12">
                                                <div>
                                                    <label htmlFor="TradeAccount">
                                                        <span>
                                                            {t("wt_choose_wallet")}: <span className="text-danger">*</span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <div>
                                                    <div className="row-control m-0">
                                                        <div className="formset">
                                                            <div
                                                                className="selector fixedWidth"
                                                                id="uniform-TradeAccount"
                                                            >
                                                                <select
                                                                    id="TradeAccount"
                                                                    value={values.TradeAccount}
                                                                    onChange={handleChange}
                                                                    name="TradeAccount"
                                                                    className="form-control"
                                                                >
                                                                    {walletList?.results.map((wallet) => {
                                                                        return (
                                                                            <option
                                                                                key={wallet.id}
                                                                                value={wallet.id}
                                                                            >{`(${formatCurrency(wallet.currency)}) ${formatAmount(wallet.balance)}`}</option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <span className="error">
                                                            <span
                                                                className="field-validation-valid"
                                                                data-valmsg-for="TradeAccount"
                                                                data-valmsg-replace="true"
                                                            ></span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div>
                                                    <label htmlFor="Amount">
                                                        <span>
                                                            {t("wt_amount")}: <span className="text-danger">*</span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <div>
                                                    <div className="row-control m-0">
                                                        <div className="formset">
                                                            <div className="formitems">
                                                                <div className="formitem col-xs-8">
                                                                    <input
                                                                        id="Amount"
                                                                        name="Amount"
                                                                        type="text"
                                                                        value={values.Amount}
                                                                        onChange={handleChange}
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <span className="error">
                                                                <span className="field-validation-valid"></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {fiat.includes(
                                            walletList?.results.filter((item) => item.id === values.TradeAccount)[0]
                                                ?.currency
                                        ) && (
                                                <div
                                                    className="sel-choice-area form-area"
                                                    id="WireTransfer"
                                                    style={{ display: "block" }}
                                                >
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div>
                                                                <label htmlFor="Beneficiary">
                                                                    <span>
                                                                        {t("wt_beneficiary")}: <span className="text-danger">*</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div>
                                                                <div className="row-control m-0">
                                                                    <div className="formset">
                                                                        <input
                                                                            id="Beneficiary"
                                                                            name="Beneficiary"
                                                                            type="text"
                                                                            value={values.Beneficiary}
                                                                            onChange={handleChange}
                                                                            className="form-control"
                                                                        />
                                                                        <span className="error">
                                                                            <span className="field-validation-valid">
                                                                                {errors.Beneficiary}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div>
                                                                <label htmlFor="BankName">
                                                                    <span>
                                                                        {t("wt_bank_name")}: <span className="text-danger">*</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div>
                                                                <div className="row-control m-0">
                                                                    <div className="formset">
                                                                        <input
                                                                            id="BankName"
                                                                            name="BankName"
                                                                            type="text"
                                                                            value={values.BankName}
                                                                            onChange={handleChange}
                                                                            className="form-control"
                                                                        />
                                                                        <span className="error">
                                                                            <span className="field-validation-valid">
                                                                                {errors.BankName}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div>
                                                                <label htmlFor="BankAddress">
                                                                    <span>
                                                                        {t("wt_bank_address")}: <span className="text-danger">*</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div>
                                                                <div className="row-control m-0">
                                                                    <div className="formset">
                                                                        <input
                                                                            id="BankAddress"
                                                                            name="BankAddress"
                                                                            type="text"
                                                                            value={values.BankAddress}
                                                                            onChange={handleChange}
                                                                            className="form-control"
                                                                        />
                                                                        <span className="error">
                                                                            <span
                                                                                className="field-validation-valid"
                                                                                data-valmsg-for="BankAddress"
                                                                                data-valmsg-replace="true"
                                                                            >
                                                                                {errors.BankAddress}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div>
                                                                <label htmlFor="BankAccountNumber">
                                                                    <span>
                                                                        {t("wt_bank_account_number")}: <span className="text-danger">*</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div>
                                                                <div className="row-control m-0">
                                                                    <div className="formset">
                                                                        <input
                                                                            id="BankAccountNumber"
                                                                            name="BankAccountNumber"
                                                                            type="text"
                                                                            value={values.BankAccountNumber}
                                                                            onChange={handleChange}
                                                                            className="form-control"
                                                                        />
                                                                        <span className="error">
                                                                            <span className="field-validation-valid">
                                                                                {errors.BankAccountNumber}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        {!fiat.includes(
                                            walletList?.results.filter((item) => item.id === values.TradeAccount)[0]
                                                ?.currency
                                        ) && (
                                                <>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div>
                                                                <label htmlFor="CreditCard">
                                                                    <span>
                                                                        {t("wt_wallet_address")}: <span className="text-danger">*</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div>
                                                                <div className="row-control m-0">
                                                                    <div className="formset">
                                                                        <input
                                                                            id="WalletAddress"
                                                                            name="WalletAddress"
                                                                            type="text"
                                                                            value={values.WalletAddress}
                                                                            onChange={handleChange}
                                                                            className="form-control"
                                                                        />
                                                                        <span className="error">
                                                                            <span
                                                                                className="field-validation-valid"
                                                                                data-valmsg-for="CreditCard"
                                                                                data-valmsg-replace="true"
                                                                            >
                                                                                {errors.WalletAddress}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ marginBottom: "2rem" }}>
                                                        <div className="col-12">
                                                            <div>
                                                                <label htmlFor="CreditCard">
                                                                    <span>
                                                                        {t("wt_relation_between")}: <span className="text-danger">*</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2">
                                                            <div>
                                                                <div className="row-control m-0">
                                                                    <div className="formset">
                                                                        <input
                                                                            id="Relation"
                                                                            name="Relation"
                                                                            type="text"
                                                                            value={values.Relation}
                                                                            onChange={handleChange}
                                                                            className="form-control"
                                                                        />
                                                                        <span className="error">
                                                                            <span
                                                                                className="field-validation-valid"
                                                                                data-valmsg-for="CreditCard"
                                                                                data-valmsg-replace="true"
                                                                            >
                                                                                {errors.Relation}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-12 mb-2">
                                                            <div>
                                                                <div
                                                                    className="row-contiSrol"
                                                                    style={{ display: "flex" }}
                                                                >
                                                                    <div className="">
                                                                        <input
                                                                            style={{ marginTop: "1rem" }}
                                                                            id="Accept"
                                                                            name="Accept"
                                                                            type="checkbox"
                                                                            value={values.Accept}
                                                                            onChange={handleChange}
                                                                            className="form-check-input"
                                                                        />
                                                                        <span className="error">
                                                                            <span className="field-validation-valid">
                                                                                {errors.Accept}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <label
                                                                        htmlFor="Accept"
                                                                        style={{ marginLeft: "1rem", width: "fit-content" }}
                                                                    >
                                                                        <span>
                                                                            {t("wt_confirm")}
                                                                            <span className="text-danger">*</span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                    <div className="row ta-center">
                                        <div className="col-12 mb-2">
                                            <div></div>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <div>
                                                <div className="buttonset">
                                                    <Button
                                                        className="button no-border py-3"
                                                        type="submit"
                                                        role={"button"}
                                                        disabled={disabled || isCreating}
                                                    >
                                                        <span>
                                                            {isCreating || creating ? (
                                                                <BeatLoader color="#36d7b7" />
                                                            ) : (
                                                                <span>{t("wt_request_a_withdrawal")}</span>
                                                            )}
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>
            </li>
        </>
    )
}