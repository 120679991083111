export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_TRANSLATOR_FUNCTION = "SET_TRANSLATOR_FUNCTION";

export const changeLanguage = (language) => ({
  type: CHANGE_LANGUAGE,
  language,
});

export const setTranslationFunction = (t) => ({
  type: SET_TRANSLATOR_FUNCTION,
  t,
});
