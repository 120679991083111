import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./style.css";
import Card from "react-bootstrap/Card";
import { Fragment } from "react";
import { COURSES } from "../../../../mock/courses";

export default function VideoAcademy() {
  const { locale } = useSelector((state) => state.i18n);
  const { t } = useTranslation();

  return (
    <Fragment>
      <div
      >
        <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
          <div>
            <h3 className="font-w600 mb-0 me-auto mb-2 text-black">{t("menu_video_academy")}</h3>
            <p> {t("video_academy_description")}</p>
          </div>
        </div>
        <div className=" nobottommargin clearfix">
          <div className="page-content-wrapper">
            <div className="industry-five-slider__area section-space--inner--120  border-bottom">
              
              <section className="">
                <div className="">
                  <div className="">

                    <div className="row">
                      {COURSES.map((course) => {
                        return (
                          <div
                            key={course.title}
                            className="col-md-4 col-sm-6 col-12 mb-4"
                          >
                            <Link to={`/video-academy/course/${course.id}`} className="course-card">
                              <Card>
                                <div
                                  style={{
                                    height: "250px",
                                    backgroundImage: `url(${course.image})`,
                                    width: "100%",
                                    backgroundSize: "80%",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundColor: "rgba(255,255,255,0.7)",
                                  }}
                                ></div>
                                {/* <Card.Img variant="top" src={} height={"200px"} /> */}
                                <Card.Body>
                                  <Card.Title style={{ color: '#000' }}>{t(course.title)}</Card.Title>
                                  <Card.Text style={{ color: 'gray' }}>{course.lessons.length} {t("lessons")}
                                    <span style={{ float: "right" }}>{course.totalTime}</span>
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </section>
              {/* <div className="">
              <div className="row no-gutters">
                <div className="col-lg-12">
                  <div
                    id="education"
                    className="eudcation"
                    style={{ overflow: "auto" }}
                  >
                    <div id="loader" style={{ display: "none" }}></div>
                    <iframe
                      style={{
                        width: "1px",
                        minWidth: "100%",
                        overflow: "hidden",
                        // height: "1084px",/
                        display: "block",
                      }}
                      src={`https://terl3.com//jump5/${
                        locale === "en" ? "gb" : locale
                      }/e3t9LOlZczth4v/open/video`}
                      // scrolling="no"
                      id="iframeducation"
                      allowfullscreen=""
                      width="100%"
                      frameborder="0"
                    ></iframe>
                    <script
                      type="text/javascript"
                      src="https://cdn1.terl3.com/files/assets/js/iframe/iframeResizer.min.js"
                    ></script>
                    <script
                      type="text/javascript"
                      data-css=""
                      data-url="fr.brokeragea.com"
                      id="techeker"
                      data-page="https://fr.brokeragea.com/research/video-academy"
                      data-client="e3t9LOlZczth4v"
                      data-pams="null"
                      src="https://d2ikij6pcyb4st.cloudfront.net/files/assets/js/jquery.urlcheker.js?host=fr.brokeragea.com"
                      async=""
                    ></script>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>{" "}
          <div
            className="cta-area cta-area-bg cta-area-bg--style2 section-space--inner--120 bg-img"
            data-bg="https://d25h7yxntel2g.cloudfront.net/assets/img/backgrounds/15.jpg"
            style={{
              backgroundImage:
                "url('https://d25h7yxntel2g.cloudfront.net/assets/img/backgrounds/15.jpg')",
            }}
          ></div>
        </div>
      </div>
    </Fragment>
  );
}
