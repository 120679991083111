import { PiHandDepositThin } from "react-icons/pi";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DepositMethods from "./components/DepositMethods";
import useSWR from "swr";
import { URLS } from "../../../../services/urls/urls";
import { API } from "../../../../services/axios";
import _ from "lodash";
import { useSelector } from "react-redux";


export default function Deposit() {
    const [show, setShow] = useState(false);
    const [initiatedPayment, setInitiatedPayment] = useState();

    const { data: walletsList, isLoading } = useSWR(URLS.USERS.MY_WALLETS(""));
    const { auth } = useSelector((state) => state.auth);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        handleToggleDepositMethodsModal()
    };

    const { t } = useTranslation();

    const handleToggleDepositMethodsModal = () => {

        if(!show){
    
          // navigate("/deposit-methods");
          let initTransaction = {
            amount: 0.0,
            reference: "REF",
          };
          const userWallets = walletsList?.results;
          console.log("userWallets ", userWallets);

          const defaultCurrency = auth?.user?.default_currency
      
          // The amount will be deposited in user real wallet event if the user make a deposit in crypto.
          const depositWal = _.filter(userWallets, { currency: defaultCurrency });
          initTransaction["wallet"] = depositWal[0]?.id;
      
          const initPayment = window.localStorage.getItem("initPayment");

          if(!initPayment){
            API.post(URLS.TRANSACTIONS.INIT, initTransaction)
                .then((resp) => {
                  window.localStorage.setItem("initPayment", JSON.stringify(resp.data));
                  setInitiatedPayment(resp.data);
                  setShow(true)
                })
                .catch((error) => {});
            } else {
                const initiatedPayment = JSON.parse(window.localStorage.getItem("initPayment"));
                setShow(true);
                setInitiatedPayment(initiatedPayment);
            }
        } else {
        }
      
    
    };

    return (
        <>
            <li className="nav-item dropdown notification_dropdown">
                <button className="btn btn-success" onClick={handleShow}>
                    <span className="me-1"><PiHandDepositThin size={24} /></span>
                    {t("txt_md")}
                </button>
                <Modal show={show} onHide={handleClose} size="lg" scrollable={true} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("txt_md")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DepositMethods initiatedPayment={initiatedPayment}/>
                    </Modal.Body>
                </Modal>
            </li>
        </>
    )
}