export default {
  translation: {
    "shared.french": "Französisch",
    "shared.english": "Englisch",
    "shared.spanish": "Spanish",
    "shared.italian": "Italian",
    "shared.norvegian": "Norvegian",
    "shared.portugues": "Portugues",
    "shared.sweden": "Sweden",
    "shared.deutsch": "Deutsch",
    home: "zu Hause",
    home_welcome: "Willkommen",
    home_you_can:
      "Sie können jetzt ein echtes Konto verwenden, um Forex, CFDs, Rohstoffe, Aktien und Indizes auf mehreren Plattformen (Web & Mobile) zu handeln. Vielen Dank, dass Sie sich für uns entschieden haben",
    menu_contact_us: "Kontaktieren uns",
    menu_my_account: "Mein Konto",
    menu_logout: "Abmelden",
    menu_personal_details: "Persönliche Daten",
    menu_change_password: "Passwort ändern",
    menu_trading_history: "Handelshistorie",
    menu_upload_document: "Dokumente hochladen",
    menu_monetary_transaction: "Geldtransaktionen",
    menu_wallet: "Brieftaschen",
    menu_withdrawal: "Auszahlung",
    pd_first_name: "Vorname",
    pd_last_name: "Nachname",
    pd_email: "E-Mail",
    pd_phone_number: "Telefonnummer",
    pd_country: "Land",
    pd_edit: "Bearbeiten",
    pd_city: "Stadt",
    pd_post_code: "Postleitzahl",
    form_save: "Speichern",
    edit_account: "Konto bearbeiten",
    field_mandatory: "Alle mit * gekennzeichneten Felder sind Pflichtfelder",
    change_password: "Passwort ändern",
    old_password: "Altes Passwort",
    pw_weak: "Schwach",
    pw_medium: "Mittel",
    pw_strong: "Stark",
    pw_confirm_pass: "Passwort bestätigen",
    pw_set_pass: "Passwort bestätigen",
    form_submit: "Senden",
    pw_new_password: "Neues Passwort",
    table_display: "Anzeige",
    display_record: "Aufzeichnungen",
    table_empty: "Keine Daten in Tabelle verfügbar",
    th_symbol: "Symbol",
    th_quantity: "Menge",
    th_open_date: "Eröffnungsdatum",
    th_close_date: "Schlusstermin",
    th_open_price: "Eröffnungspreis",
    th_close_price: "Schlusskurs",
    th_position_type: "Kaufen/Verkaufen",
    th_comments: "Kommentare",
    th_profit_lost: "Gewinn / Verlust",
    upload_documents: "Dokumente hochladen",
    ud_upload_type: "Upload-Typ",
    ud_upload_date: "Upload-Datum",
    select_choose_option: "Wähle eine Option",
    ft_bank_file: "Datei",
    ft_bank_wire: "Banküberweisung",
    ft_credit_card_front: "Kreditkarten-Vorderseite",
    ft_driving_license: "Führerschein",
    ft_national_id_card: "Personalausweis",
    ft_passport_id: "Pass-ID",
    ft_utility_bill: "Stromrechnung",
    ft_dod: "DOD",
    ft_por: "POR",
    ft_id: "ID",
    ft_wire_details: "Kabeldetails",
    ft_wd_request_form: "WD-Anfrageformular",
    ft_credit_card_back: "Rückseite der Kreditkarte",
    ft_other_doc_type: "Anderer Dokumenttyp",
    file_uploaded: "Hochgeladene Dateien",
    wallet_details: "Wallet-Details",
    w_currency: "Währung",
    w_amount: "Betrag",
    w_status: "Status",
    w_status_valid: "GÜLTIG",
    w_status_invalid: "UNGÜLTIG",
    w_currency: "Einzahlungsadresse",
    w_transactions_history: "Transaktionsverlauf",
    w_currency: "Währung",
    wd_reference: "Referenz",
    wd_transaction_type: "Transaktionstyp",
    wd_amount: "Betrag",
    wd_wallet: "Brieftasche",
    wd_status: "Status",
    wd_operation_date: "Operationsdatum",
    wd_note: "Hinweis",
    wallet_list: "Wallet-Liste",
    new_wallet: "Neue Brieftasche",
    create_wallet_description:
      "Geben Sie Ihre Wallet-Informationen ein, um sie zu erstellen.",
    choose_currency: "Währung wählen",
    payment_page_description: `Indem Sie auf "Auszahlung anfordern" klicken, werden wir darüber informiert
     Sie möchten Geld abheben. Das Geld wird zurückerstattet
     Ihre Kredit- oder Debitkarte zur Finanzierung.`,
    wt_choose_wallet: "Wallet auswählen",
    wt_amount: "Betrag",
    wt_beneficiary: "Begünstigter",
    wt_bank_name: "Bankname",
    wt_bank_address: "Bankadresse",
    wt_bank_account_number: "Bankkontonummer",
    wt_swift_code: "Swift-Code",
    wt_wallet_address: "Wallet-Adresse",
    wt_relation_between: "Beziehung mit dem Empfänger",
    wt_confirm:
      "Da mir bewusst ist, dass Kryptowährungsransaktionen nicht rückgängig und stoniertbar sind, genehmige ich diese Transaktion, indem ich die bereitgestellten Daten validiere.",
    wt_request_a_withdrawal: "Fordere eine Auszahlung an",
    l_loading: "Laden",
    login_to_start: "Melden Sie sich an, mit dem Handel sofort zu beginnen",
    login_to_start_description: `Sie können sich jetzt anmelden, um mit dem Trading auf a zu beginnen
     KOSTENLOSES Demokonto. Sobald Sie genug Selbstvertrauen aufgebaut haben,
     Sie können mit dem echten Handel beginnen. Plattform testen, finden
     Ihre Herangehensweise und legen Sie los.`,
    dont_have_account: "Habe kein Konto",
    register_here: "hier registrieren",
    login_password: "Passwort",
    login_email: "E-Mail",
    login_button: "LOGIN",
    live_registration: "Live-Registrierung",
    personal_informations: "Persönliche Informationen",
    login_information: "Anmeldeinformationen",
    login_i_have_read: "Ich habe gelesen, verstanden und akzeptiere die",
    term_and_conditions: "Allgemeine Geschäftsbedingungen",
    register_now: "JETZT REGISTRIEREN",
    login_now: "Jetzt anmelden",
    bereits_have_ccount: "Habe bereits ein Konto",
    edit_profile_language: "Sprache",
    edit_profile_gender: "Geschlecht",
    edit_profile_gender_male: "Männlich",
    edit_profile_gender_female: "Weiblich",
    edit_profile_birth_day: "Geburtsdatum",
    edit_profile_address: "Adresse",
    now_have_ccount:
      "Passwortänderung fehlgeschlagen, bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.",
    closed_order: "Geschlossene Positionen",
    open_order: "Geöffnete Positionen",
    menu_video_academy: "Video akademie",
    w_deposit_address: "Wallet-Einzahlungsadresse",
    button_deposit_text: "Einzahlung",
    button_deposit_description: "Einzahlungsinformationen.",
    qr_code: "QR-Code",
    wallet_details_overview: "Übersicht",
    wt_wallet_address_type: "Wallet-Typ",
    wallet_details_details: "Details",
    no_email_validation:
      "Kein E-Mail-Bestätigungsschlüssel, bitte versuchen Sie es später noch einmal.",
    email_address_validated:
      "Ihre E-Mail-Adresse wurde erfolgreich verifiziert, Sie können sich jetzt anmelden",
    email_address_validation_error:
      "Ups, beim Verifizieren Ihrer E-Mail-Adresse ist ein Fehler aufgetreten. Bitte aktualisieren Sie die Seite oder versuchen Sie es später noch einmal.",
    email_verification_pending: "Bestätige deine E-Mail-Adresse ...",
    w_deposit_iban: "IBAN",
    deposit_infos: "Einzahlungsinformationen",
    new_wallet_generate_address: "Adresse generieren.",
    new_wallet_success: "Wallet erfolgreich erstellt",
    new_wallet_error: "Wallet-Erstellungsfehler",
    menu_swap_transfert: "Übertragung tauschen",
    transfert_money: "Geld überweisen",
    transfert_money_between_wallets: "Geld zwischen Geldbörsen übertragen",
    wallet_to_withdraw: "Wallet zu Lastschrift",
    wallet_to_send_to: "Geldbörse auf Kredit",
    transfert_amount: "Überweisungsbetrag",
    swap_transfert_success: "Guthaben erfolgreich überwiesen",
    swap_transfert_error:
      "Beim Geldtransfer ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.",
    swap_transfert_cannot_process:
      "Wir können die Übertragung nicht verarbeiten, bitte füllen Sie alle erforderlichen Informationen aus und versuchen Sie es erneut.",
    transfert_amount: "Betrag",
    transfert_execute: "Ausführen",
    return_to_courses: "Zurück zu den Kursen",
    crypto_trading: "Kryptohandel",
    crypto_trading_description:
      "In diesem Kurs lernen Sie alle Grundlagen, um mit dem Handel in der Welt der Kryptowährungen zu beginnen.",
    introduction_and_history: "Einführung und Geschichte",
    blockchain_and_descentralisation: "Blockchain und Dezentralisierung",
    major_cryptocurencies: "Hauptkryptowährungen",
    other_crypto_and_stable_coin: "Andere Kryptos und Stablecoins",
    nft: "NFT",
    market_actors: "Marktakteure",
    key_factors: "Schlüsseltreiber des Kryptomarktes",
    trading_tools: "Krypto-Trading-Tools",
    psychologie_of_trading_and_risk_management:
      "Krypto-Trading-Psychologie und Risikomanagement",
    trade_cryptos: "Wie man Krypto handelt",
    conclusion: "Fazit",
    market_analysis: "Marktanalyse",
    market_analysis_description:
      "Dieser Marktanalysekurs ist für diejenigen gedacht, die ihren Handelsvorteil ausbauen und ihre fundamentalen und technischen Analysefähigkeiten schärfen möchten, um bessere Handelsentscheidungen zu treffen.",
    introduction: "Einführung",
    economic_analyse: "Wirtschaftsanalyse",
    informations_and_trading_events: "Handelsnachrichten und -ereignisse",
    trading_events: "Handelsereignisse",
    key_economic_data: "Wirtschaftliche Eckdaten",
    technical_analyse: "Technische Analyse",
    support_and_resistance: "Unterstützung und Widerstand",
    trends: "Trends",
    hauss_trends: "Aufwärtstrends",
    bass_trends: "Abwärtstrends",
    course_resume: "Kurszusammenfassung",
    trading_psychology: "Handelspsychologie",
    trading_psychology_description:
      "In diesem bahnbrechenden Kurs lernen Sie, wie Sie den größten Feind des Tradings überwinden: Emotionen! Versetzen Sie sich in die Denkweise eines erfolgreichen Traders, bauen Sie Vertrauen in Ihr Trading auf.",
    introduction: "Einführung",
    fear_of_lost: "Verlustangst",
    surmont_the_fear_of_lost: "Verlustangst überwinden",
    gourmandise: "Gier",
    surmont_gourmandise: "Gier überwinden",
    positions_management: "Positionsverwaltung",
    solutions_: "Lösungen",
    risk_management: "Risikomanagement",
    financial_management_solutions: "Geldverwaltungslösungen",
    course_resume: "Kurszusammenfassung",
    forex_strategy: "Handelsstrategien",
    forex_strategy_description:
      "Dieser Kurs wird eine breite Palette von On-Demand-Forex-Handelsstrategien für alle Arten von Händlern enthüllen",
    day_trading: "Tageshandel",
    news_trading: "Nachrichtenhandel",
    carry_trading: "Carry-Trading",
    binary_trends_trading: "Trading von Trends",
    binary_swing_trading: "Swing-Trading",
    chart_level_trading_of_binaries_option: "Handel auf Chartebene",
    chartists_classics_figures: "Trading mit klassischen Chartmustern",
    technical_indicators_trading: "Handel mit technischen Indikatoren",
    course_resume: "Zusammenfassung",
    trading_sociology: "Social Trading",
    trading_sociology_description:
      "Social Trading ist eine Art des Handels, bei der Anleger die Handelsstrategien anderer Händler beobachten können. Werden Sie zum Kopierer und replizieren Sie die Geschäfte des Anbieters, wobei ein Anbieter ein erfahrener Händler ist, der seine Anlagestrategien teilt.",
    what_interest: "Warum Social Trading?",
    how_to_process: "Anbieter auswählen",
    benefit_and_inconvenients: "Vor- und Nachteile",
    course_resume: "Zusammenfassung",
    technical_indicators: "Technische Indikatoren",
    technical_indicators_description:
      "Die gebräuchlichsten Methoden zur Analyse von Finanzmarktindikatoren, Candlestick-Charts und mehr.",
    graphiques_en_chandeliers: "Candlestick Charting",
    figuren_des_chandeliers_part_1: "Kerzenmuster Teil 1",
    figuren_des_chandeliers_part_2: "Kerzenmuster Teil 2",
    supports_et_resistances: "Unterstützung und Widerstand",
    moyenne_mobile: "Gleitender Durchschnitt",
    bandes_de_bollinger: "Bollinger Bänder",
    retracements_de_fibonacci: "Fibonacci-Retracements",
    indice_de_force_relative_ou_rsi: "Index der relativen Stärke",
    diversification_and_timing: "Diversifikation und Timing",
    video_academy: "Video akademie",
    video_academy_description: "Hier finden Sie Kurse mit Videolektionen.",
    video_academy_courses: "Kurse",
    active_orders: "Aktive Bestellungen",
    closed_orders: "Geschlossene Bestellungen",
    wt_balance: "Guthaben",
    w_volume: "Lautstärke",
    accept_im_major_and_not_living_in_usa:
      "Ich bestätige, dass ich volljährig bin und kein Bürger der USA bin.",
    accept_general_risk: `und ich stimme der <a href="https://StockBridge Capitaltrade.com/legalcontent/General_risk_disclosure.pdf" target="_blank"class="text-primary">allgemeinen Risikooffenlegung</a> zu ,`,
    btn_continue: "Weiter",
     Einzahlungsbetrag_Fehler:
       "Der Betrag darf nicht mehr als 5000 betragen, bitte kontaktieren Sie einen Account Manager für eine Einzahlung über 5000",
     select_a_payment_method: "Zahlungsmethode auswählen",
     btn_previous: "Zurück",
     send_the_exact_amount_of:
       "Um Ihre Einzahlung zu tätigen, senden Sie den genauen Betrag von",
     to_this_wallet: "An folgende Adresse",
     or_scan_the_qrcode: "Oder QR-Code scannen",
     timeleft_before_deposit: "Verbleibende Zeit bis zur Einzahlung: ",
     btn_return_on_app: "Zurück zur App.",
     deposit_important: "Wichtig",
     deposit_select_coin: "Währung auswählen",
     deposit_deposit_to: "Einzahlen auf",
     deposit_send_only: `Sende nur {{currency}} an
      diese Depotadresse. Senden einer anderen Währung
      an diese Adresse kann zum Verlust Ihrer E-Mail führen
      Kaution.`,
     deposit_ensure_network_is: "Stellen Sie sicher, dass das Netzwerk aktiv ist",
     deposit_deposit_address: "Einzahlungsadresse",
     deposit_show_qr: "QR-Code anzeigen",
     deposit_copy_qr: "Adresse kopieren",
     btn_copy_text: "Kopieren",
     deposit_tips: "Tipps",
     deposit_tips_1:
       "Hinweis: Coins werden sofort nach Bestätigung von Netzwerk 2 hinterlegt",
     deposit_track_progress:
       "Nachdem Sie eine Einzahlung getätigt haben, können Sie Ihren Fortschritt verfolgen",
     deposit_history_page: "Verlaufsseite.",
     deposit_enter_card_details:
       "Bitte geben Sie Ihre Kartendaten ein, um zu bezahlen.",
      btn_copy_text: "Kopieren",
     payment_view_trans_id: "Transaktions-ID",
     you_also_have_create: "Du musst auch öffnen",
     a_ticket: "Ein Ticket.",
     menu_tickets: "Support-Tickets",
     new_tickets: "Neues Ticket",
     ticket_subject: "Betreff",
     ticket_status: "Status",
     ticket_last_updated: "Zuletzt aktualisiert",
     ticket_details: "Ticketdetails",
     ticket_view: "Ticketansicht",
     posted_on: "an",
     posted_by: "Gepostet von",
     ticket_replies: "Antworten",
     ticket_reply: "Antworten",
     notification_title: "Benachrichtigung",
     notification_empty: "Du hast keine Benachrichtigungen",
     usr_request_call: "Anrufanfrage",
     usr_request_call_severity: "Schweregrad",
     usr_request_call_date: "Gewünschtes Anrufdatum",
     usr_request_call_time: "Gewünschte Anrufzeit",
     usr_request_call_subject: "Anrufbetreff",
     usr_request_call_severity_normal: "Normal",
     usr_request_call_severity_urgent: "Dringend",
     usr_request_call_severity_very_u: "Sehr dringend",
     usr_request_call_action_btn: "Anruf anfordern",
     usr_request_call_request_sended:"Anrufanfrage wurde empfangen, wir rufen Sie zur angegebenen Zeit an.",
     password_reset_desc: "Bitte geben Sie Ihre E-Mail-Adresse ein, um das Passwort zurückzusetzen",
     password_reset_action_btn: "Passwort zurücksetzen",
     password_recovery_action_btn: "Passwort wiederherstellen",
     password_reset_reset: "Geben Sie Ihr neues Passwort ein und bestätigen Sie es",
     deposit_card_holder: "Name des Karteninhabers",
     deposit_hide_qr: "QR-Code ausblenden",
     deposit_send_within: "Sende nur {{currency}} auf dieser Brieftasche innerhalb von {{timeout}}",
     TR_PENDING: "Ausstehend",
     TR_CANCELED: "Abgelehnt",
     TR_VALIDATED: "Validiert",
     TR_AWAITING_FOR_APPROVAL: "Warten auf Genehmigung",
     TR_ON_HOLD: "Halten",
     menu_upload_document_to_verify_identity: "Laden Sie Ihre Dokumente hoch, um Ihre Identität zu überprüfen.",
     ft_tin: "TIN",
     menu_upload_document_to_verify_identity_success: "Wir haben Ihre Dokumente erhalten, wir werden sie prüfen und Ihr Konto aktivieren, wenn sie verifiziert sind.",
     upload_failed_file_too_large: "Entschuldigung, die Größe der von Ihnen hochgeladenen Datei beträgt mehr als 2 MB, und das ist nicht zulässig.",
     text_copied_success: "Text kopiert!",
     ft_national_id_card_front: "Vorderseite des Personalausweises",
     ft_national_id_card_back: "Rückseite des Personalausweises",
     ft_upload_national_id_card_back: "Ausweis zurück hochladen",
     ft_upload_national_id_card_front: "Vorderseite des Personalausweises hochladen",
     account_status: "Kontobestätigung",
     account_status_pending: "Die Überprüfung Ihrer Informationen ist im Gange.",
     account_status_pending_text: "Unser Verifizierungsteam hat Ihre Dokumente erhalten und die Verifizierung läuft. Sobald Ihre Dokumente genehmigt wurden, erhalten Sie eine E-Mail. Anschließend können Sie sich anmelden und mit der Tätigung Ihrer Investitionen beginnen.",
     account_status_pending_signature: "Mit freundlichen Grüßen, das Verifizierungsteam der Black Bulls-Märkte",
     account_status_pending_nb: "Hinweis: Aufgrund der großen Anzahl der eingereichten Verifizierungsanfragen kann die Validierung Ihrer Dokumente zwischen 30 Minuten und 24 Stunden dauern. Vielen Dank für Ihre Geduld und bis bald bei Ihrer ersten Investition.",
     payment_page_description: `Durch Klicken auf "Auszahlung anfordern" werden wir darüber informiert
     Sie möchten Geld abheben. Das Geld wird an zurückerstattet
     Ihre Kredit- oder Debitkarte für die Finanzierung.`,
     dashboard_login_wtrader: "Bei Web Trader anmelden",
     transaction_create_success: "Transaktion erfolgreich erstellt!",
     transaction_create_error_message: "Fehler bei der Transaktionserstellung!! Bitte versuchen Sie es später",
     transaction_loading_error_message: "Fehler beim Laden der Transaktion!! Bitte versuchen Sie es später",
     files_loading_error_message: "Laden der Datei fehlgeschlagen!",
     files_upload_success_message: "Datei erfolgreich hochgeladen",
     files_upload_error_message: "Datei-Upload fehlgeschlagen. Bitte versuchen Sie es erneut",
     wallets_creation_error_message: "Wallet erfolgreich erstellt!",
     wallets_creation_success_message: "Wallet-Erstellung fehlgeschlagen!",
     wallets_loading_error_message: "Ladefehler!",
     wallets_loading_success_message: "Wallet-Erstellung fehlgeschlagen!",
     text_no_eth_wallet_for_swap: "Es tut uns leid, aber Sie haben kein Ethereum-Wallet, um Netzwerkgebühren für diese Swap-Transaktion zu bezahlen.",
      text_cannot_generate_eth_wallet: "Sie können kein ETH-Wallet generieren. Bitte aktualisieren Sie Ihren Kontotyp und versuchen Sie es erneut.",
      to_bbm: "zum StockBridge Capital TRADE",
     account_status_2: "Statut du compte",
     account_verified: "Verifiziert",
     account_not_verified: "Nicht verifiziert",
     date_registered: "Datum der Registrierung",
     txt_wm: "Auszahlungsmethoden",
     txt_cw:"Auszahlung fortsetzen",
     txt_tr:"HANDELSRAUM",
     txt_ob:"BUCH BESTELLEN",
     txt_wl:"Beobachtungsliste",
     txt_ar:"Warnungen und Berichte",
     txt_fh:"Finanzgeschichte",
     txt_dashboard:"Dashboard",
     txt_tpv:"Gesamtwert des Portfolios",
     txt_trader_room:"Händlerraum",
     txt_ar2:"Kontobericht",
     txt_ab:"Verfügbares Guthaben",
     txt_e_pnl:"Geschätzter PNL",
     txt_td:"Gesamteinzahlung",
     txt_taw:"Gesamtzahl der genehmigten Abhebungen",
     txt_p_bal:"Plansaldo",
     txt_d:"Datum",
     txt_ad: "Meine Kontodaten",
     txt_ud:"Hochgeladene Dokumente",
     txt_mt:"Meine Tickets",
     txt_up:"Passwort aktualisieren",
     txt_w:"Auszahlungen",
     txt_s_n:"S/N",
     txt_pm:"Zahlungsmethode",
     txt_at:"Alle Transaktionen",
     txt_w_l:"Wunschliste",
     txt_sa:"Assets suchen",
     txt_ass:"Vermögenswerte",
     txt_sell:"VERKAUFEN",
     txt_buy:"KAUFEN",
     txt_spread:"Spread",
     txt_actions:"Aktionen",
     txt_:"",
     menu_withdraw: "Zurückziehen",
     txt_login_welcome: "Willkommen bei <strong>StockBridge Capital</strong>",
    txt_sign_in: "Anmelden",
    txt_sign_up: "Anmelden",
    txt_to_access: "um auf Ihr Konto zuzugreifen",
    txt_to_open: "Konto eröffnen",
    txt_forgot_password: "Passwort vergessen?",
    txt_not_client: "Noch kein StockBridge Capital <br />-Client?",
    txt_open_account: "Konto eröffnen",
    txt_main_page: "StockBridge Capital-Hauptseite",
    txt_contact_us_at: "Kontaktieren Sie uns unter",
    txt_if_have_pb: "Wenn Sie Probleme haben",
    txt_footer: "Der Handel mit CFDs birgt ein erhebliches Verlustrisiko. Lesen Sie StockBridge Capital's",
    txt_risk: "‚Risikooffenlegungserklärung‘.",
    txt_not_membber: 'Kein Mitglied?</span> <b className="ng-star-inserted">Jetzt anmelden!',
    txt_already_member: "<span>Bereits Mitglied?</span> <b>Jetzt anmelden!</b>",
    txt_f: "",
  },
};
