



export const ETH_BASED_CURRENCIES = [
    "USDT",
    "ETH",
]

export const ETH_BASED_NETWORK = [
    "ERC20",
    "ERC 20",
]

export const paymentMethods = [
  {
    image: "/assets/images/payment/new-bank-card.png",
    favicon: "/assets/images/payment/new-bank-card.png",
    value: "bankCards",
  },
  {
    image: "/assets/images/payment/paypal-logo.png",
    favicon: "/assets/images/payment/paypal-logo.png",
    value: "paypal",
  },
  // {
  //   image: "/assets/images/payment/perfectmoney.png",
  //   value: "perfectMoney",
  // },
  {
    image: "/assets/images/payment/bank-transfert.png",
    value: "bank-transfert",
    favicon: "/assets/images/payment/bank-favicon.png",
    shortName: "Bank",
    fullName: "Transfert",
  },
  {
    image: "/assets/images/payment/bitcoin.svg",
    value: "btc",
    favicon: "/assets/images/payment/btc-fav.png",
    shortName: "BTC",
    fullName: "Bitcoin",
  },
  {
    image: "/assets/images/payment/ethereum.png",
    value: "eth",
    favicon: "/assets/images/payment/eth-fav.png",
    shortName: "ETH",
    fullName: "Ethereum",
  },
  {
    image: "/assets/images/payment/usdt.png",
    value: "usdt",
    favicon: "/assets/images/payment/usdt-fav.png",
    shortName: "USDT",
    fullName: "Tether",
  },
  // {
  //   image: "/assets/images/payment/usdt.png",
  //   value: "usdt-trc-20",
  // }
];