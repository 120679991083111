

export default {
  translation: {
     "shared.french": "Franska",
     "shared.english": "Engelska",
     "shared.spanish": "Spanish",
     "shared.italian": "Italian",
     "shared.norvegian": "Norvegian",
     "shared.portugues": "Portugues",
     "shared.sweden": "Sweden",
     "shared.deutsch": "Deutsch",
     home: "Hem",
     home_welcome: "Välkommen",
     home_you_can: "Du kan nu använda riktiga konton för att handla Forex, CFD:er, råvaror, aktier, index över flera plattformar (webb och mobil). Tack för att du valde oss",
     menu_contact_us: "Kontakta oss",
     menu_my_account:"Mitt konto",
     menu_logout: "Logga ut",
     menu_personal_details: "Personliga uppgifter",
     menu_change_password: "Ändra lösenord",
     menu_trading_history: "Handelshistorik",
     menu_upload_document: "Ladda upp dokument",
     menu_monetary_transaction: "Monetära transaktioner",
     menu_wallet: "Plånböcker",
     menu_withdrawal: "Uttag",
     pd_first_name: "Förnamn",
     pd_last_name: "Efternamn",
     pd_email: "E-post",
     pd_phone_number: "Telefonnummer",
     pd_country: "Land",
     pd_edit: "Redigera",
     pd_city: "Stad",
     pd_post_code: "Postnummer",
     form_save: "Spara",
     edit_account: "Redigera konto",
     field_mandatory: "Alla fält markerade som *är obligatoriska",
     change_password: "Ändra lösenord",
     old_password: "Gammalt lösenord",
     pw_weak: "Svag",
     pw_medium: "Medium",
     pw_strong: "Stark",
     pw_confirm_pass: "Bekräfta lösenord",
     pw_set_pass: "Bekräfta lösenord",
     form_submit: "Skicka",
     pw_new_password: "Nytt lösenord",
     table_display: "Display",
     display_record: "rekord",
     table_empty: "Ingen data tillgänglig i tabellen",
     th_symbol: "Symbol",
     th_quantity: "Quantity",
     th_open_date: "Öppningsdatum",
     th_close_date: "Stängningsdatum",
     th_open_price: "Öppet pris",
     th_close_price: "Stäng pris",
     th_position_type: "Köp/sälj",
     th_comments: "Kommets",
     th_profit_lost:"Vinst / förlorad",
     upload_documents: "Ladda upp dokument",
     ud_upload_type: "Uppladdningstyp",
     ud_upload_date: "Uppladdningsdatum",
     select_choose_option: "Välj ett alternativ",
     ft_bank_file: "Fil",
     ft_bank_statement: "Kontoutdrag",
     ft_other: "Annan",
     agree_im_major_and_not_living_in_usa: "Jag bekräftar att jag är myndig och att jag inte är medborgare i USA.",
     accept_general_risk: `och jag håller med <a href="https://StockBridge Capitaltrade.com/legalcontent/General_risk_disclosure.pdf" target="_blank"class="text-primary">allmän riskupplysning</a>` ,
     ft_bank_wire: "Banköverföring",
     ft_credit_card_front: "Kreditkorts framsida",
     ft_driving_license: "Körkort",
     ft_national_id_card: "Nationellt ID-kort",
     ft_passport_id: "Pass-ID",
     ft_utility_bill: "Utility Bill",
     ft_dod: "DOD",
     ft_por: "POR",
     ft_id: "ID",
     ft_wire_details: "Wire Details",
     ft_wd_request_form: "WD Request Form",
     ft_credit_card_back: "Kreditkort tillbaka",
     ft_other_doc_type: "Annan dokumenttyp",
     file_uploaded: "Filer uppladdade",
     wallet_details: "Information om plånboken",
     w_currency:"Valuta",
     w_amount:"Belopp",
     w_status:"Status",
     w_status_valid:"VALID",
     w_status_invalid:"INVALID",
     w_currency:"Insättningsadress",
     w_transactions_history:"Transaktionshistorik",
     w_currency:"Valuta",
     wd_reference:"Referens",
     wd_transaction_type:"Transaktionstyp",
     wd_amount:"Belopp",
     wd_wallet:"Plånbok",
     wd_status:"Status",
     wd_operation_date:"Operation Date",
     wd_note: "Obs",
     wallet_list: "Plånbokslista",
     new_wallet: "Ny plånbok",
     create_wallet_description:"Sätt in din plånboksinformation för att skapa.",
     select_currency: "Välj valuta",
     withdrawal_page_description: `Genom att klicka på "Begär ett uttag" kommer vi att informeras om att
     du vill ta ut pengar. Pengarna kommer att återbetalas till
     ditt finansieringskredit- eller betalkort.`,
     wt_choose_wallet: "Välj en plånbok",
     wt_amount: "Belopp",
     wt_beneficiary: "Förmånstagare",
     wt_bank_name: "Bankens namn",
     wt_bank_address: "Bankadress",
     wt_bank_account_number: "Bankkontonummer",
     wt_swift_code: "Swift-kod",
     wt_wallet_address: "Plånboksadress",
     wt_relation_between: "Relation mellan dig och mottagaren",
     wt_confirm: "Eftersom jag inser att transaktioner med kryptoplånbok är riskabla, godkänner jag den här transaktionen genom att validera de angivna uppgifterna.",
     wt_request_a_withdrawal: "Begär ett uttag",
     l_loading: "Laddar",
     login_to_start: "Logga in för att börja handla just nu",
     login_to_start_description: `Du kan registrera dig just nu för att börja öva handel på en
     GRATIS demokonto. När du väl har byggt upp tillräckligt med självförtroende,
     du kan börja handla på riktigt. Testa plattformen, hitta
     ditt tillvägagångssätt och kom igång.`,
     dont_have_account:"Har inget konto",
     register_here: "Registrera dig här",
     login_password :"Lösenord",
     login_email :"E-post",
     login_button :"LOGGA IN",
     live_registration: "Live Registration",
     personal_informations: "Personlig information",
     login_information: "Inloggningsinformation",
     login_i_have_read:"Jag har läst, förstått och accepterar",
     term_and_conditions: "Användarvillkor",
     register_now: "REGISTRERA NU",
     login_now: "Logga in nu",
     already_have_ccount: "Har redan ett konto",
     edit_profile_language: "Språk",
     edit_profile_gender: "Kön",
     edit_profile_gender_male: "Man",
     edit_profile_gender_female: "Kvinna",
     edit_profile_birth_day: "Födelsedatum",
     edit_profile_address: "Adress",
     te_pw_failed_message: "Lösenordsändring misslyckades, verifiera din inmatning och försök igen.",
     closed_order: "Stängda positioner",
     opened_order: "Öppnade positioner",
     menu_video_academy: "Video akademi",
     w_deposit_address: "Plånboksinsättningsadress",
     button_deposit_text: "Insättning",
     button_deposit_description: "Insättningsinformation.",
     qr_code: "QR-kod",
     wallet_details_overview: "Översikt",
     wt_wallet_address_type: "Plånbokstyp",
     wallet_details_details: "Detaljer",
     "no_email_validation": "Ingen e-postbekräftelsenyckel, försök igen senare.",
     email_address_validated: 'Din e-postadress har verifierats, du kan logga in nu',
     email_address_validation_error: 'Hoppsan ett fel inträffade när du verifierade din e-postadress. Försök att uppdatera sidan eller försök senare.',
     email_verification_pending: "Verifierar din e-postadress ...",
     w_deposit_iban: "IBAN",
     deposit_infos: "Insättningsinformation",
     new_wallet_generate_address: "Gerar endereço.",
     new_wallet_success: "Carteira criada com sucesso",
     new_wallet_error: "Erro ao criar carteira",
     menu_swap_transfert: "Trocar transferência",
     transfert_money: "Transferir dinheiro",
     transfert_money_between_wallets: "Transferir dinheiro entre carteiras",
     wallet_to_withdraw: "Carteira para débito",
     wallet_to_send_to: "Carteira para crédito",
     transfert_amount: "Valor da transferência",
     swap_transfert_success: "Fundos transferidos com sucesso",
     swap_transfert_error: "Ocorreu um erro durante a transferência de fundos, tente mais tarde.",
     swap_transfert_cannot_process: "Não foi possível processar a transferência, por favor preencha todas as informações necessárias e tente novamente.",
     transfert_amount: "Valor",
     transfert_execute: "Executar",
     return_to_courses: "Retornar aos cursos",
     crypto_trading: "Negociação de criptomoedas",
     crypto_trading_description: "Neste curso, você aprenderá todos os fundamentos para começar a negociar no mundo das criptomoedas.",
     introduction_and_history: "Introdução e história",
     blockchain_and_descentralisation: "Blockchain e descentralização",
     major_cryptocurencies: "Principais criptomoedas",
     other_crypto_and_stable_coin: "Outras criptomoedas e stablecoins",
     nft: "NFT",
     market_actors: "Atores do mercado",
     key_factors: "Principais impulsionadores do mercado de criptomoedas",
     trading_tools: "Ferramentas de negociação de criptografia",
     psychologie_of_trading_and_risk_management: "Psicologia de negociação de criptografia e gerenciamento de risco",
     trade_cryptos: "Como negociar criptomoedas",
     conclusion: "Conclusão",
     market_analysis: "Análise de mercado",
     market_analysis_description: "Este curso de Análise de Mercado destina-se àqueles que desejam desenvolver sua vantagem comercial e aprimorar suas habilidades de análise fundamental e técnica para tomar melhores decisões comerciais.",
     introduction: "Introdução",
     economic_analyse: "Análise Econômica",
     informations_and_trading_events: "Notícias e eventos de negociação",
     trading_events: "Eventos de Negociação",
     key_economic_data: "Dados econômicos importantes",
     technic_analyse: "Análise Técnica",
     support_and_resistance: "Suporte e Resistência",
     trends: "Tendências",
     hauss_trends: "Tendências ascendentes",
     bass_trends: "Tendências Descendentes",
     course_resume: "Resumo do Curso",
     trading_psychology: "Psicologia do Trading",
     trading_psychology_description: "Neste curso inovador, você aprenderá como superar o maior inimigo do trading: as emoções! Adquira a mentalidade de um trader de sucesso, ganhe confiança em seu trading.",
     introduction: "Introdução",
     fear_of_lost: "Medo de perder",
     surmont_the_fear_of_lost: "Superando o medo da perda",
     gourmandise: "Ganância",
     surmont_gourmandise: "Superando a Ganância",
     position_management: "Gerenciamento de posição",
     solutions_: "Soluções",
     risk_management: "Gerenciamento de Riscos",
     finance_management_solutions: "Soluções de gerenciamento de dinheiro",
     course_resume: "Resumo do Curso",
     forex_strategy: "Estratégias de Negociação",
     forex_strategy_description: "Este curso revelará uma ampla gama de estratégias de negociação forex sob demanda para todos os tipos de traders",
     day_trading: "Dia de Negociação",
     news_trading: "Negociação de notícias",
     carry_trading: "Carry Trading",
     binary_trends_trading: "Negociação de Tendências",
     binary_swing_trading: "Swing Trading",
     chart_level_trading_of_binaries_option: "Negociação em nível de gráfico",
     chartists_classics_figures: "Negociação de padrões gráficos clássicos",
     technical_indicators_trading: "Indicador Técnico de Negociação",
     course_resume: "Resumo",
     trading_sociology: "Negociação social",
     trading_sociology_description: "A negociação social é um tipo de negociação onde os investidores podem observar as estratégias de negociação de outros comerciantes. Torne-se um copiador e replique as negociações do provedor, onde um provedor é um comerciante experiente que compartilha suas estratégias de investimento.",
     what_interest: "Por que fazer negociação social?",
     how_to_process: "Escolha um provedor",
     advantages_and_inconvenients: "Vantagens e desvantagens",
     course_resume: "Resumo",
     technic_indicators: "Indicadores Técnicos",
     technic_indicators_description: "Os métodos mais comuns para analisar os indicadores dos mercados financeiros, gráficos de velas e muito mais.",
     graphiques_en_chandeliers: "Gráficos de velas",
     figure_des_chandeliers_part_1: "Padrões de Candlestick Parte 1",
     figure_des_chandeliers_part_2: "Padrões de castiçal Parte 2",
     support_et_resistances: "Suporte e Resistência",
     moyenne_mobile: "Média móvel",
     bandes_de_bollinger: "Bandas de Bollinger",
     retracements_de_fibonacci: "Retrações de Fibonacci",
     indice_de_force_relative_ou_rsi: "Índice de Força Relativa",
     diversification_and_timing: "diversificação e timing",
     video_academy: "Academia de Vídeo",
     video_academy_description: "Aqui você encontra cursos com videoaulas.",
     video_academy_courses: "Cursos",
     active_orders: "Aktiva beställningar",
    closed_orders: "Stängda beställningar",
    wt_balance: "Saldo",
    w_volume: "Volym",
    form_choose_file: "Välj fil",
    btn_continue: "Fortsätt",
     deposit_amount_error: "Beloppet får inte vara mer än 5000, kontakta en kontoansvarig för ett insättningsbelopp som är större än 5000",
     selecte_a_payment_method: "Välj en betalningsmetod",
     btn_previous: "Föregående",
     send_the_exact_amount_of: "För att göra din insättning, skicka det exakta beloppet på",
     to_this_wallet: "Till följande adress",
     or_scan_the_qrcode: "Eller skanna QR-koden",
     timeleft_before_deposit: "Tid kvar innan insättningen: ",
     btn_return_on_app: "Återgå till appen.",
     deposit_important: "Viktigt",
     deposit_select_coin: "välj mynt",
     deposit_deposit_to: "Insättning till",
     deposit_send_only: `Skicka endast {{currency}} till
     denna insättningsadress. Skicka någon annan valuta
     till denna adress kan resultera i att du förlorar din
     insättning.`,
     deposit_ensure_network_is: "Se till att nätverket är",
     deposit_deposit_address: "Insättningsadress",
     deposit_show_qr: "Visa QR-kod",
     deposit_copy_qr: "Kopiera adress",
     deposit_tips: "Tips",
     deposit_tips_1: "Notera: Mynt kommer att sättas in omedelbart efter 2 nätverksbekräftelse",
     deposit_track_progress: "När du har gjort en insättning kan du följa dess framsteg på",
     deposit_history_page: "historiksida.",
     deposit_enter_card_details: "Vänligen ange dina kortuppgifter för att betala med.",
     btn_copy_text: "Kopier",
     payment_view_trans_id: "Transaksjons-ID",
     you_also_have_create: "Du må også åpne ",
     a_ticket: "en billett.",
     menu_tickets: "støtte billetter",
     new_tickets: "Ny billett",
     ticket_subject: "Emne",
     ticket_status: "Status",
     ticket_last_updated: "Sist oppdatert",
     ticket_details: "Billettdetaljer",
     ticket_view: "Billettvisning",
     posted_on: "på",
     posted_by: "Lagt inn av",
     ticket_replies: "Svar",
     ticket_reply: "Svar",
     notification_title: "Varsling",
     notification_empty: "Du har ingen varsler",
     usr_request_call: "Anropsforespørsel",
     usr_request_call_severity: "Alvorlighetsgrad",
     usr_request_call_date: "Ønsket ringedato",
     usr_request_call_time: "Ønsket samtaletid",
     usr_request_call_subject: "Samtaleemne",
     usr_request_call_severity_normal: "Normal",
     usr_request_call_severity_urgent: "Haster",
     usr_request_call_severity_very_u: "Veldig haster",
     usr_request_call_action_btn: "Be om en samtale",
     usr_request_call_request_sended:"Anropsforespørsel er mottatt, vi vil ringe deg på nevnte tidspunkt.",
     password_reset_desc: "Vennligst, skriv inn e-postadressen din for å tilbakestille passordet",
     password_reset_action_btn: "Tilbakestill passordet",
     password_recovery_action_btn: "Gjenopprett passordet",
     password_reset_reset: "Skriv inn og bekreft ditt nye passord",
     deposit_card_holder: "Kortinnehavers navn",
     deposit_hide_qr: "Skjul QR-kode",
     deposit_send_within: "Skicka endast {{currency}} på denna plånbok inom {{timeout}}",
     TR_PENDING: "Väntar",
     TR_CANCELED: "Avvisad",
     TR_VALIDATED: "Validerad",
     TR_AWAITING_FOR_APPROVAL: "Väntar på godkännande",
     TR_ON_HOLD: "På vänt",
     menu_upload_document_to_verify_identity: "Ladda upp dina dokument för att verifiera din identitet.",
     ft_tin: "TIN",
     menu_upload_document_to_verify_identity_success: "Vi har tagit emot dina dokument, vi kommer att kontrollera dem och aktivera ditt konto om de är verifierade.",
     upload_failed_file_too_large: "Tyvärr, storleken på filen du laddade upp är mer än 2 Mb, och det är inte tillåtet.",
     text_copied_success: "Text kopierad!",
     ft_national_id_card_front: "ID-kort framsida",
     ft_national_id_card_back: "ID-kort tillbaka",
     ft_upload_national_id_card_back: "Ladda upp ID-kort tillbaka",
     ft_upload_national_id_card_front: "Ladda upp ID-kort framsidan",
     account_status: "Kontoverifiering",
     account_status_pending: "Verifieringen av din information pågår.",
     account_status_pending_text: "Vårt verifieringsteam har tagit emot dina dokument och deras verifiering pågår. Du kommer att få ett e-postmeddelande när dina dokument har godkänts och sedan kan du logga in och börja göra dina investeringar.",
     account_status_pending_signature: "Med vänliga hälsningar, StockBridge Capital verifieringsteam",
     account_status_pending_nb: "OBS: På grund av en stor mängd verifieringsförfrågningar som skickats in, kan valideringen av dina dokument ta från 30 minuter till 24 timmar. Tack för att du har tålamod och vi ses snart för din första investering.",
     withdrawal_page_description: `Genom att klicka på "Begär ett uttag" kommer vi att informeras om att
     du vill ta ut pengar. Pengarna kommer att återbetalas till
     ditt finansieringskredit- eller betalkort.`,
     dashboard_login_wtrader: "Logga in på Web Trader",
     transaction_create_success: "Transaktionen har skapats!",
     transaction_create_error_message: "Fel vid skapande av transaktion! Försök senare",
     transaction_loading_error_message: "Fel vid laddning av transaktion !! Försök senare",
     files_loading_error_message: "Filladdning misslyckades!",
     files_upload_success_message: "Filen har laddats upp",
     files_upload_error_message: "Filuppladdning misslyckades. snälla försök igen",
     wallets_creation_error_message: "Plånboken har skapats!",
     wallets_creation_success_message: "Det gick inte att skapa plånbok!",
     wallets_loading_error_message: "Fel vid laddning!",
     wallets_loading_success_message: "Det gick inte att skapa plånbok!",
     text_no_eth_wallet_for_swap: "Vi är ledsna, men du har ingen Ethereum-plånbok för att betala nätverksavgifter för denna bytestransaktion.",
      text_cannot_generate_eth_wallet: "Du kan inte skapa en ETH-plånbok, uppgradera din kontotyp och försök igen.",
      to_bbm: "para StockBridge Capital TRADE",
     account_status_2: "Estatuto da conta",
     account_verified: "Verificado",
     account_not_verified: "Não verificado",
     date_registered: "Data de registro",
     txt_wm: "Métodos de saque",
     txt_cw:"Continuar retirada",
     txt_tr:"SALA DE COMÉRCIO",
     txt_ob:"LIVRO DE PEDIDOS",
     txt_wl:"Lista de observação",
     txt_ar:"Alertas e Relatórios",
     txt_fh:"Histórico Financeiro",
     txt_dashboard:"Painel",
     txt_tpv:"Valor total do portfólio",
     txt_trader_room:"Sala do Trader",
     txt_ar2:"Relatório da conta",
     txt_ab:"Saldo Disponível",
     txt_e_pnl:"PNL estimado",
     txt_td:"Depósito total",
     txt_taw:"Total de retiradas aprovadas",
     txt_p_bal:"Saldo do Plano",
     txt_d:"Data",
     txt_ad:"Detalhes da minha conta",
     txt_ud:"Documentos enviados",
     txt_mt:"Meus ingressos",
     txt_up:"Atualizar senha",
     txt_w:"Retiradas",
     txt_s_n:"S/N",
     txt_pm:"Forma de pagamento",
     txt_at:"Todas as transações",
     txt_w_l:"Lista de desejos",
     txt_sa:"Pesquisar ativos",
     txt_ass:"Ativos",
     txt_sell:"VENDER",
     txt_buy:"COMPRAR",
     txt_spread:"Espalhar",
     txt_actions:"Ações",
     TXT_:"",
     menu_withdraw: "Retirar",
     txt_login_welcome: "Välkommen till <strong>StockBridge Capital</strong>",
    txt_sign_in: "Logga in",
    txt_sign_up: "Registrera dig",
    txt_to_access: "för att komma åt ditt konto",
    txt_to_open: "öppna ditt konto",
    txt_forgot_password: "Har du glömt lösenordet?",
    txt_not_client: "Inte en StockBridge Capital <br />-klient än?",
    txt_open_account: "Öppna ett konto",
    txt_main_page: "StockBridge Capital huvudsida ",
    txt_contact_us_at: "Kontakta oss på",
    txt_if_have_pb: "Om du har problem",
    txt_footer: "Att handla med CFD:er innebär betydande risk för förlust. Läs StockBridge Capital",
    txt_risk: "‘Riskupplysning’.",
    txt_not_membber: 'Inte medlem?</span> <b className="ng-star-inserted">Registrera dig nu!',
    txt_already_member: "<span>Är du redan medlem?</span> <b>Logga in nu!</b>",
   },
};