

export default {
  translation: {
     "shared.french": "Francese",
     "shared.english": "Inglese",
     "shared.spanish": "Spanish",
     "shared.italian": "Italian",
     "shared.norvegian": "Norvegian",
     "shared.portugues": "Portugues",
     "shared.sweden": "Sweden",
     "shared.deutsch": "Deutsch",
     home: "casa",
     home_welcome: "Benvenuto",
     home_you_can: "Ora puoi utilizzare un conto reale per fare trading su Forex, CFD, materie prime, azioni, indici su più piattaforme (Web e dispositivi mobili). Grazie per averci scelto",
     menu_contact_us: "Contattaci",
     menu_my_account:"Il mio account",
     menu_logout: "Esci",
     menu_personal_details: "Dettagli personali",
     menu_change_password: "Cambia password",
     menu_trading_history: "Cronologia di trading",
     menu_upload_document: "Carica documenti",
     menu_monetary_transaction: "Transazioni monetarie",
     menu_wallet: "Portafogli",
     menu_withdrawal: "Prelievo",
     pd_first_name: "Nome",
     pd_last_name: "Cognome",
     pd_email: "E-mail",
     pd_phone_number: "Numero di telefono",
     pd_country: "Paese",
     pd_edit: "Modifica",
     pd_city: "Città",
     pd_post_code: "Codice postale",
     form_save: "Salva",
     edit_account: "Modifica account",
     field_mandatory: "Tutti i campi contrassegnati come *sono obbligatori",
     change_password: "Cambia password",
     old_password: "Vecchia password",
     pw_weak: "Debole",
     pw_medium: "Medio",
     pw_strong: "Forte",
     pw_confirm_pass: "Conferma password",
     pw_set_pass: "Conferma password",
     form_submit: "Invia",
     pw_new_password: "Nuova password",
     table_display: "Visualizza",
     display_record: "record",
     table_empty: "Nessun dato disponibile nella tabella",
     th_symbol: "Simbolo",
     th_quantity: "Quantità",
     th_open_date: "Data di apertura",
     th_close_date: "Data di chiusura",
     th_open_price: "Prezzo aperto",
     th_close_price: "Chiudi prezzo",
     th_position_type: "Acquista/Vendi",
     th_comments: "Commenti",
     th_profit_lost:"Profitto/perso",
     upload_documents: "Carica documenti",
     ud_upload_type: "Tipo di caricamento",
     ud_upload_date: "Data di caricamento",
     select_choose_option: "Scegli un'opzione",
     ft_bank_file: "File",
     ft_bank_wire: "Bonifico bancario",
     ft_credit_card_front: "Fronte carta di credito",
     ft_driving_license: "Patente di guida",
     ft_national_id_card: "Carta d'identità nazionale",
     ft_passport_id: "ID passaporto",
     ft_utility_bill: "Conto delle utenze",
     ft_dod: "DOD",
     ft_por: "POR",
     ft_id: "ID",
     ft_wire_details: "Dettagli cavo",
     ft_wd_request_form: "Modulo di richiesta WD",
     ft_credit_card_back: "Retro carta di credito",
     ft_other_doc_type: "Altro tipo di documento",
     file_uploaded: "File caricati",
     wallet_details: "Dettagli portafoglio",
     w_currency:"Valuta",
     w_amount:"Importo",
     w_status:"Stato",
     w_status_valid:"VALIDO",
     w_status_invalid:"INVALID",
     w_currency:"Indirizzo di deposito",
     w_transactions_history:"Cronologia transazioni",
     w_currency:"Valuta",
     wd_reference:"Riferimento",
     wd_transaction_type:"Tipo di transazione",
     wd_amount:"Importo",
     wd_wallet:"Portafoglio",
     wd_status:"Stato",
     wd_operation_date:"Data operazione",
     wd_note: "Nota",
     wallet_list: "Elenco portafogli",
     new_wallet: "Nuovo portafoglio",
     create_wallet_description:"Inserisci le informazioni del tuo portafoglio per creare.",
     choose_currency: "Scegli valuta",
     removal_page_description: `Facendo clic su "Richiedi un prelievo" verremo informati
     desideri prelevare fondi. Il denaro sarà rimborsato a
     la tua carta di credito o di debito per il finanziamento.`,
     wt_choose_wallet: "Scegli un portafoglio",
     wt_amount: "Importo",
     wt_beneficiary: "Beneficiario",
     wt_bank_name: "Nome banca",
     wt_bank_address: "Indirizzo banca",
     wt_bank_account_number: "Numero di conto bancario",
     wt_swift_code: "Codice rapido",
     wt_wallet_address: "Indirizzo portafoglio",
     wt_relation_between: "Relazione tra te e il destinatario",
     wt_confirm: "Riconoscendo che le transazioni del portafoglio crittografico sono rischiose, approvo questa transazione convalidando i dati forniti.",
     wt_request_a_withdrawal: "Richiedi un prelievo",
     l_loading: "Caricamento in corso",
     login_to_start: "Accedi per iniziare subito a fare trading",
     login_to_start_description: `Puoi iscriverti subito per iniziare a fare pratica di trading su a
     Conto demo GRATUITO. Una volta che hai acquisito abbastanza fiducia,
     puoi iniziare a fare trading per davvero. Prova la piattaforma, trova
     il tuo approccio e inizia.`,
     dont_have_account:"Non hai un account",
     register_here: "Registrati qui",
     login_password :"Password",
     login_email :"E-mail",
     login_button :"ACCEDI",
     live_registration: "Registrazione dal vivo",
     personal_informations: "Informazioni personali",
     login_information: "Informazioni di accesso",
     login_i_have_read:"Ho letto, compreso e accetto le",
     term_and_conditions: "Termini e condizioni",
     register_now: "REGISTRATI ORA",
     login_now: "Accedi ora",
     already_have_ccount: "Hai già un account",
     edit_profile_language: "Lingua",
     edit_profile_gender: "Sesso",
     edit_profile_gender_male: "Uomo",
     edit_profile_gender_female: "Femmina",
     edit_profile_birth_day: "Data di nascita",
     edit_profile_address: "Indirizzo",
     te_pw_failed_message: "Modifica della password non riuscita, verifica l'inserimento e riprova.",
     closed_order: "Posizioni chiuse",
     open_order: "Posizioni aperte",
     menu_video_academy: "Accademia video",
     w_deposit_address: "Indirizzo di deposito del portafoglio",
     button_deposit_text: "Deposito",
     button_deposit_description: "Informazioni sul deposito.",
     qr_code: "codice QR",
     wallet_details_overview: "Panoramica",
     wt_wallet_address_type: "Tipo di portafoglio",
     wallet_details_details: "Dettagli",
     "no_email_validation": "Nessuna chiave di conferma email, riprova più tardi.",
     email_address_validated: 'Il tuo indirizzo email è stato verificato con successo, puoi accedere ora',
     email_address_validation_error: 'Ooops si è verificato un errore durante la verifica del tuo indirizzo email. Per favore, prova ad aggiornare la pagina o riprova più tardi.',
     email_verification_pending: "Verifica del tuo indirizzo email...",
     w_deposit_iban: "IBAN",
     deposit_infos: "Informazioni sul deposito",
     new_wallet_generate_address: "Genera indirizzo.",
     new_wallet_success: "Portafoglio creato con successo",
     new_wallet_error: "Errore di creazione del portafoglio",
     menu_swap_transfert: "Scambia trasferimento",
     transfert_money: "Trasferisci denaro",
     transfert_money_between_wallets: "Trasferisci denaro tra portafogli",
     wallet_to_withdraw: "Portafoglio da addebitare",
     wallet_to_send_to: "Portafoglio da accreditare",
     transfert_amount: "Importo di trasferimento",
     swap_transfert_success: "Fondi trasferiti con successo",
     swap_transfert_error: "Si è verificato un errore durante il trasferimento di fondi, riprova più tardi.",
     swap_transfert_cannot_process: "Non possiamo elaborare il trasferimento, inserisci tutte le informazioni richieste e riprova.",
     transfert_amount: "Importo",
     transfert_execute: "Esegui",
     return_to_courses: "Torna ai corsi",
     crypto_trading: "Trading di criptovalute",
     crypto_trading_description: "In questo corso imparerai tutte le basi per iniziare a fare trading nel mondo delle criptovalute.",
     introduction_and_history: "Introduzione e storia",
     blockchain_and_descentralisation: "Blockchain e decentralizzazione",
     major_cryptocurencies: "Principali criptovalute",
     other_crypto_and_stable_coin: "Altre criptovalute e stablecoin",
     nft: "NFT",
     market_actors: "Attori di mercato",
     key_factors: "Driver chiave del mercato delle criptovalute",
     trading_tools: "Strumenti di trading di criptovalute",
     psychologie_of_trading_and_risk_management: "Psicologia del trading di criptovalute e gestione del rischio",
     trade_cryptos: "Come scambiare criptovalute",
     conclusion: "Conclusione",
     market_analysis: "Analisi di mercato",
     market_analysis_description: "Questo corso di analisi di mercato è destinato a coloro che desiderano sviluppare il proprio vantaggio nel trading e affinare le proprie capacità di analisi fondamentale e tecnica per prendere decisioni di trading migliori.",
     introduction: "Introduzione",
     economic_analyse: "Analisi economica",
     informations_and_trading_events: "Notizie ed eventi di trading",
     trading_events: "Eventi di trading",
     key_economic_data: "Dati economici chiave",
     technical_analyse: "Analisi tecnica",
     support_and_resistance: "Supporto e resistenza",
     trends: "Tendenze",
     hauss_trends: "Tendenze al rialzo",
     bass_trends: "Tendenze al ribasso",
     course_resume: "Riepilogo corso",
     trading_psychology: "Psicologia commerciale",
     trading_psychology_description: "In questo corso innovativo imparerai come superare il più grande nemico del trading: le emozioni! Entra nella mentalità di un trader di successo, crea fiducia nel tuo trading.",
     intro: "Intro",
     fear_of_lost: "Paura della perdita",
     surmont_the_fear_of_lost: "Superare la paura della perdita",
     gourmandise: "Avidità",
     surmont_gourmandise: "Superare l'avidità",
     position_management: "Gestione posizione",
     solutions_: "Soluzioni",
     risk_management: "Gestione del rischio",
     financial_management_solutions: "Soluzioni per la gestione del denaro",
     course_resume: "Riepilogo corso",
     forex_strategy: "Strategie di trading",
     forex_strategy_description: "Questo corso svelerà un'ampia gamma di strategie di forex trading su richiesta per tutti i tipi di trader",
     day_trading: "Trading giornaliero",
     news_trading: "Trading di notizie",
     carry_trading: "Carry Trading",
     binary_trends_trading: "Trading di tendenza",
     binary_swing_trading: "Swing trading",
     chart_level_trading_of_binaries_option: "Trading a livello di grafico",
     chartists_classics_figures: "Trading di schemi grafici classici",
     technical_indicators_trading: "Trading di indicatori tecnici",
     course_resume: "Riepilogo",
     trading_sociology: "Trading sociale",
     trading_sociology_description: "Il social trading è un tipo di trading in cui gli investitori possono osservare le strategie di trading di altri trader. Diventa un copiatore e replica le operazioni del provider, dove un provider è un trader esperto che condivide le loro strategie di investimento.",
     what_interest: "Perché il social trading?",
     how_to_process: "Scegli un fornitore",
     advantages_and_inconvenients: "Vantaggi e svantaggi",
     course_resume: "Riepilogo",
     technical_indicators: "Indicatori tecnici",
     technical_indicators_description: "I metodi più comuni per analizzare gli indicatori dei mercati finanziari, i grafici a candele e altro.",
     graphiques_en_chandeliers: "Grafici a candele",
     figures_des_chandeliers_part_1: "Modelli di candelieri parte 1",
     figures_des_chandeliers_part_2: "Modelli di candelieri parte 2",
     support_et_resistances: "Supporto e resistenza",
     moyenne_mobile: "Media mobile",
     bandes_de_bollinger: "Bande di Bollinger",
     retracements_de_fibonacci: "Ritracciamenti di Fibonacci",
     indice_de_force_relative_ou_rsi: "Indice di forza relativa",
     diversification_and_timing: "diversificazione e tempistica",
     video_academy: "Accademia video",
     video_academy_description: "Qui puoi trovare corsi contenenti lezioni video.",
     video_academy_courses: "Corsi",
     active_orders: "Ordini attivi",
    closed_orders: "Ordini chiusi",
    wt_balance: "Saldo",
    w_volume: "Volume",
    form_choose_file: "Scegli file",
    ft_bank_statement: "Estratto conto bancario",
     ft_other: "Altro",
     accept_im_major_and_not_living_in_usa: "Confermo di essere maggiorenne e di non essere cittadino degli Stati Uniti.",
     accept_general_risk: `e sono d'accordo con <a href="https://StockBridge Capitaltrade.com/legalcontent/General_risk_disclosure.pdf" target="_blank"class="text-primary">informativa generale sui rischi</a>` ,
     btn_continue: "Continua",
     deposit_amount_error: "L'importo non può essere superiore a 5000, contatta un account manager per un importo di deposito superiore a 5000",
     selecte_a_payment_method: "Seleziona un metodo di pagamento",
     btn_previous: "Precedente",
     send_the_exact_amount_of: "Per effettuare il deposito, invia l'importo esatto di",
     to_this_wallet: "Al seguente indirizzo",
     or_scan_the_qrcode: "Oppure scansiona il codice QR",
     timeleft_before_deposit: "Tempo rimanente prima del deposito: ",
     btn_return_on_app: "Torna sull'app.",
     deposit_important: "Importante",
     deposit_select_coin: "seleziona moneta",
     deposit_deposit_to: "Deposito su",
     deposit_send_only: `Invia solo {{valuta}} a
     questo indirizzo di deposito. Invio di qualsiasi altra valuta
     a questo indirizzo può comportare la perdita del tuo
     deposito.`,
     deposit_ensure_network_is: "Assicurati che la rete sia",
     deposit_deposit_address: "Indirizzo deposito",
     deposit_show_qr: "Mostra codice QR",
     deposit_copy_qr: "Copia indirizzo",
     deposit_tips: "Suggerimenti",
     deposit_tips_1: "Avviso: le monete verranno depositate immediatamente dopo la conferma di 2 Network",
     deposit_track_progress: "Dopo aver effettuato un deposito, puoi seguirne l'avanzamento sul",
     deposit_history_page: "pagina cronologia.",
     deposit_enter_card_details: "Per favore, inserisci i dettagli della tua carta con cui pagare.",
     btn_copy_text: "Copier",
     payment_view_trans_id: "Identifiant de la transaction",
     you_also_have_create: "Vous devez également ouvrir ",
     a_ticket: "un billet.",
     menu_tickets: "tickets d'assistance",
     new_tickets: "Nouveau billet",
     ticket_subject: "Objet",
     ticket_status: "Statut",
     ticket_last_updated: "Dernière mise à jour",
     ticket_details: "Détails du billet",
     ticket_view: "Vue du ticket",
     posted_on: "sur",
    posted_by: "Publié par",
     ticket_replies: "Réponses",
     ticket_reply: "Répondre",
     notification_title: "Notification",
     notification_empty: "Vous n'avez aucune notification",
     usr_request_call: "Demande d'appel",
     usr_request_call_severity: "Gravité",
     usr_request_call_date: "Date d'appel souhaitée",
     usr_request_call_time: "Heure d'appel souhaitée",
     usr_request_call_subject: "Sujet de l'appel",
     usr_request_call_severity_normal: "Normale",
     usr_request_call_severity_urgent: "Urgent",
     usr_request_call_severity_very_u: "Très urgent",
     usr_request_call_action_btn: "Demander un appel",
     usr_request_call_request_sended:"La demande d'appel a été reçue, nous vous appellerons à l'heure indiquée.",
     password_reset_desc: "Veuillez saisir votre adresse e-mail pour réinitialiser le mot de passe",
     password_reset_action_btn: "Réinitialiser le mot de passe",
     password_recovery_action_btn: "Récupérer le mot de passe",
     password_reset_reset: "Entrez et confirmez votre nouveau mot de passe",
     deposit_card_holder: "Nom du titulaire de la carte",
     deposit_hide_qr: "Masquer le code QR",
     deposit_send_within: "Invia solo {{currency}} su questo portafoglio entro {{timeout}}",
     TR_PENDING: "In attesa",
     TR_CANCELED: "Rifiutato",
     TR_VALIDATED: "Convalidato",
     TR_AWAITING_FOR_APPROVAL: "In attesa di approvazione",
     TR_ON_HOLD: "In attesa",
     menu_upload_document_to_verify_identity: "Carica i tuoi documenti per verificare la tua identità.",
     ft_tin: "TIN",
     menu_upload_document_to_verify_identity_success: "Abbiamo ricevuto i tuoi documenti, li controlleremo e attiveremo il tuo account se sono verificati.",
     upload_failed_file_too_large: "Spiacenti, la dimensione del file che hai caricato è superiore a 2 Mb, e non è consentito.",
     text_copied_success: "Testo copiato!",
     ft_national_id_card_front: "carta d'identità anteriore",
     ft_national_id_card_back: "Retro carta d'identità",
     ft_upload_national_id_card_back: "Ricarica carta d'identità",
     ft_upload_national_id_card_front: "Carica la parte anteriore della carta d'identità",
     account_status: "Verifica dell'account",
     account_status_pending: "La verifica delle tue informazioni è in corso.",
     account_status_pending_text: "Il nostro team di verifica ha ricevuto i tuoi documenti e la loro verifica è in corso. Riceverai un'email una volta approvati i tuoi documenti, quindi potrai accedere e iniziare a fare i tuoi investimenti.",
     account_status_pending_signature: "Cordiali saluti, il team di verifica dei mercati dei tori neri",
     account_status_pending_nb: "NB: a causa dell'elevato numero di richieste di verifica inviate, la convalida dei tuoi documenti può richiedere da 30 minuti a 24 ore. Grazie per la pazienza e a presto per il tuo primo investimento.",
     withdrawal_page_description: `Facendo clic su "Richiedi un prelievo" verremo informati
     desideri prelevare fondi. Il denaro sarà rimborsato a
     la tua carta di credito o di debito per il finanziamento.`,
     dashboard_login_wtrader: "Accedi a Web Trader",
     transaction_create_success: "Transazione creata con successo!",
     transaction_create_error_message: "Errore nella creazione della transazione!! Riprova più tardi",
     transaction_loading_error_message: "Errore di transazione durante il caricamento!! Riprova più tardi",
     files_loading_error_message: "Caricamento file non riuscito!",
     files_upload_success_message: "File caricato con successo",
     files_upload_error_message: "Caricamento file non riuscito. Per favore, riprova",
     wallets_creation_error_message: "Portafoglio creato con successo!",
     wallets_creation_success_message: "Creazione portafoglio fallita!",
     wallets_loading_error_message: "Errore di caricamento!",
     wallets_loading_success_message: "Creazione portafoglio fallita!",
     text_no_eth_wallet_for_swap: "Siamo spiacenti, ma non hai un portafoglio Ethereum per pagare le commissioni di rete per questa transazione di scambio.",
      text_cannot_generate_eth_wallet: "Non puoi generare un portafoglio ETH, aggiorna il tuo tipo di account e riprova.",
      to_bbm: "al StockBridge Capital TRADE",
     account_status_2: "Stato del conto",
     account_verified: "Verificato",
     account_not_verified: "Non verificato",
     date_registered: "Data di registrazione",
     txt_wm: "Metodi di prelievo",
     txt_cw:"Continua il ritiro",
     txt_tr:"SALA COMMERCIALE",
     txt_ob:"LIBRO ORDINI",
     txt_wl:"Lista di controllo",
     txt_ar:"Avvisi e rapporti",
     txt_fh:"Storia finanziaria",
     txt_dashboard:"Dashboard",
     txt_tpv:"Valore totale del portafoglio",
     txt_trader_room:"Stanza del commerciante",
     txt_ar2:"Rapporto sull'account",
     txt_ab:"Saldo disponibile",
     txt_e_pnl:"PNL stimato",
     txt_td:"Deposito totale",
     txt_taw:"Totale prelievi approvati",
     txt_p_bal:"Saldo del piano",
     txt_d:"Data",
     txt_ad:"Dettagli del mio account",
     txt_ud:"Documenti caricati",
     txt_mt:"I miei biglietti",
     txt_up:"Aggiorna password",
     txt_w:"Prelievi",
     txt_s_n:"S/N",
     txt_pm:"Metodo di pagamento",
     txt_at:"Tutte le transazioni",
     txt_w_l:"Lista dei desideri",
     txt_sa:"Cerca risorse",
     txt_ass:"Risorse",
     txt_sell:"VENDI",
     txt_buy:"ACQUISTA",
     txt_spread:"Distribuisci",
     txt_actions:"Azioni",
     TXT_:"",
     menu_withdraw: "Ritira",
     txt_close: "Chiudi",
txt_please_wait: "Per favore, aspetta...",
txt_position_closed: "Posizione chiusa con successo",
txt_position_closed_error: "E si è verificato un errore durante la chiusura della tua posizione, prova a contattare il tuo account manager.",
txt_login_welcome: "Benvenuto su <strong>StockBridge Capital</strong>",
    txt_sign_in: "Accedi",
    txt_sign_up: "Registrati",
    txt_to_access: "per accedere al tuo account",
    txt_to_open: "apri il tuo account",
    txt_forgot_password: "Password dimenticata?",
    txt_not_client: "Non sei ancora un client StockBridge Capital <br />?",
    txt_open_account: "Apri un conto",
    txt_main_page: "Pagina principale di StockBridge Capital",
    txt_contact_us_at: "Contattaci a",
    txt_if_have_pb: "Se hai problemi",
    txt_footer: "Il trading di CFD comporta un rischio significativo di perdita. Leggi StockBridge Capital",
    txt_risk: "'Informativa sui rischi'.",
    txt_not_membber: 'Non sei membro?</span> <b className="ng-star-inserted">Iscriviti ora!',
    txt_already_member: "<span>Sei già membro?</span> <b>Accedi ora!</b>",
    txt_f: "",
   },
};