import fr from "./fr";
import en from "./en";
import es from "./es";
import nv from "./nv";
import it from "./it";
import pt from "./pt";
import sd from "./sd";
import de from "./de";

export default { en: en, fr: fr, es: es, it: it, nv: nv, pt: pt, sd: sd, de: de, };
