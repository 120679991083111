 import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
    </div>
  );
};

export default Footer;
