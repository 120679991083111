import { CHANGE_DARK_MODE, LOADING_COUNTRIES_FAILED, LOADING_COUNTRIES_REQUEST, LOADING_COUNTRIES_SUCCESS, LOAD_MARKET_PRICES, PAYMENTS_METHODS } from "../actions/utilsActions";


const initialState = {
    countries: {
        loading_countries_request: false,
        loading_countries_success: false,
        loading_countries_failed: false,
        countries: {
            results: [],
        }
    },
    marketPrices: undefined,
    paymentData: undefined,
    isLight: true,
};

export function UtilReducers(state = initialState, action) {
    if (action.type === LOADING_COUNTRIES_REQUEST) {
        return {
            ...state,
            countries: {
                ...state.countries,
                loading_countries_request: true,
                loading_countries_success: false,
                loading_countries_failed: false,
            }
            
        };
    }
    if (action.type === LOADING_COUNTRIES_SUCCESS) {
        return {
            ...state,
            countries: {
                ...state.countries,
                loading_countries_request: false,
                loading_countries_success: true,
                loading_countries_failed: false,
                countries: action.payload
            }
            
        };
    }

    if (action.type === LOADING_COUNTRIES_FAILED) {
        return {
            ...state,
            countries: {
                ...state.countries,
                loading_countries_request: false,
                loading_countries_success: false,
                loading_countries_failed: true,
            }
            
        };
    }

    if (action.type === LOAD_MARKET_PRICES) {
        return {
            ...state,
            marketPrices: {
                ...state.marketPrices,
                ...action.payload,
            }
            
        };
    }

    if (action.type === PAYMENTS_METHODS) {
        return {
            ...state,
            paymentData: {
                ...action.payload,
            }
            
        };
    }
    if (action.type === CHANGE_DARK_MODE) {
        return {
            ...state,
            isLight: action.payload
            
        };
    }

    return state;
}

