import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowUp, ArrowDown, CoinIcon1, CoinIcon2, CoinIcon3, CoinIcon4 } from "../zenix/Transactions/TableData";
// images
import table1 from "../../../images/table/Untitled-1.jpg";
import table2 from "../../../images/table/Untitled-2.jpg";
import table3 from "../../../images/table/Untitled-3.jpg";
import { useTranslation } from 'react-i18next';
import { Button, Nav, Tab } from 'react-bootstrap';
import TransactionHead from '../../pages/App/Transaction/components/TransactionHead';
import TransactionRow from '../../pages/App/Transaction/components/TransactionRow';
import _ from 'lodash';
import { API } from '../../../services/axios';
import { URLS } from '../../../services/urls/urls';
import moment from 'moment';
//import table4 from "../../../images/table/Untitled-4.jpg";


const Transactions = () => {
	const [data, setData] = useState(document.querySelectorAll("#transaction_table tbody tr"));
	//const [activeName, setActiveName] = useState("Newest");

	const { t } = useTranslation();
	const sort = 8;
	const activePag = useRef(0);
	const [test, settest] = useState(0);

	const [transactions, setTransactions] = useState([]);

	const [show, setShow] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [selected, setSelected] = useState("last_7_days");
	const [pdfData, setPdfData] = useState("");
	const [state, setState] = useState([
		{
			startDate: new Date(),
			key: 'selection'
		}
	]);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
	// use effect
	useEffect(() => {
		setData(document.querySelectorAll("#transaction_table tbody tr"));
	}, [test]);

	// Active pagginarion
	activePag.current === 0 && chageData(0, sort);
	// paggination
	let paggination = Array(Math.ceil(data.length / sort))
		.fill()
		.map((_, i) => i + 1);

	// Active paggination & chage data
	const onClick = (i) => {
		activePag.current = i;
		chageData(activePag.current * sort, (activePag.current + 1) * sort);
		settest(i);
	};

	
	const handleGenerate = () => {

		setDisabled(true);
		let startDate = "";
		let endDate = "";
		const today = moment();
	
		switch (selected) {
		  case "last_7_days":
			endDate = today.format('YYYY-MM-DD');
			startDate = moment(today).subtract(7, 'days').format('YYYY-MM-DD');
			break;
		  case "current_month":
			startDate = moment().startOf('month').format('YYYY-MM-DD');
			// Get current month end date
			endDate = moment().endOf('month').format('YYYY-MM-DD');
			break;
		  case "last_6_months":
			startDate = today.clone().subtract(6, 'months').startOf('month');
			// Calculate the end date of the last 6 months
			endDate = today.clone().subtract(1, 'months').endOf('month');
			break;
		  case "current_year":
			const currentYear = moment().year();
	
			// Get the start and end dates of the current year
			startDate = moment().year(currentYear).startOf('year').format('YYYY-MM-DD');
			endDate = moment().year(currentYear).endOf('year').format('YYYY-MM-DD');
	
			break;
	
		  case "last_year":
			// Get the start date of the last year
			startDate = today.subtract(1, 'year').startOf('year');
	
			// Get the end date of the last year
			endDate = today.endOf('year');
			break;
	
		  default:
			break;
		}
	
		API.get(URLS.EXPORT.transactions(startDate, endDate), {
		  responseType: 'arraybuffer' // Set responseType to 'arraybuffer' to receive binary data
		}).then(response => {
	
		  // Create a Blob from the response data
		  const blob = new Blob([response.data], { type: 'application/pdf' });
		  // Create a URL for the Blob
		  const url = window.URL.createObjectURL(blob);
		  // Set the URL to the state
		  // setPdfData(url);
		  var link = document.createElement("a");
		  link.href = url;
		  link.download = "transactions-reports.pdf";
		  document.body.appendChild(link);
		  link.click();
		  document.body.removeChild(link);
		  setDisabled(false);
		})
		  .catch(error => {
			console.error('Error downloading PDF:', error);
			setDisabled(false);
		  });
	  }
	
	
	  useEffect(() => {
		API.get(URLS.USERS.MY_TRANSACTIONS).then((resp) => {
		  setTransactions(resp.data)
		})
	
	  }, []);

	return (
		<Fragment>
			<div className="form-head d-flex align-items-center flex-wrap mb-3">
				<h2 className="font-w600 mb-0 text-black">{t("txt_fh")}</h2>
				<Button variant="primary" className="p-3 ms-auto" onClick={handleShow}>
					{t("txt_export_report")}
				</Button>
			</div>
			<div className="card">
				<Tab.Container defaultActiveKey="Withdrawals">
					<div className="card-header pb-2 d-block d-sm-flex flex-wrap border-0">
						<div className="mb-3">
							<h4 className="fs-20 text-black">Recent Wallet Activities</h4>
							{/* <p className="mb-0 fs-12">Lorem ipsum dolor sit amet, consectetur</p> */}
						</div>
						<div className="card-action card-tabs mb-3 style-1">
							<Nav as="ul" className="nav-tabs" role="tablist">
								<Nav.Item as="li" className="nav-item"><Nav.Link to={"#"} eventKey="Withdrawals" >{t("txt_w")}</Nav.Link></Nav.Item>
								<Nav.Item as="li" className="nav-item"><Nav.Link to={"#"} eventKey="Deposits">{t("button_deposit_text")}</Nav.Link></Nav.Item>
								<Nav.Item as="li" className="nav-item"><Nav.Link to={"#"} eventKey="AllTransactions" >{t("txt_at")}</Nav.Link></Nav.Item>
							</Nav>
						</div>
					</div>
					<div className="card-body tab-content p-0">
						<div className="tab-pane active show fade" id="activeOrders">
							<Tab.Content>

								<Tab.Pane eventKey="Withdrawals">
									<table className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer" id="transaction_table_withdrawals"
										role="grid" aria-describedby="example5_info">
										<TransactionHead />
										<tbody>
											{
												_.filter(transactions, { "type": "WITHDRAW" })?.map((transaction) => (

													<TransactionRow transaction={transaction} key={"width" + transaction.id}  />
												))
											}
										</tbody>
									</table>
								</Tab.Pane>
								<Tab.Pane eventKey="Deposits">
									
									<table className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer" id="transaction_table_closed_orders"
										role="grid" aria-describedby="example5_info">
										<TransactionHead />
										<tbody>
											{
												_.filter(transactions, { "type": "CREDIT" }).map((trans) => {
													
													return(

													<TransactionRow transaction={trans} key={"dep" + trans.id} />
												)})
											}
										</tbody>
									</table>
								</Tab.Pane>
								<Tab.Pane eventKey="AllTransactions">
									<table className="table display mb-4 dataTablesCard short-one card-table text-black dataTable no-footer" id="transaction_table_balance"
										role="grid" aria-describedby="example5_info">
										<TransactionHead />
										<tbody>
											{
												transactions?.map((transaction) => (

													<TransactionRow transaction={transaction} key={"all-" + transaction.id}  />
												))
											}
										</tbody>
									</table>

								</Tab.Pane>
							</Tab.Content>
						</div>
					</div>
				</Tab.Container>
			</div>

		</Fragment>
	)
}
export default Transactions;