import { useState } from "react";
import { Tab, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PositionRow from "../../../webtrader/PositionRow";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";


const ActiveOrders = ({positionsList, isLoading}) => {

    const change = {};

    const { t } = useTranslation();

    return (
        <Tab.Pane eventKey="ActiveOrders">

            <Table responsive striped>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">{t("txt_d")}</th>
                            <th scope="col">{t("th_symbol")}</th>
                            <th scope="col">{t("th_position_type")}</th>
                            <th scope="col">{t("th_quantity")}</th>
                            <th scope="col">{t("th_open_price")}</th>
                            <th scope="col">{t("th_close_price")}</th>
                            <th scope="col">{t("th_profit_lost")}</th>
                            <th scope="col">{t("text_stop_loss")}</th>
                            <th scope="col">{t("text_take_profit")}</th>
                            <th scope="col">{t("txt_actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Filter closed positions */}
                        {_.filter(positionsList?.results ?? [], { status: "OPENED" })?.map(
                            (position, index) => {
                                return (
                                    <PositionRow
                                        position={position}
                                        index={index}
                                        change={change}
                                        isOpen={true}
                                    />
                                );
                            }
                        )}
                    </tbody>
                </table>
            </Table>
            {(!isLoading && positionsList?.results?.length === 0) && <div className="text-center">
                <p className="text-center">{t("table_empty")}</p>
            </div>}
            {(isLoading) && <div className="text-center">
                <p className="text-center">{t("l_loading")} ...</p>
            </div>}
        </Tab.Pane>
    )
}


export default ActiveOrders;