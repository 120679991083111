import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { BarLoader } from "react-spinners";
import { API } from "../../../../../services/axios";
import { URLS } from "../../../../../services/urls/urls";
import useSWR from "swr";

const TicketDetails = () => {
  const [loading, setLoading] = useState(true);
  
  const { ticketId } = useParams();
  const { data: ticket, isLoading } = useSWR(URLS.TICKETS.MY_DETAILS(ticketId));

  const editorRef = useRef(null);
  const { t } = useTranslation();

  return (
    <Fragment>
      <div
        className="q-page-container my-account trade-room-layout" >
          <div className="" style={{ marginBottom: "60px" }}>
            {isLoading && <BarLoader width={"100%"} color="var(--primary)" />}
            {ticket && (
              <div className="card">
                <div className="card-body">
                  <div className="border p-3">
                    <h2>
                      {t("ticket_view")}: #{ticket?.reference}
                    </h2>
                    <div>
                      {t("ticket_subject")}: {ticket?.subject}
                    </div>
                  </div>
                  <div className="border">
                    <div className="ticket-author">
                      <div className="d-flex">
                        <span className="mr-1">{t("posted_by")} </span>{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {ticket?.created_by.first_name}{" "}
                          {ticket?.created_by?.last_name}
                        </span>{" "}
                        <span className="mx-1">{t("posted_on")}</span>
                        <span>{moment(ticket.created_at).format("lll")}</span>
                      </div>
                    </div>
                    <div className="p-3">
                      <div dangerouslySetInnerHTML={{ __html: ticket?.content }} />
                    </div>
                    {/* Replies */}
                    {
                      ticket?.responses?.length > 0 && (
                        <>
                          <div className="p-3">
                            <h2>{t("ticket_replies")}</h2>
                          </div>
                          <div className="p-3">
                            <div className="border-top">
                              <div className="ticket-author">
                                <div className="d-flex">
                                  <span className="mr-1">{t("posted_by")} </span>{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {ticket?.created_by.first_name}{" "}
                                    {ticket?.created_by?.last_name}
                                  </span>{" "}
                                  <span className="mx-1">{t("posted_on")}</span>
                                  <span>{moment(ticket.created_at).format("lll")}</span>
                                </div>
                              </div>
                              <div className="p-3">
                                <div
                                  dangerouslySetInnerHTML={{ __html: ticket?.content }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
    </Fragment>
  );
};

export default TicketDetails;
