// TradingViewWidget.js

import React, { useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import WebtraderFooter from "./WebtraderFooter";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import useSWR from "swr";
import { URLS } from "../../../services/urls/urls";
import { API } from "../../../services/axios";
import { ThemeContext } from "../../../context/ThemeContext";


let tvScriptLoadingPromise;

export default function Webtrader({tab, handleRenameTab}) {
  const onLoadScriptRef = useRef();
  const [stickerChange, setStickerChange] = useState({});
  const [symbolMeta, setSymbolMeta] = useState({});
  const [conversionRate, setConversionRate] = useState(1);
  const [prevStickerChange, setPrevStickerChange] = useState({});
  const [activeWallet, setActiveWallet] = useState();
  const {background} = useContext(ThemeContext);

  const handle = useFullScreenHandle();

  const { data: walletsList, isLoading: isLoadingWallets, mutate: mutateWallets } = useSWR(`${URLS.USERS.MY_WALLETS("")}`);
  const {
    auth: { user },
  } = useSelector((state) => state.auth);

  const { locale } = useSelector((state) => state.i18n);

  const handleToggleFullScreen = () => {
    if(handle.active){
      handle.exit();
    } else {
      handle.enter();
    }
  };

  useEffect(() => {
    onLoadScriptRef.current = createWidget;
    handle.active = false;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script-onnj-";
        // script.src = "/assets/js/tv.js";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.async = true;
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );
 
    function createWidget() {
      if (
        document.getElementById("tradingview_24c78") &&
        "TradingView" in window &&
        user
      ) {
        // Init tab name

        handleRenameTab({
          panel: tab?.panel,
          title: `BITSTAMP:BTC${user?.default_currency ?? "USD"}`,
        });

        // Init widget
        let widget = new window.TradingView.widget({
          autosize: true,
          symbol: tab?.title !== "BTC" ? tab?.title : `BITSTAMP:BTC${user?.default_currency ?? "USD"}`,
          theme: background.value,
          style: "1",
          currency: "EUR",
          locale: locale ?? "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          withdateranges: true,
          range: "YTD",
          hide_side_toolbar: false,
          allow_symbol_change: true,
          details: true,
          fullscreen: true,
          hotlist: true,
          timezone: "exchange",
          calendar: false,
          container_id: "tradingview_24c78",
          interval: "D",
          save_image: false,
          // "disabled_features": ['use_localstorage_for_settings', 'header_symbol_search'],
        });

        widget.ready((e) => {
          widget.subscribeToQuote((data, utils, hola) => {
            setStickerChange(data);
          });
        });
      }
    }

    return () => (onLoadScriptRef.current = null);
  }, [user]);

  useEffect(() => {
    // If user changed sybol on chart
    if (stickerChange.original_name !== prevStickerChange.original_name) {
      setPrevStickerChange(stickerChange);
      console.log("stickerChange", stickerChange);
      handleRenameTab(
        {
          panel: tab?.panel,
          title: stickerChange.original_name,
        }
      )
      // const symbolDetails = getSymbolInfo(stickerChange.short_name, stickerChange.exchange)
      API.get(URLS.TV.symbols(stickerChange.short_name, stickerChange.exchange))
        .then((resp) => {
          const symbolDetails = resp.data;
          console.log(
            "symbolDetails",
            stickerChange,
            prevStickerChange,
            symbolDetails
          );
          setSymbolMeta(symbolDetails);

          // Fetch conversion rate for the current symbol after obtaining symbol currency
          const convObj = {
            frm: symbolDetails.currency_code,
            to: activeWallet.currency ?? "EUR",
            amount: 1,
          };

          API.post(URLS.WALLETS.CONVERT, convObj)
            .then((resp) => {
              const rate = resp.data;
              // Update conversion rate
              setConversionRate(rate ?? 1);
            })
            .catch((error) => {});
        })
        .catch((error) => {});
    }
  }, [stickerChange]);

  useEffect(() => {
    if (walletsList?.results) {
      setActiveWallet(
        walletsList?.results?.filter((item) => item.currency === "USD")[0] ??
        walletsList?.results?.filter((item) => item.currency === "EUR")[0]
      );
      // {formatCurrency(selectedWallet?.currency) ?? ""}{" "} {selectedWallet?.balance ? Number(selectedWallet?.balance ?? 0.0).toFixed(2) : ""}
    }
  }, [walletsList]);

  return (
    <div>
      <FullScreen handle={handle}>

        <div className="">
          <div className="">
            <div className="position-relative">
              <div className="" style={{position: 'absolute', top: 0, right: 0, zIndex: 10000}}>
                <div className="d-flex">
                  <div>
                    <button onClick={handleToggleFullScreen} className="p-2">
                      {
                        handle.active ? <BsFullscreenExit style={{fontSize: '25px'}} /> : <BsFullscreen style={{fontSize: '25px'}} />
                      }
                    </button>
                  </div>
                </div>
              </div>
              <div className="tradingview-widget-container" style={{ height: handle.active ? "100vh" : "70vh" }}>
                <div id="tradingview_24c78" style={{ height: handle.active ? "100vh" : "70vh" }} />
                <div className="webtrader-actions"></div>
              </div>
            </div>
          </div>
        </div>
      </FullScreen>
      <WebtraderFooter
        user={user}
        change={stickerChange}
        conversioRate={conversionRate}
        activeWallet={activeWallet}
      />
    </div>
  );
}
