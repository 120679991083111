import { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import BarLoader from "react-spinners/BarLoader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { API } from "../../../../services/axios";
import { URLS } from "../../../../services/urls/urls";
import { copyText } from "../../../components/copy/copyText";
import { IoCopyOutline } from "react-icons/io5";


export default function UploadedDocuments() {
    const [file, setFile] = useState();
    const [fileType, setFileType] = useState("");
    const [uploading, setUploading] = useState();
    const [loading, setLoading] = useState();
    const [pageSize, setPageSize] = useState(10);
    const [filesList, setFilesList] = useState(undefined);
    const { user } = useSelector((state) => state.auth.auth);

    const { t } = useTranslation();

    const handleUploadedFile = (event) => {
        const file = event.target.files[0];
        setFile(file);
        // console.log("event.target.files[0] ==============>", event.target.files[0]);
    };
    const handleSubmit = (event) => {
        // console.log(file && user);
        event.preventDefault();
        // for
        setUploading(true);
        let formData = new FormData();
        if (file && user) {
            formData.append("user", user.id);
            formData.append("file", file);
            formData.append("file_type", String(fileType).replace("_", " "));
            // formData.append("type", fileType);
            API.post(URLS.USERS.FILES.CREATE, formData)
                .then((resp) => {
                    const { data } = resp;
                    toast.success(t("files_upload_success_message"));
                    setUploading(false);
                    setFilesList((oldFiles) => [data, ...oldFiles.results]);
                })
                .catch((error) => {
                    toast.success(t("files_upload_error_message"));
                    setUploading(false);
                });
        }
    };
    useEffect(() => {
        setLoading(true);
        API.get(URLS.USERS.FILES.MY)
            .then((response) => {
                setFilesList(response?.data);
                setLoading(false);
            })
            .catch((error) => {
                toast.success(t("files_loading_error_message"));
                setLoading(false);
            });
    }, [pageSize]);
    return (
        <Fragment title={t("menu_upload_document")}>
            <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
                <div>
                    <h3 className="font-w600 mb-0 me-auto mb-2 text-black">{t("menu_upload_document")}</h3>
                    {/* <p> {t("menu_upload_document")}</p> */}
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div>
                        <div className="card">
                            <div className="card-body">
                                <form
                                    action="/upload-documents"
                                    className="form px-4"
                                    encType="multipart/form-data"
                                    id="actionForm2"
                                    method="post"
                                    noValidate="novalidate"
                                >
                                    {" "}
                                    <div className="mb-3">
                                        <div className="">
                                            <div>
                                                <label htmlFor="UploadType">
                                                    <span>{t("ud_upload_type")}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className=" ta-right">
                                                <div className="formset">
                                                    <div className="selector fixedWidth" id="uniform-UploadType">
                                                        <select
                                                            id="UploadType"
                                                            value={fileType}
                                                            onChange={(event) => setFileType(event.target.value)}
                                                            className="form-control"
                                                        >
                                                            <option value="">{t("select_choose_option")}</option>
                                                            <option value="BANK_STATEMENT">
                                                                {t("ft_bank_statement")}
                                                            </option>
                                                            <option value="BANK_WIRE">{t("ft_bank_wire")}</option>
                                                            <option value="CREDIT_CARD_FRONT">
                                                                {t("ft_credit_card_front")}
                                                            </option>
                                                            <option value="DRIVING_LICENSE">
                                                                {t("ft_driving_license")}
                                                            </option>
                                                            {/* <option value="NATIONAL_ID_CARD">
                            {t("ft_national_id_card")}
                          </option> */}
                                                            <option value="NATIONAL_ID_CARD_FRONT">
                                                                {t("ft_national_id_card_front")}
                                                            </option>
                                                            <option value="NATIONAL_ID_CARD_BACK">
                                                                {t("ft_national_id_card_back")}
                                                            </option>
                                                            <option value="PASSPORT_ID">{t("ft_passport_id")}</option>
                                                            <option value="UTILITY_BILL">{t("ft_utility_bill")}</option>
                                                            <option value="DOD">{t("ft_dod")}</option>
                                                            <option value="POR">{t("ft_por")}</option>
                                                            <option value="ID">{t("ft_id")}</option>
                                                            <option value="WIRE_DETAILS">{t("ft_wire_details")}</option>
                                                            <option value="WD_REQUEST_FORM">
                                                                {t("ft_wd_request_form")}
                                                            </option>
                                                            <option value="TIN">{t("ft_tin")}</option>
                                                            <option value="CREDIT_CARD_BACK">
                                                                {t("ft_credit_card_back")}
                                                            </option>
                                                            <option value="OTHER">{t("ft_other")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <span className="error">
                                                    <span
                                                        className="field-validation-valid"
                                                        data-valmsg-for="UploadType"
                                                        data-valmsg-replace="true"
                                                    ></span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="">
                                            <div>
                                                <label htmlFor="IdentificationsFile">
                                                    <span>
                                                        {t("ft_bank_file")}: <span className="text-danger">*</span>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div>
                                                <div className="row-control ta-right">
                                                    <div className="formset">
                                                        <label
                                                            htmlFor="IdentificationsFile"
                                                            className="button btn btn-primary no-border py-3"
                                                        >
                                                            <span>
                                                                <span>{t("form_choose_file")}</span>
                                                            </span>
                                                        </label>
                                                        <input
                                                            hidden
                                                            type="file"
                                                            className="file"
                                                            id="IdentificationsFile"
                                                            name="IdentificationsFile"
                                                            accept="*"
                                                            size="25"
                                                            onChange={handleUploadedFile}
                                                        />
                                                        <span>{
                                                            file?.name}</span>
                                                        <span className="error">
                                                            <span
                                                                className="field-validation-valid"
                                                                data-valmsg-for="IdentificationsFile"
                                                                data-valmsg-replace="true"
                                                            >

                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{" "}
                                    <span className="error col-md-offset-2"></span>
                                    <h3 className="status-success"></h3>
                                    <div className="row ta-center">
                                        <div className="col-12">
                                            <div className="d-flex">
                                                <div className="w-100">
                                                    <Button
                                                        disabled={uploading}
                                                        className="button no-border py-3 w-100"
                                                        to="#"
                                                        onClick={handleSubmit}
                                                    >
                                                        <span>
                                                            <span>{t("form_submit")}</span>
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="table account-table">
                                <div id="tradingHistory_wrapper" className="dataTables_wrapper no-foot">
                                    <div className="dataTables_length" id="tradingHistory_length">
                                        <label>
                                            {t("table_display")}{" "}
                                            <select
                                                name="tradingHistory_length"
                                                aria-controls="tradingHistory"
                                                className=""
                                                value={pageSize}
                                                onChange={(event) => {
                                                    setPageSize(event.target.value);
                                                }}
                                            >
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>{" "}
                                            {t("display_record")}
                                        </label>
                                    </div>
                                    {loading && (
                                        <div style={{ display: "flex" }}>
                                            {" "}
                                            <BarLoader color="var(--primary)" width={"100%"} />{" "}
                                        </div>
                                    )}
                                    {!loading && filesList?.results && (
                                        <>
                                            <table
                                                id="tradingHistory"
                                                className="desktop-table dataTable no-footer w-100"
                                                role="grid"
                                            >
                                                <thead>
                                                    <tr role="row">
                                                        <th className="sorting_disabled" rowSpan="1" colSpan="1">
                                                            {t("ud_upload_type")}
                                                        </th>
                                                        <th className="sorting_disabled" rowSpan="1" colSpan="1">
                                                            {t("ud_upload_date")}
                                                        </th>
                                                        <th className="sorting_disabled" rowSpan="1" colSpan="1">
                                                            {t("file")}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filesList.results.length === 0 && (
                                                        <tr className="odd">
                                                            <td
                                                                colSpan="11"
                                                                className="dataTables_empty"
                                                                valign="top"
                                                            >
                                                                {t("table_empty")}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {filesList.results.length > 0 &&
                                                        filesList.results.map((position, index) => (
                                                            <tr
                                                                key={position.id}
                                                                className={index % 2 === 0 ? "odd" : "even"}
                                                            >
                                                                <td colSpan="1" valign="top">
                                                                    {position.file_type}
                                                                </td>
                                                                <td colSpan="1" valign="top">
                                                                    {moment(position.uploaded_at).format("lll")}
                                                                </td>
                                                                <td colSpan="1" valign="top">
                                                                    <div className="d-flex">
                                                                        <div className="row-file">{position.file}</div>
                                                                        <div>
                                                                            <button className="btn btn-primary" onClick={() => copyText(position.file)}>
                                                                                <IoCopyOutline />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
