import React,{Fragment,useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { ThemeContext } from "../../../context/ThemeContext";


import Donut from "../zenix/MyWallets/Donut";
import WalletTab from "../zenix/MyWallets/WalletTab";
import ContactSlider from "../zenix/Home/ContactSlider";
import SwiperSlider2 from "../zenix/MyWallets/SwiperSlider2";
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { URLS } from '../../../services/urls/urls';


const CoinChart = loadable(() =>
  pMinDelay(import("../zenix/MyWallets/CoinChart"), 1000)
);

const Wallet = () => {
	
	const {data: walletsList, isLoading: isLoadingWallets} = useSWR(`${URLS.USERS.MY_WALLETS("")}`);

	const [ activeWallet, setActiveWallet ] = useState();

	const handleChangeWallet = (wallet) => {
		setActiveWallet(wallet);
	}

	const { t } = useTranslation();
	return(
		<Fragment>
			<div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
				<h2 className="font-w600 mb-0 me-auto mb-2 text-black">{t("menu_wallet")}</h2>
			</div>
			{
				isLoadingWallets && <div className="text-center">
					<p className="text-center">{t("l_loading")} ...</p>
				</div>
			}
			{
				!isLoadingWallets && walletsList?.results?.length === 0 && <div className="text-center">
					<p className="text-center">{t("table_empty")}</p>
				</div> 
			}

			{
				!isLoadingWallets && walletsList?.results?.length > 0 && <div className="row">
					<div className="col-xl-3 col-xxl-4">
						<div className="swiper-box">
							<SwiperSlider2 wallets={walletsList?.results} handleChangeWallet={handleChangeWallet} activeWallet={activeWallet} />
						</div>
					</div>
					<div className="col-xl-9 col-xxl-8">
						<WalletTab  activeMenu ="Wallet Activity" activeWallet={activeWallet} />
					</div>
				</div>
			}
		</Fragment>
	)
}		
export default Wallet;