import { menus } from "./fr/menus.translations";

const fr = {
  translation: {
    "shared.french": "Francais",
    "shared.english": "Anglais",
    "shared.spanish": "Spanish",
    "shared.italian": "Italian",
    "shared.norvegian": "Norvegian",
    "shared.portugues": "Portugues",
    "shared.sweden": "Sweden",
    "shared.deutsch": "Deutsch",
    "shared.internet_connexion_error": "Vérifiez votre connexion Internet!",
    home: "Acceuil",
    home_welcome: "Bienvenue",
    home_you_can:
      "Vous pouvez désormais utiliser un compte réel pour trader le Forex, les CFD, les matières premières, les actions et les indices sur plusieurs plateformes (Web et mobile). Merci de nous avoir choisis",
    menu_contact_us: "Contactez-nous",
    menu_my_account: "Mon compte",
    menu_logout: "Déconnexion",
    menu_personal_details: "Détails personnels",
    menu_change_password: "Modifier le mot de passe",
    menu_trading_history: "Historique des trades",
    menu_upload_document: "Télécharger des documents",
    menu_monetary_transaction: "Transactions monétaires",
    menu_wallet: "Portefeuilles",
    menu_withdrawal: "Retrait",
    pd_first_name: "Prénom",
    pd_last_name: "Nom de famille",
    pd_email: "E-mail",
    pd_phone_number: "Numéro de téléphone",
    pd_country: "Pays",
    pd_edit: "Modifier",
    pd_city: "Ville",
    pd_post_code: "Code postal",
    form_save: "Enregistrer",
    edit_account: "Modifier le compte",
    field_mandatory: "Tous les champs marqués comme *sont obligatoires",
    change_password: "Modifier le mot de passe",
    old_password: "Ancien mot de passe",
    pw_weak: "Faible",
    pw_medium: "Moyen",
    pw_strong: "Fort",
    pw_confirm_pass: "Confirmer le mot de passe",
    pw_set_pass: "Confirmer le mot de passe",
    form_submit: "Soumettre",
    pw_new_password: "Nouveau mot de passe",
    table_display: "Afficher",
    display_record: "enregistrements",
    table_empty: "Aucune donnée disponible dans la table",
    th_symbol: "Actif",
    th_quantity: "Quantité",
    th_open_date: "Date d'ouverture",
    th_close_date: "Date de clôture",
    th_open_price: "Prix d'ouverture",
    th_close_price: "Prix de clôture",
    th_position_type: "Achat/Vente",
    th_comments: "Commets",
    th_profit_lost: "Profit / perte",
    upload_documents: "Télécharger des documents",
    ud_upload_type: "Type de téléchargement",
    ud_upload_date: "Date de téléchargement",
    select_choose_option: "Choisissez une option",
    ft_bank_file: "Fichier",
    ft_bank_wire: "Virement bancaire",
    ft_credit_card_front: "Recto de la carte de crédit",
    ft_driving_license: "Permis de conduire",
    ft_national_id_card: "Carte d'identité nationale",
    ft_passport_id: "ID de passeport",
    ft_utility_bill: "Facture de services publics",
    ft_dod: "DOD",
    ft_por: "POR",
    ft_id: "identifiant",
    ft_wire_details: "Détails du câble",
    ft_wd_request_form: "Formulaire de demande WD",
    ft_credit_card_back: "Retour de carte de crédit",
    ft_other_doc_type: "Autre type de document",
    file_uploaded: "Fichiers téléchargés",
    wallet_details: "Détails du portefeuille",
    w_currency: "Devise",
    w_amount: "Montant",
    w_status: "Statut",
    w_status_valid: "VALIDE",
    w_status_invalid: "INVALIDE",
    w_currency: "Adresse de dépôt",
    w_transactions_history: "Historique des transactions",
    w_currency: "Devise",
    wd_reference: "Référence",
    wd_transaction_type: "Type de transaction",
    wd_amount: "Montant",
    wd_wallet: "Portefeuille",
    wd_status: "Statut",
    wd_operation_date: "Date d'opération",
    wd_note: "Remarque",
    wallet_list: "Liste des portefeuilles",
    new_wallet: "Nouveau portefeuille",
    create_wallet_description: "Entrez vos informations de wallet à créer.",
    choose_currency: "Choisir la devise",
    removal_page_description: `En cliquant sur "Demander un retrait", nous serons informés que
    vous souhaitez retirer des fonds. L'argent sera remboursé à
    votre carte de crédit ou de débit de financement.`,
    wt_choose_wallet: "Choisir un portefeuille",
    wt_amount: "Montant",
    wt_beneficiary: "Bénéficiaire",
    wt_bank_name: "Nom de la banque",
    wt_bank_address: "Adresse de la banque",
    wt_bank_account_number: "Numéro de compte bancaire (IBAN)",
    wt_swift_code: "Code rapide",
    w_deposit_iban: "IBAN",
    deposit_infos: "Informations pour le dépôt",
    wt_wallet_address_type: "Type d'adresse",
    wt_wallet_address: "Adresse du portefeuille",
    wt_relation_between: "Relation entre vous et le destinataire",
    wt_confirm:
      "Reconnaissant que les transactions de portefeuille crypto sont risquées, j'approuve cette transaction en validant les données fournies.",
    wt_request_a_withdrawal: "Demander un retrait",
    l_loading: "Chargement",
    login_to_start: "Connectez-vous pour commencer à trader dès maintenant",
    login_to_start_description: `Vous pouvez vous inscrire dès maintenant pour commencer à pratiquer le trading sur un
    Compte démo GRATUIT. Une fois que vous avez acquis suffisamment de confiance,
    vous pouvez commencer à trader pour de vrai. Testez la plateforme, trouvez
    votre approche et lancez-vous.`,
    dont_have_account: "Vous n'avez pas de compte",
    register_here: "Inscrivez-vous ici",
    login_password: "Mot de passe",
    login_email: "E-mail",
    login_button: "CONNEXION",
    live_registration: "Création de compte",
    personal_informations: "Informations personnelles",
    login_information: "Informations de connexion",
    login_i_have_read: "J'ai lu, compris et accepté le",
    term_and_conditions: "Conditions d'utilisation",
    register_now: "INSCRIPTION",
    login_now: "Connexion maintenant",
    already_have_ccount: "Vous avez déjà un compte",
    edit_profile_language: "Langue",
    edit_profile_gender: "Sexe",
    edit_profile_gender_male: "Homme",
    edit_profile_gender_female: "Femme",
    edit_profile_birth_day: "Date de naissance",
    edit_profile_address: "Adresse",
    update_pw_failed_message:
      "La modification du mot de passe a échoué, veuillez vérifier votre saisie et réessayer.",
    withdrawal_page_description: `En cliquant sur "Demander un retrait", nous serons informés que
      vous souhaitez retirer des fonds. L'argent sera remboursé à
      votre carte de crédit ou de débit de financement.`,
    closed_order: "Positions fermées",
    opened_order: "Positions ouvertes",
    menu_video_academy: "Academie video",
    w_deposit_address: "Adresse pour les dépôts",
    button_deposit_text: "Dépôt",
    button_deposit_description: "Informations pour le dépôt",
    qr_code: "QR code",
    wallet_details_overview: "Aperçu",
    wallet_details_details: "Détails",
    no_email_validation:
      "Aucune clé de confirmation d'e-mail, veuillez réessayer plus tard.",
    email_address_validated:
      "Votre adresse e-mail a été vérifiée avec succès, vous pouvez vous connecter maintenant",
    email_address_validation_error:
      "Oups, une erreur s'est produite lors de la vérification de votre adresse e-mail. Veuillez actualiser la page ou réessayer plus tard.",
    email_verification_pending: "Vérification de votre adresse e-mail...",
    new_wallet_generate_address: "Générer une adresse.",
    new_wallet_success: "Le portefeuille a été créé avec succès",
    new_wallet_error: "Erreur de création de portefeuille",
    menu_swap_transfert: "Transfert d'échange",
    transfert_money: "Transférer de l'argent",
    transfert_money_between_wallets:
      "Transférer de l'argent entre portefeuilles",
    wallet_to_withdraw: "Portefeuille à débiter",
    wallet_to_send_to: "Portefeuille à créditer",
    transfert_amount: "Montant du transfert",
    swap_transfert_success: "Les fonds ont été transférés avec succès",
    swap_transfert_error:
      "Une erreur s'est produite lors du transfert de fonds, veuillez réessayer plus tard.",
    swap_transfert_cannot_process:
      "Nous ne pouvons pas traiter le transfert, veuillez remplir toutes les informations requises et réessayer.",
    transfert_amount: "Montant",
    transfert_execute: "Exécuter",
    return_to_courses: "Retour aux cours",
    crypto_trading: "Commerce de chiffrement",
    crypto_trading_description:
      "Dans ce cours, vous apprendrez toutes les bases pour commencer à trader dans le monde des crypto-monnaies.",
    introduction_and_history: "Introduction et historique",
    blockchain_and_descentralisation: "Blockchain et décentralisation",
    major_cryptocurencies: "Principales crypto-monnaies",
    other_crypto_and_stable_coin: "Autres cryptos et stablecoins",
    nft: "NFT",
    market_actors: "Acteurs du marché",
    key_factors: "Principaux moteurs du marché de la cryptographie",
    trading_tools: "Outils de crypto-trading",
    psychologie_of_trading_and_risk_management:
      "Psychologie du trading crypto et gestion des risques",
    trade_cryptos: "Comment échanger des cryptos",
    conclusion: "Conclusion",
    market_analysis: "Analyse du marché",
    market_analysis_description:
      "Ce cours d'analyse de marché est destiné à ceux qui souhaitent développer leur avantage commercial et affiner leurs compétences d'analyse fondamentale et technique pour prendre de meilleures décisions commerciales.",
    présentation: "Présentation",
    economic_analyse: "Analyse économique",
    informations_and_trading_events: "Actualités et événements commerciaux",
    trading_events: "Événements commerciaux",
    key_economic_data: "Données économiques clés",
    technical_analyse: "Analyse technique",
    support_and_resistance: "Soutien et résistance",
    trends: "Tendances",
    hauss_trends: "Tendances à la hausse",
    bass_trends: "Tendances à la baisse",
    course_resume: "Résumé du cours",
    trading_psychology: "Psychologie du trading",
    trading_psychology_description:
      "Dans ce cours révolutionnaire, vous apprendrez à vaincre le plus grand ennemi du trading: les émotions ! Entrez dans l'état d'esprit d'un trader qui réussit, renforcez la confiance dans votre trading.",
    introduction: "Intro",
    fear_of_lost: "Peur de perdre",
    surmont_the_fear_of_lost: "Surmonter la peur de perdre",
    gourmandise: "Gourmandise",
    surmont_gourmandise: "Surmonter la cupidité",
    positions_management: "Gestion des postes",
    solutions_: "Solutions",
    risk_management: "Gestion des risques",
    solutions_de_gestion_financière: "Solutions de gestion de l'argent",
    course_resume: "Résumé du cours",
    forex_strategy: "Stratégies de trading",
    forex_strategy_description:
      "Ce cours dévoilera un large éventail de stratégies de trading forex à la demande pour tous les types de traders",
    day_trading: "Trading journalier",
    news_trading: "Trading d'actualités",
    carry_trading: "Carry Trading",
    binary_trends_trading: "Trading de tendance",
    binary_swing_trading: "Swing Trading",
    chart_level_trading_of_binaries_option: "Trading au niveau du graphique",
    chartists_classics_figures: "Trading de modèle de graphique classique",
    technical_indicators_trading: "Négociation d'indicateurs techniques",
    course_resume: "Résumé",
    trading_sociology: "Trading social",
    trading_sociology_description:
      "Le trading social est un type de trading où les investisseurs peuvent observer les stratégies de trading d'autres traders. Devenez un copieur et reproduisez les transactions d'un fournisseur, où un fournisseur est un trader expérimenté qui partage ses stratégies d'investissement.",
    what_interest: "Pourquoi faire du trading social ?",
    how_to_process: "Choisir un fournisseur",
    avantages_et_inconvénients: "Avantages et inconvénients",
    course_resume: "Résumé",
    technical_indicators: "Indicateurs techniques",
    technical_indicators_description:
      "Les méthodes les plus courantes pour analyser les indicateurs des marchés financiers, les graphiques en chandeliers et plus encore.",
    graphiques_en_chandeliers: "Chandelier graphique",
    figures_des_chandeliers_part_1: "Modèles de chandeliers partie 1",
    figures_des_chandeliers_part_2: "Modèles de chandeliers partie 2",
    supports_et_resistances: "Soutien et Résistance",
    moyenne_mobile: "Moyenne mobile",
    bandes_de_bollinger: "Bandes de Bollinger",
    retracements_de_fibonacci: "Retracements de Fibonacci",
    indice_de_force_relative_ou_rsi: "Indice de force relative",
    diversification_and_timing: "diversification et timing",
    video_academy: "Académie vidéo",
    video_academy_description:
      "Vous trouverez ici des cours contenant des leçons vidéo.",
    video_academy_courses: "Cours",
    // new texts to translate
    active_orders: "Commandes actives",
    closed_orders: "Commandes fermées",
    wt_balance: "Solde",
    w_volume: "Volume",
    form_choose_file: "Choisissez un fichier",
    ft_bank_statement: "Relevé bancaire",
    ft_other: "Autre",
    agree_im_major_and_not_living_in_usa:
      "Je confirme que j'ai l'âge légal et que je ne suis pas citoyen des États-Unis.",
    accept_general_risk: `et je suis d'accord avec <a href="https://StockBridge Capitaltrade.com/legalcontent/General_risk_disclosure.pdf" target="_blank"class="text-primary">divulgation générale des risques</a>`,
    btn_Continue: "Continuer",
    deposit_amount_error:
      "Le montant ne peut pas être supérieur à 5000, veuillez contacter un gestionnaire de compte pour un montant de dépôt que 5000",
    selecte_a_payment_method: "Sélectionnez un mode de paiement",
    btn_previous: "précédent",
    send_the_exact_amount_of:
      "Pour effectuer votre dépôt, veuillez envoyer le montant exact de",
    to_this_wallet: "à l'adresse suivante",
    or_scan_the_qrcode: "ou scanner le code QR",
    timeleft_before_deposit: "Temps laissé avant le dépôt:",
    btn_return_on_app: "Retour sur l'application.",
    deposit_important: "important",
    deposit_select_coin: "sélectionner la pièce",
    deposit_deposit_to: "dépôt à",
    deposit_send_only: `Envoyez uniquement {{devise}}
       cette adresse de dépôt. Envoi de toute autre devise
       à cette adresse peut entraîner la perte de votre
       dépôt.`,
    deposit_ensure_network_is: "Assurez-vous que le réseau est",
    deposit_deposit_address: "Adresse de dépôt",
    deposit_show_qr: "Afficher le code QR",
    deposit_copie_qr: "Copier l'adresse",
    btn_copy_text: "Copier",
    deposit_tips: "Conseils",
    deposit_tips_1:
      "Avis: les pièces seront déposées immédiatement après 2 confirmation du réseau",
    deposit_track_progress:
      "Après avoir atteint un dépôt, vous pouvez suivre ses progrès sur le",
    deposit_history_page: "page d'histoire.",
    deposit_enter_card_details:
      "S'il vous plaît, entrez les détails de votre carte pour payer.",
    btn_copy_text: "Copier",
    payment_view_trans_id: "Identifiant de la transaction",
    you_also_have_create: "Vous devez également ouvrir ",
    a_ticket: "un billet.",
    menu_tickets: "tickets d'assistance",
    new_tickets: "Nouveau billet",
    ticket_subject: "Objet",
    ticket_status: "Statut",
    ticket_last_updated: "Dernière mise à jour",
    ticket_details: "Détails du billet",
    ticket_view: "Vue du ticket",
    posté_sur: "sur",
    posté_par: "Publié par",
    ticket_replies: "Réponses",
    ticket_reply: "Répondre",
    notification_title: "Notification",
    notification_empty: "Vous n'avez aucune notification",
    usr_request_call: "Demande d'appel",
    usr_request_call_severity: "Gravité",
    usr_request_call_date: "Date d'appel souhaitée",
    usr_request_call_time: "Heure d'appel souhaitée",
    usr_request_call_subject: "Sujet de l'appel",
    usr_request_call_severity_normal: "Normale",
    usr_request_call_severity_urgent: "Urgent",
    usr_request_call_severity_very_u: "Très urgent",
    usr_request_call_action_btn: "Demander un appel",
    usr_request_call_request_sended:
      "La demande d'appel a été reçue, nous vous appellerons à l'heure indiquée.",
    password_reset_desc:
      "Veuillez saisir votre adresse e-mail pour réinitialiser le mot de passe",
    password_reset_action_btn: "Réinitialiser le mot de passe",
    password_recovery_action_btn: "Récupérer le mot de passe",
    password_reset_reset: "Entrez et confirmez votre nouveau mot de passe",
    deposit_card_holder: "Nom du titulaire de la carte",
    deposit_hide_qr: "Masquer le code QR",
    deposit_send_within:
      "Envoyez uniquement {{currency}} sur ce portefeuille dans un délai de {{timeout}}",
    TR_PENDING: "En attente",
    TR_CANCELED: "Refusé",
    TR_VALIDATED: "Validé",
    TR_AWAITING_FOR_APPROVAL: "En attente d'approbation",
    TR_ON_HOLD: "En attente",
    menu_upload_document_to_verify_identity:
      "Téléchargez vos documents pour vérifier votre identité.",
    ft_tin: "TIN",
    menu_upload_document_to_verify_identity_success:
      "Nous avons reçu vos documents, nous allons les vérifier et activer votre compte s'ils sont vérifiés.",
    upload_failed_file_too_large:
      "Désolé, la taille du fichier que vous avez téléchargé est supérieure à 2 Mo, et ce n'est pas autorisé.",
    text_copied_success: "Texte copié !",
    ft_national_id_card_front: "Recto de la carte d'identité",
    ft_national_id_card_back: "Retour de la carte d'identité",
    ft_upload_national_id_card_back:
      "Télécharger la carte d'identité en arrière",
    ft_upload_national_id_card_front:
      "Télécharger le recto de la carte d'identité",
    account_status: "Vérification du compte",
    account_status_pending: "La vérification de vos informations est en cours.",
    account_status_pending_text:
      "Notre équipe de vérification a reçu vos documents et leur vérification est en cours. Vous recevrez un e-mail une fois vos documents approuvés, vous pourrez alors vous connecter et commencer à faire vos investissements.",
    account_status_pending_signature:
      "Cordialement, l'équipe de vérification des marchés haussiers noirs",
    account_status_pending_nb:
      "NB: En raison d'un grand nombre de demandes de vérification soumises, la validation de vos documents peut prendre de 30 min à 24 heures. Merci de votre patience, et à très bientôt pour votre premier investissement.",
    removal_page_description: `En cliquant sur "Demander un retrait", nous serons informés que
     vous souhaitez retirer des fonds. L'argent sera remboursé à
     votre carte de crédit ou de débit de financement.`,
    dashboard_login_wtrader: "Connexion à Web Trader",
    transaction_create_success: "Transaction créée avec succès !",
    transaction_create_error_message:
      " Erreur de création de transaction ! Veuillez réessayer plus tard",
    transaction_loading_error_message:
      "Erreur de chargement de la transaction !! Veuillez réessayer plus tard",
    files_loading_error_message: "Le chargement du fichier a échoué !",
    files_upload_success_message: "Fichier téléchargé avec succès",
    files_upload_error_message:
      "Le téléchargement du fichier a échoué. Veuillez réessayer",
    wallets_creation_error_message: "Le portefeuille a été créé avec succès !",
    wallets_creation_success_message: "La création du portefeuille a échoué !",
    wallets_loading_error_message: "Erreur de chargement !",
    wallets_loading_success_message: "La création du portefeuille a échoué !",
    text_no_eth_wallet_for_swap:
      "Nous sommes désolés, mais vous n'avez pas de portefeuille Ethereum pour payer les frais de réseau pour cette transaction d'échange.",
    text_cannot_generate_eth_wallet:
      "Vous ne pouvez pas générer de portefeuille ETH, veuillez mettre à niveau votre type de compte et réessayer.",
      // Ne translationns to do
    bankCard: "Carte bancaire",
    to_bbm: "sur StockBridge Capital TRADE",
     account_status_2: "Statut du compte",
     account_verified: "Vérifié",
     account_not_verified: "Non vérifié",
     date_registered: "Date d'enregistrement",
     txt_wm: "Méthodes de retrait",
     txt_cw:"Continuer le retrait",
     txt_tr:"SALLE COMMERCIALE",
     txt_ob:"CARNET DE COMMANDES",
     txt_wl:"Liste de surveillance",
     txt_ar:"Alertes et rapports",
     txt_fh:"Historique financier",
     txt_dashboard:"Tableau de bord",
     txt_tpv:"Valeur totale du portefeuille",
     txt_trader_room:"Salle des commerçants",
     txt_ar2:"Rapport de compte",
     txt_ab:"Solde disponible",
     txt_e_pnl:"PNL estimé",
     txt_td:"Dépôt total",
     txt_taw:"Total des retraits approuvés",
     txt_p_bal:"Solde du plan",
     txt_d:"Date",
     txt_ad:"Détails de mon compte",
     txt_ud:"Documents téléchargés",
     txt_mt:"Mes billets",
     txt_up:"Mettre à jour le mot de passe",
     txt_w:"Retraits",
     txt_s_n:"S/N",
     txt_pm:"Mode de paiement",
     txt_at: "Toutes les transactions",
     txt_w_l:"Liste de souhaits",
     txt_sa:"Rechercher des éléments",
     txt_ass:"Actifs",
     txt_sell:"VENDRE",
     txt_buy:"ACHETER",
     txt_spread:"Propagation",
     txt_actions:"Actions",
     SMS_:"",
     menu_withdraw: "Retirer",
     txt_close: "Fermer",
      txt_please_wait: "S'il vous plait, patienter...",
      txt_position_closed: "Position clôturée avec succès",
      txt_position_closed_error: "Une erreur s'est produite lors de la clôture de votre position",
      text_take_profit: "Prendre le profit",
      text_stop_loss: "Arrêter les pertes",
      text_pips: "Pips",
      txt_login_welcome: "Bienvenue sur <strong>StockBridge Capital</strong>",
      txt_sign_in: "Connexion",
      txt_sign_up: "Inscrivez-vous",
      txt_to_access: "pour accéder à votre compte",
      txt_to_open: "ouvrez votre compte",
      txt_forgot_password: "Mot de passe oublié ?",
      txt_not_client: "Pas encore un client StockBridge Capital <br />?",
      txt_open_account: "Ouvrir un compte",
      txt_main_page: "Page principale de StockBridge Capital",
      txt_contact_us_at: "Contactez-nous à",
      txt_if_have_pb: "Si vous avez des problèmes",
      txt_footer: "Le trading de CFD implique un risque de perte important. Lisez les StockBridge Capital",
      txt_risk: "'Déclaration de divulgation des risques'.",
      txt_not_membber: 'Pas membre ?</span> <b className="ng-star-inserted">Inscrivez-vous maintenant !',
      txt_already_member: "<span>Déjà membre?</span> <b>Connectez-vous maintenant!</b>",
      txt_f: "",
  },
};

export default fr;