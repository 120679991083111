import { Tab, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const AccountBalance = ({ walletsList, isLoading }) => {

    const { t } = useTranslation();


    return (
        <Tab.Pane eventKey="Balance">

            <Table responsive striped>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">{t("w_currency")}</th>
                            <th scope="col">{t("w_amount")}</th>
                            <th scope="col">{t("w_volume")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {walletsList?.results?.map((wallet) => {
                            return (
                                <tr>
                                    <th>{wallet?.currency}</th>
                                    <td>{wallet?.balance}</td>
                                    <td>{wallet?.volume}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                
                {(!isLoading && walletsList?.results?.length === 0) && <div className="text-center">
                    <p className="text-center">{t("table_empty")}</p>
                </div>}
                {(isLoading) && <div className="text-center">
                    <p className="text-center">{t("l_loading")} ...</p>
                </div>}
            </Table>
        </Tab.Pane>
    )
}


export default AccountBalance;