import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls/urls";
import { calculate_profit_loss } from "../../components/utils/functions";



function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 0.002) + min + 0.209);
}

const PositionRow = ({ position, index, change, reload, isOpen }) => {
  const [price, setPrice] = useState(position?.quantity);
  const [pl, setPl] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [closingPrice, setClosingPrice] = useState(position?.quantity);

  const handleClosePosition = (hideFailure) => {
    setDisabled(true);

    API.get(URLS.POSITIONS.userClose(position.id)).then((resp) => {
      toast.success(t("txt_position_closed"));
      setDisabled(false);
      if(reload) {
        reload();
      }
    }
    ).catch((error) => {
      setDisabled(false);
      if(!hideFailure){

        toast.error(t("txt_position_closed_error"));
      }
    })

  }

  const { t } = useTranslation();

  useEffect(() => {
    if(isOpen) {

      const interval = setInterval(() => {
        
        API.get(URLS.remoteLink(position.symbol, position.market)).then((resp) => {
          const data = resp.data;
          const price = data[6];
  
          if(price > 0) {
  
            setClosingPrice(price);
    
            const pl = calculate_profit_loss(position?.quantity, position?.opening_price, price)
            setPl(pl);
    
            const stopLoss = position?.stop_loss;
            const takeProfit = position?.take_profit;
    
            if (position?.status === "OPENED") {
              if (pl < 0) {
                // User is lossing money
                const lossAmount = -pl;
                if(stopLoss > 0) {
                  if(lossAmount > stopLoss){
                    handleClosePosition(true);
                  }
                }
              } else {
                if(takeProfit > 0){
    
                  if(pl > takeProfit){
                    handleClosePosition(true);
                  }
                }
              }
            }
            
          } else {
            // Si le prix est 0, ca veut dire que le systeme ne prend pas en compte le symbole
            const newPrice = randomIntFromInterval(
              position?.opening_price - position?.opening_price * 0,
              position?.opening_price + position?.opening_price * 0.2
            );
            const pl = calculate_profit_loss(position?.quantity, position?.opening_price, newPrice)
            setPl(pl);        
            // console.log(newPrice);
            setClosingPrice(newPrice);
          }
        }).catch((error) => {
          const newPrice = randomIntFromInterval(
            position?.opening_price - position?.opening_price * 0,
            position?.opening_price + position?.opening_price * 0.2
          );
          const pl = calculate_profit_loss(position?.quantity, position?.opening_price, newPrice)
          setPl(pl);        
          // console.log(newPrice);
          setClosingPrice(newPrice);
        });
      }, 2000);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <tr key={position.id + index}>
      <th>{moment(position?.created_at)?.format("lll")}</th>
      <td className="">{position?.symbol}</td>
      <td
        className={
          position?.position_type !== "SELL" ? "crypt-up" : "crypt-down"
        }
      >
        {position?.position_type}
      </td>
      <td className="">{Number(price).toFixed(2)} {position?.wallet?.currency}</td>
      <td className="">
        {Number(position?.opening_price).toFixed(2)} {position?.wallet?.currency}
      </td>
      <td className="">
        {change.short_name === position?.symbol ? Number(change?.last_price).toFixed(2) : Number(closingPrice).toFixed(2)} {position?.wallet?.currency}
      </td>
      <td className={(pl) >= 0 ? "crypt-up" : "crypt-down"}>
        {Number(pl).toFixed(2)} {position?.wallet?.currency}
      </td>
      <td className="">
        {position?.stop_loss > 0 ? Number(position?.stop_loss).toFixed(2) : '-'} {position?.wallet?.currency}
      </td>
      <td className="">
        {position?.take_profit > 0 ? Number(position?.take_profit).toFixed(2) : '-'} {position?.wallet?.currency}
      </td>
      <td>
        {isOpen ? <Button disabled={disabled} className="rounded-0" onClick={handleClosePosition}>
          {t("txt_close")}
          {
            disabled && <Spinner className="ms-2" size="sm" animation="border"
            role="status" />
          }
        </Button> : 
        <span>{position?.comments}</span>
        }
      </td>
    </tr>
  );
};

export default PositionRow;
